import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeTabs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.375 11.144h17.25M5.25 7.657h2.7m2.7 0h2.7M5.4 20h13.2c.84 0 1.26 0 1.581-.205.282-.18.512-.469.656-.823.163-.402.163-.93.163-1.984V7.012c0-1.054 0-1.582-.163-1.984a1.74 1.74 0 0 0-.656-.823C19.861 4 19.441 4 18.6 4H5.4c-.84 0-1.26 0-1.581.205-.282.18-.512.469-.656.823C3 5.43 3 5.958 3 7.012v9.976c0 1.055 0 1.582.163 1.984.144.354.374.642.656.823.32.205.74.205 1.581.205"
    />
  </svg>
);
export default SvgNoCodeTabs;
