import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeAudioPlayer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M8 19.033V19zm0 0A2 2 0 1 1 6 17h2m0 2.032V6.372a.5.5 0 0 1 .356-.479l9-2.7a.5.5 0 0 1 .644.479v11.861a2 2 0 1 1-2-2.033h2m-10-3 9.997-2.896" />
  </svg>
);
export default SvgNoCodeAudioPlayer;
