import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.5 11.667V8.333a7.5 7.5 0 0 1 15 0v3.334c0 2.098 0 3.148-.384 3.96a4 4 0 0 1-1.906 1.905c-.812.385-1.861.385-3.96.385v0m0 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0m-6.667-3.75A2.083 2.083 0 0 1 2.5 12.083v-1.666a2.083 2.083 0 1 1 4.167 0v1.666c0 1.15-.933 2.084-2.084 2.084m10.834 0a2.083 2.083 0 0 1-2.084-2.084v-1.666a2.083 2.083 0 0 1 4.167 0v1.666c0 1.15-.933 2.084-2.083 2.084"
    />
  </svg>
);
export default SvgSupport;
