import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={17.916} height={9} y={10} fill="#079455" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.337 13.77h1.04a2.1 2.1 0 0 0-.227-.751 1.8 1.8 0 0 0-.456-.553 2 2 0 0 0-.644-.347A2.5 2.5 0 0 0 4.266 12q-.642 0-1.154.294a2.1 2.1 0 0 0-.813.85Q2 13.704 2 14.5q0 .794.295 1.351.296.56.807.853.513.294 1.164.295.468 0 .839-.14.372-.144.64-.385a1.94 1.94 0 0 0 .633-1.2l-1.04-.005q-.036.195-.129.347a.92.92 0 0 1-.544.418 1.3 1.3 0 0 1-.382.055q-.369 0-.649-.18a1.2 1.2 0 0 1-.437-.535q-.154-.354-.154-.874 0-.507.154-.86.155-.357.435-.542.28-.187.658-.187.21 0 .387.06.18.059.318.17a.9.9 0 0 1 .226.27q.087.158.116.36m4.417-.304a.6.6 0 0 0-.244-.447q-.217-.16-.587-.16-.252 0-.425.072a.6.6 0 0 0-.266.193.46.46 0 0 0-.09.28.4.4 0 0 0 .054.228.6.6 0 0 0 .169.168q.105.07.247.122.14.05.299.085l.437.105q.318.07.584.19.267.118.461.292.195.173.302.408.11.236.111.54a1.34 1.34 0 0 1-.228.774 1.45 1.45 0 0 1-.646.506Q9.512 17 8.918 17q-.59 0-1.026-.18a1.5 1.5 0 0 1-.68-.535q-.241-.356-.254-.881h.996q.016.245.14.408a.8.8 0 0 0 .335.245q.21.08.477.08.261 0 .454-.075.195-.075.302-.212a.5.5 0 0 0 .106-.31.4.4 0 0 0-.097-.276.74.74 0 0 0-.28-.19 3 3 0 0 0-.45-.143l-.529-.133q-.615-.15-.971-.468-.357-.318-.354-.857a1.27 1.27 0 0 1 .235-.772q.24-.33.658-.516.418-.185.95-.185.542 0 .945.185.407.186.632.516t.233.765zm2.623-1.4 1.176 3.696h.045l1.178-3.696h1.14l-1.677 4.865h-1.325l-1.68-4.865z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCsv;
