import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTimeline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 38"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#fff"
      strokeMiterlimit={10}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <path
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M17.5 10.285h13M17.5 19.709h13M17.5 29.123h13"
    />
    <path strokeLinecap="round" strokeMiterlimit={10} d="M10.5 10.5V29" />
    <path
      fill="#705AF8"
      d="M10.5 23a3 3 0 1 0 0-6 3 3 0 0 0 0 6M10.5 32.419a3 3 0 1 0 0-6 3 3 0 0 0 0 6M10.5 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
    />
  </svg>
);
export default SvgNoCodeColorTimeline;
