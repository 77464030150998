import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#25D366"
      fillRule="evenodd"
      d="M103.681 24.275C93.154 13.785 79.153 8.005 64.235 8 33.495 8 8.48 32.895 8.468 63.494a55.2 55.2 0 0 0 7.444 27.745L8 120l29.563-7.717a55.9 55.9 0 0 0 26.648 6.754h.024c30.734 0 55.754-24.898 55.765-55.497.005-14.83-5.789-28.772-16.319-39.262zm-39.446 85.389h-.019a46.45 46.45 0 0 1-23.591-6.429l-1.693-1-17.544 4.579 4.682-17.023-1.101-1.745a45.85 45.85 0 0 1-7.086-24.549c.01-25.432 20.803-46.125 46.371-46.125 12.38.006 24.017 4.809 32.769 13.53 8.752 8.717 13.567 20.309 13.562 32.632-.011 25.435-20.803 46.128-46.35 46.128zM89.66 75.118c-1.393-.694-8.244-4.048-9.522-4.51-1.278-.464-2.206-.695-3.134.694-.927 1.39-3.599 4.513-4.412 5.437-.813.926-1.625 1.04-3.019.346-1.392-.695-5.882-2.159-11.206-6.882-4.142-3.678-6.939-8.217-7.752-9.607s-.085-2.14.61-2.828c.626-.623 1.393-1.62 2.09-2.43.699-.809.929-1.389 1.394-2.312.465-.927.233-1.735-.115-2.43-.348-.694-3.134-7.52-4.297-10.296-1.131-2.703-2.28-2.336-3.134-2.381a57 57 0 0 0-2.67-.048c-.931 0-2.44.346-3.718 1.735s-4.877 4.744-4.877 11.567 4.992 13.42 5.69 14.347 9.827 14.93 23.803 20.94c3.324 1.429 5.92 2.283 7.945 2.922 3.337 1.056 6.374.907 8.775.55 2.677-.399 8.244-3.355 9.405-6.594 1.16-3.238 1.16-6.017.813-6.594-.348-.578-1.278-.926-2.672-1.62z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWhatsapp;
