import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeBusinessHour = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <rect
      width={24.2}
      height={17.486}
      x={6.9}
      y={10.114}
      fill="#B7B4FE"
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.8}
      rx={2.1}
    />
    <rect width={7.714} height={5.143} x={15.286} y={6} stroke="#705AF8" strokeWidth={1.8} rx={1} />
    <path
      fill="#B7B4FE"
      d="M6 10.5A1.5 1.5 0 0 1 7.5 9h23a1.5 1.5 0 0 1 1.5 1.5v2.176a1.5 1.5 0 0 1-1.035 1.426l-11.5 3.747a1.5 1.5 0 0 1-.93 0l-11.5-3.747A1.5 1.5 0 0 1 6 12.676z"
    />
    <path
      fill="#fff"
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M28 34a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M28 24.77v3.356l1.846 1.259"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="m5 13.5.447.894a2 2 0 0 0 1.79 1.106H30.93a2 2 0 0 0 1.664-.89L33 14"
    />
    <rect width={5} height={5} x={17} y={13} fill="#fff" rx={1} />
  </svg>
);
export default SvgNoCodeBusinessHour;
