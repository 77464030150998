import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRightToLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-arrow-right-to-line"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M17 12H3M11 18l6-6-6-6M21 5v14" />
  </svg>
);
export default SvgArrowRightToLine;
