import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorStatCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#667085"
      d="M23.998 22.854q-.596-.003-1.025-.293-.427-.291-.658-.843-.229-.552-.226-1.327 0-.774.228-1.318.23-.545.658-.829.429-.285 1.023-.286.593 0 1.02.286.429.287.66.831.23.543.229 1.316 0 .778-.231 1.33-.229.55-.656.842-.427.29-1.022.29m0-.829q.406 0 .648-.408.243-.409.24-1.226 0-.538-.11-.896-.11-.357-.31-.538a.68.68 0 0 0-.468-.18q-.404 0-.647.404-.242.405-.244 1.21 0 .544.108.91.111.361.312.544.2.18.47.18M19.523 22.854q-.596-.003-1.025-.293-.427-.291-.658-.843-.229-.552-.226-1.327 0-.774.228-1.318.23-.545.658-.829.429-.285 1.023-.286.592 0 1.02.286.429.287.66.831.23.543.229 1.316 0 .778-.231 1.33-.229.55-.656.842-.427.29-1.022.29m0-.829q.406 0 .648-.408.243-.409.24-1.226 0-.538-.11-.896-.11-.357-.31-.538a.68.68 0 0 0-.468-.18q-.404 0-.647.404-.242.405-.244 1.21 0 .544.108.91.111.361.312.544.2.18.47.18M15.048 22.854q-.597-.003-1.025-.293-.427-.291-.658-.843-.23-.552-.226-1.327 0-.774.228-1.318.23-.545.658-.829.43-.285 1.022-.286.594 0 1.02.286.43.287.66.831.231.543.23 1.316 0 .778-.231 1.33-.229.55-.656.842-.427.29-1.023.29m0-.829q.405 0 .648-.408.243-.409.24-1.226 0-.538-.11-.896-.11-.357-.31-.538a.68.68 0 0 0-.469-.18q-.403 0-.646.404-.242.405-.245 1.21 0 .544.109.91.111.361.312.544.2.18.47.18M12.495 22.104l-.026.253q-.03.306-.115.608a6 6 0 0 1-.176.551q-.09.247-.145.388h-.637l.095-.38q.06-.244.113-.546.052-.302.067-.616l.011-.258zM10.547 18.023v4.727h-1V18.97H9.52l-1.083.679v-.887l1.17-.74z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M8.557 28.485h18"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M8.557 11.875h10"
    />
    <path
      fill="#17B26A"
      d="M29.854 18.439a.64.64 0 0 1 1.107 0l1.66 2.875a.64.64 0 0 1-.554.958h-3.32a.64.64 0 0 1-.553-.958z"
    />
  </svg>
);
export default SvgNoCodeColorStatCard;
