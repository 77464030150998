import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.556 11.324v8.155a1.2 1.2 0 0 0 1.2 1.2h12.48a1.2 1.2 0 0 0 1.2-1.2v-8.155m-9.36 9.24V10.96M3 6.88v1.687c0 1.12.656 2.09 1.62 2.571.427.217.9.33 1.38.33 1.66 0 2.998-1.3 2.998-2.901.012 1.589 1.353 2.877 3 2.877 1.643 0 2.98-1.281 2.999-2.865.008 1.592 1.349 2.88 3 2.88 1.656 0 2.995-1.288 3.003-2.88l-.001-1.7M3 6.88h17.999M3 6.88l1.273-2.267A1.2 1.2 0 0 1 5.32 4h13.35a1.2 1.2 0 0 1 1.045.608l1.285 2.272"
    />
  </svg>
);
export default SvgStore;
