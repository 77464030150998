import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorKeyValue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.5 2.12H7a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.899 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.351 5.25-5.25V7.37c0-2.9-2.35-5.25-5.25-5.25Z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M7.78 16.139H26.9"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M7.78 9.549h11.8"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M7.78 30.69H26.9"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M7.78 24.1h11.8"
    />
  </svg>
);
export default SvgNoCodeColorKeyValue;
