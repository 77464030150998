import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHubspot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 23 24"
    {...props}
  >
    <path
      fill="#FF7A59"
      d="M17.6 7.898V5.042a2.2 2.2 0 0 0 1.268-1.983v-.066A2.203 2.203 0 0 0 16.67.796h-.067a2.203 2.203 0 0 0-2.197 2.197v.066a2.2 2.2 0 0 0 1.268 1.983v2.856a6.23 6.23 0 0 0-2.963 1.304L4.868 3.099q.084-.302.09-.617a2.479 2.479 0 1 0-2.482 2.475c.43-.001.853-.116 1.225-.333l7.711 6.003a6.25 6.25 0 0 0 .097 7.047L9.162 20.02a2 2 0 0 0-.582-.094 2.037 2.037 0 1 0 2.037 2.037 2 2 0 0 0-.095-.582l2.322-2.322A6.26 6.26 0 1 0 17.6 7.899m-.962 9.396a3.213 3.213 0 1 1 0-6.425 3.213 3.213 0 0 1 0 6.425"
    />
  </svg>
);
export default SvgHubspot;
