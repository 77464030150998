import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorRepeatableList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 38H7.25A5.25 5.25 0 0 1 2 32.75V26m0-12V7.25C2 4.35 4.35 2 7.25 2H14m24 24v6.75c0 2.9-2.35 5.25-5.25 5.25H26m0-36h6.75C35.65 2 38 4.35 38 7.25V14"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.5}
      d="M9 11h22"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.5}
      d="M9 18h22M9 27h8"
    />
    <circle cx={27.5} cy={27.5} r={5} fill="#FDB022" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M27.5 25v5M25 27.5h5"
    />
  </svg>
);
export default SvgNoCodeColorRepeatableList;
