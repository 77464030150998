import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      stroke="#53B1FD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="m17.27 29.406-1.784-1.87c-.186-.192-.279-.289-.386-.325a.45.45 0 0 0-.29 0c-.107.036-.2.133-.386.326l-3.99 4.137c-.2.209-.3.313-.308.403m.274.323h5a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2"
    />
    <path
      fill="#17B26A"
      stroke="#53B1FD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.99 26.13a.442.442 0 1 1-.883 0 .442.442 0 0 1 .883 0"
    />
    <path
      stroke="#17B26A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M25.277 11.79h3.133m-3.133 2.345h5.012M25 17.4h6a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M24.394 24.607c0-.475 0-.712.103-.845a.52.52 0 0 1 .377-.198c.172-.012.38.117.795.374l4.881 3.02c.36.222.54.334.603.476a.48.48 0 0 1 0 .387c-.062.141-.242.253-.602.476l-4.882 3.02c-.416.256-.623.385-.795.373a.52.52 0 0 1-.377-.198c-.103-.133-.103-.37-.103-.845z"
    />
    <path
      stroke="#FDB022"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M10.705 15.965v-5.922c0-.244 0-.367.045-.466a.5.5 0 0 1 .182-.216c.09-.06.211-.08.453-.12l4.475-.746c.326-.054.489-.081.616-.034.111.041.204.12.264.223.067.118.067.283.067.614v5.65m-6.102 1.016a1.525 1.525 0 1 1-3.05 0 1.525 1.525 0 0 1 3.05 0m6.102-1.017a1.525 1.525 0 1 1-3.05 0 1.525 1.525 0 0 1 3.05 0"
    />
  </svg>
);
export default SvgNoCodeColorMedia;
