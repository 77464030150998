import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.8 6h4.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C22 8.28 22 9.12 22 10.8v5.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 21 18.88 21 17.2 21H9.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C5 18.72 5 17.88 5 16.2v-2.4m2.5-7.5v2.2m0 2.2h-.005M2 8.5a5.5 5.5 0 1 0 11 0 5.5 5.5 0 0 0-11 0"
    />
  </svg>
);
export default SvgNoCodeAlert;
