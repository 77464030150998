import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 10.5V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v1.4c0 1.68 0 1.52.327 2.162a3 3 0 0 0 1.311 1.311C5.28 14 6.12 14 7.8 14h1.7m7.886 2.711-1.698 3.154c-.278.516-.417.774-.586.84a.5.5 0 0 1-.447-.038c-.155-.096-.247-.374-.43-.93L11.5 11.512c-.16-.486-.241-.73-.183-.892a.5.5 0 0 1 .302-.302c.162-.058.406.022.892.183l8.226 2.724c.556.184.834.276.93.431a.5.5 0 0 1 .039.447c-.067.17-.325.308-.84.586l-3.155 1.698a1 1 0 0 0-.152.09.5.5 0 0 0-.082.083 1 1 0 0 0-.09.152"
    />
  </svg>
);
export default SvgNoCodeButton;
