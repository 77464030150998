import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeStatCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m5.7 9.487 1.374-.637v6.3M9.43 9.589c.099-.197.538-.92 1.64-.92 1.379 0 2.265 1.641 1.15 2.954-.893 1.05-2.385 2.406-2.921 2.953v.394h3.61m3.5-3.31c.848-.087 1.522-.636 1.58-1.425.064-.862-.607-1.565-1.565-1.565-.745 0-1.25.46-1.501.862m1.486 2.128a2 2 0 0 1-.24.013q.11-.018.24-.013m0 0c.775.036 1.965.715 1.804 1.865-.179 1.278-1.267 1.555-1.789 1.534-.455-.02-1.344-.237-1.725-1.182M7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21"
    />
  </svg>
);
export default SvgNoCodeStatCard;
