import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeRepeatable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.5 21v-3.5m0 0V14m0 3.5H14m3.5 0H21M4.6 3h3.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C10 3.76 10 4.04 10 4.6v3.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C9.24 10 8.96 10 8.4 10H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 9.24 3 8.96 3 8.4V4.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C3.76 3 4.04 3 4.6 3m11 0h3.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C21 3.76 21 4.04 21 4.6v3.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C20.24 10 19.96 10 19.4 10h-3.8c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C14 9.24 14 8.96 14 8.4V4.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C14.76 3 15.04 3 15.6 3m-11 11h3.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C10 14.76 10 15.04 10 15.6v3.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C9.24 21 8.96 21 8.4 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4v-3.8c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C3.76 14 4.04 14 4.6 14"
    />
  </svg>
);
export default SvgNoCodeRepeatable;
