import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#B7B4FE"
      fillRule="evenodd"
      d="M26.183 10.773C26.03 9.853 25.886 9 24.5 9h-9c-1.41 0-1.534.792-1.692 1.805l-.03.195-.016.126a1 1 0 0 1-.992.874H9a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2h-3.77a1 1 0 0 1-.992-.874L26.222 11z"
      clipRule="evenodd"
    />
    <circle cx={20} cy={21} r={4.5} fill="#705AF8" stroke="#fff" />
  </svg>
);
export default SvgNoCodeColorCamera;
