import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <path stroke="#CFD4DE" strokeMiterlimit={10} strokeWidth={1.5} d="M37 10.241H1" />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.07}
      d="m28.82 4.888 2.015 2.015 2.015-2.015"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M8.144 16H30"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M8 23h21.856M8 30h21.856"
    />
  </svg>
);
export default SvgNoCodeColorMenu;
