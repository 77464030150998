import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m12.612 17.508-1.224 1.224a4.328 4.328 0 0 1-6.12-6.12l1.224-1.224m11.016 1.224 1.224-1.224a4.328 4.328 0 0 0-6.12-6.12l-1.224 1.224m-2.417 8.537 6.058-6.058"
    />
  </svg>
);
export default SvgNoCodeLink;
