import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <g clipPath="url(#a)">
      <path
        stroke="#705AF8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.192 25.548h-8.725m6.301-3.878v1.939m-3.878-1.94v1.94m-.097 7.756h4.072c.815 0 1.222 0 1.533-.159.274-.14.496-.362.635-.635.159-.311.159-.719.159-1.533v-4.072c0-.814 0-1.222-.159-1.533a1.45 1.45 0 0 0-.635-.635c-.311-.159-.718-.159-1.533-.159h-4.072c-.814 0-1.221 0-1.532.159-.274.14-.496.362-.636.635-.158.311-.158.719-.158 1.533v4.072c0 .814 0 1.222.158 1.533.14.273.362.496.636.635.31.159.718.159 1.532.159"
      />
    </g>
    <g clipPath="url(#b)">
      <path
        stroke="#705AF8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.08}
        d="M26.962 10.502a7.1 7.1 0 0 0 1.38 1.944 7.1 7.1 0 0 0 1.944 1.38c.06.029.09.043.128.055a.51.51 0 0 0 .534-.178c.17-.17.254-.254.34-.31a.97.97 0 0 1 1.056 0c.085.056.17.14.34.31l.094.095c.258.257.386.386.456.524.14.275.14.6 0 .875-.07.139-.198.268-.456.525l-.076.077c-.257.256-.386.385-.56.483a1.64 1.64 0 0 1-.717.186c-.2 0-.337-.04-.61-.117a9.2 9.2 0 0 1-4.016-2.362 9.2 9.2 0 0 1-2.362-4.016c-.078-.273-.117-.41-.117-.61 0-.223.078-.523.186-.717.098-.175.227-.303.483-.56l.077-.076c.257-.258.386-.386.525-.456a.97.97 0 0 1 .875 0c.138.07.267.198.524.456l.095.094c.17.17.254.255.31.34a.97.97 0 0 1 0 1.057c-.056.085-.14.17-.31.34a.507.507 0 0 0-.123.662"
      />
    </g>
    <g
      stroke="#FDB022"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      clipPath="url(#c)"
    >
      <path d="M18.682 25.67c0-.342 0-.513-.067-.592a.28.28 0 0 0-.237-.098c-.104.008-.225.13-.468.371l-2.053 2.054 2.053 2.054c.243.242.364.363.468.371.09.007.178-.03.237-.098.067-.08.067-.25.067-.593z" />
      <path d="M7.38 26.162c0-.95 0-1.424.185-1.787.163-.32.422-.578.74-.741.364-.185.838-.185 1.788-.185h3.051c.95 0 1.425 0 1.787.185.32.163.579.422.741.74.185.364.185.838.185 1.788v2.486c0 .95 0 1.424-.185 1.787a1.7 1.7 0 0 1-.74.74c-.364.186-.838.186-1.788.186h-3.051c-.95 0-1.424 0-1.787-.185a1.7 1.7 0 0 1-.741-.741c-.185-.363-.185-.837-.185-1.787z" />
    </g>
    <g clipPath="url(#d)">
      <path
        stroke="#705AF8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.05}
        d="M10.126 16.436v-5.069m-2.305.922v3.225c0 .51.413.922.922.922h6.187c.682 0 1.263-.498 1.366-1.172l.497-3.226a1.383 1.383 0 0 0-1.367-1.593h-1.614a.46.46 0 0 1-.46-.46V8.356c0-.628-.51-1.136-1.137-1.136a.38.38 0 0 0-.346.225l-1.622 3.648a.46.46 0 0 1-.421.274H8.743a.92.92 0 0 0-.922.922"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M23.013 20.7h11.634v11.634H23.013z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M22.9 6.21h11.634v11.635H22.9z" />
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" d="M6.25 20.624h13.563v13.563H6.25z" />
      </clipPath>
      <clipPath id="d">
        <path fill="#fff" d="M6.9 6.298h11.059v11.059H6.899z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNoCodeColorIcon;
