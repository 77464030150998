import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInteger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 17h8.462M7.23 6v4.23m0 0v4.231m0-4.23H3m4.23 0h4.231M20.447 17h-2.633m0 0h-2.632m2.632 0V6h-2.632"
    />
  </svg>
);
export default SvgInteger;
