import { SVGProps } from 'react';

const withAdaptedStrokeWidth = (
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  strokeWidth = 2,
): React.ComponentType<SVGProps<SVGSVGElement>> | undefined => {
  return (props: SVGProps<SVGSVGElement>) => <Icon {...props} strokeWidth={strokeWidth} />;
};

export default withAdaptedStrokeWidth;
