import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDocx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={23.197} height={9} y={10} fill="#155EEF" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.341 14.5q0 .795-.302 1.355-.3.558-.817.853a2.3 2.3 0 0 1-1.16.292q-.648 0-1.164-.295a2.1 2.1 0 0 1-.815-.853q-.3-.558-.3-1.352 0-.795.3-1.355.299-.558.815-.85A2.3 2.3 0 0 1 9.062 12q.644 0 1.16.295.518.292.817.85.302.559.302 1.355m-1.043 0q0-.516-.155-.87a1.18 1.18 0 0 0-.43-.537 1.16 1.16 0 0 0-.65-.183q-.375 0-.652.183-.278.183-.433.537-.152.354-.152.87t.152.87q.155.354.433.537.277.183.651.183.373 0 .651-.183.279-.183.43-.537.155-.354.155-.87m5.059-.73h1.04a2.1 2.1 0 0 0-.228-.75 1.8 1.8 0 0 0-.456-.554 2 2 0 0 0-.644-.347 2.5 2.5 0 0 0-.784-.119q-.642 0-1.155.295a2.1 2.1 0 0 0-.813.85q-.3.559-.3 1.355 0 .794.295 1.352.298.559.808.853.514.295 1.165.295.468 0 .839-.14.372-.143.641-.385a1.94 1.94 0 0 0 .633-1.2l-1.041-.005q-.036.195-.129.347a.92.92 0 0 1-.544.418 1.3 1.3 0 0 1-.382.055q-.369 0-.65-.18a1.2 1.2 0 0 1-.436-.536q-.155-.354-.155-.874 0-.506.155-.86a1.2 1.2 0 0 1 .434-.542q.28-.188.659-.188.21 0 .387.06.18.06.318.17a.9.9 0 0 1 .226.272q.088.157.117.358m3.62-.045-.981-1.658h-1.167l1.495 2.433-1.524 2.433h1.18l.997-1.66h.038l.998 1.66h1.184L19.678 14.5l1.485-2.433h-1.162l-.986 1.658zM2 16.933h1.725q.743 0 1.274-.292.535-.292.82-.839.287-.546.287-1.307 0-.758-.287-1.302a1.97 1.97 0 0 0-.815-.834q-.53-.292-1.264-.292H2zm1.683-.881h-.654v-3.104h.656q.456 0 .767.162.312.162.469.504.159.342.159.881 0 .545-.16.89a1.04 1.04 0 0 1-.47.505q-.31.162-.767.162"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocx;
