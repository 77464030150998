import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRichText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.778 6.444v11.112M4.222 6.444v11.112M17.556 4.222H6.444m11.112 15.556H6.444M3.778 6.444h.889c.622 0 .933 0 1.17-.12.21-.107.38-.277.486-.486.121-.238.121-.55.121-1.171v-.89c0-.622 0-.933-.12-1.17a1.1 1.1 0 0 0-.486-.486C5.6 2 5.288 2 4.667 2h-.89c-.622 0-.933 0-1.17.121-.21.107-.38.277-.486.486C2 2.844 2 3.155 2 3.777v.89c0 .622 0 .933.121 1.17.107.21.277.38.486.486.237.121.548.121 1.17.121m0 15.556h.889c.622 0 .933 0 1.17-.121a1.1 1.1 0 0 0 .486-.486c.121-.237.121-.549.121-1.17v-.89c0-.622 0-.933-.12-1.17a1.1 1.1 0 0 0-.486-.486c-.238-.121-.55-.121-1.171-.121h-.89c-.622 0-.933 0-1.17.12-.21.107-.38.277-.486.486C2 18.4 2 18.712 2 19.333v.89c0 .622 0 .933.121 1.17.107.21.277.38.486.486.237.121.548.121 1.17.121M19.333 6.444h.89c.622 0 .933 0 1.17-.12.21-.107.38-.277.486-.486C22 5.6 22 5.288 22 4.667v-.89c0-.622 0-.933-.121-1.17a1.1 1.1 0 0 0-.486-.486C21.156 2 20.844 2 20.223 2h-.89c-.622 0-.933 0-1.17.121a1.1 1.1 0 0 0-.486.486c-.121.237-.121.548-.121 1.17v.89c0 .622 0 .933.12 1.17.107.21.277.38.486.486.238.121.55.121 1.171.121m0 15.556h.89c.622 0 .933 0 1.17-.121.21-.107.38-.277.486-.486.121-.237.121-.549.121-1.17v-.89c0-.622 0-.933-.121-1.17a1.1 1.1 0 0 0-.486-.486c-.237-.121-.549-.121-1.17-.121h-.89c-.622 0-.933 0-1.17.12a1.1 1.1 0 0 0-.486.486c-.121.238-.121.55-.121 1.171v.89c0 .622 0 .933.12 1.17.107.21.277.38.486.486.238.121.55.121 1.171.121M8 9.5c0-.466 0-.699.076-.883a1 1 0 0 1 .541-.54C8.801 8 9.034 8 9.5 8h5c.466 0 .699 0 .883.076a1 1 0 0 1 .54.541c.077.184.077.417.077.883M10.5 16h3M12 8v8"
    />
  </svg>
);
export default SvgRichText;
