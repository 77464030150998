import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTabs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M11.1 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V12.5H18.3a2.8 2.8 0 0 1-2.8-2.8V6.4A4.4 4.4 0 0 0 11.1 2Z"
    />
    <path
      fill="#B7B4FE"
      d="M30.55 17.3H9.16a1.86 1.86 0 0 0-1.86 1.86v11.05a1.86 1.86 0 0 0 1.86 1.86h21.39a1.86 1.86 0 0 0 1.86-1.86V19.16a1.86 1.86 0 0 0-1.86-1.86"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M6.7 8h4"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M20 8h4M29.6 8h4"
    />
    <path
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.7 2h22.05C35.65 2 38 4.35 38 7.25v5.25"
    />
  </svg>
);
export default SvgNoCodeColorTabs;
