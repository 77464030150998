import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTextInput = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 18"
    {...props}
  >
    <rect
      width={30}
      height={16}
      x={1}
      y={1}
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      rx={3}
    />
    <path
      fill="#705AF8"
      fillRule="evenodd"
      d="M9.468 11.781V5.6h1.286v2.324h.039q.085-.188.244-.38.164-.197.423-.326.262-.133.652-.133.507 0 .936.265.428.263.685.794.255.528.256 1.325 0 .776-.25 1.31-.247.531-.676.806a1.74 1.74 0 0 1-.954.272q-.375 0-.637-.124a1.3 1.3 0 0 1-.426-.31 1.6 1.6 0 0 1-.253-.384h-.058v.742zm1.258-2.318q0 .414.115.722t.332.48q.217.168.528.169a.83.83 0 0 0 .532-.172q.216-.176.329-.483.114-.311.114-.716 0-.401-.111-.706a1.05 1.05 0 0 0-.33-.477.83.83 0 0 0-.534-.172.85.85 0 0 0-.53.166 1.04 1.04 0 0 0-.33.47 2 2 0 0 0-.115.72"
      clipRule="evenodd"
    />
    <path
      fill="#705AF8"
      d="M16.942 11.872q-.712 0-1.225-.302a2.04 2.04 0 0 1-.785-.845 2.7 2.7 0 0 1-.272-1.243q0-.713.275-1.25.278-.54.788-.842.51-.305 1.213-.305.607 0 1.063.22t.721.62.293.935h-1.214a.94.94 0 0 0-.271-.559.78.78 0 0 0-.57-.214q-.3 0-.523.163a1.04 1.04 0 0 0-.344.468 2 2 0 0 0-.124.746q0 .443.12.754.125.31.348.474a.86.86 0 0 0 .522.163q.22 0 .396-.09a.76.76 0 0 0 .292-.263q.118-.175.154-.42h1.214a1.9 1.9 0 0 1-.29.936 1.75 1.75 0 0 1-.71.628q-.452.226-1.07.226"
    />
    <path
      fill="#705AF8"
      fillRule="evenodd"
      d="M5.044 11.715q.348.154.79.154.345 0 .608-.09.263-.094.45-.257a1.3 1.3 0 0 0 .298-.383h.036v.643h1.22V8.655q0-.414-.16-.71a1.36 1.36 0 0 0-.438-.489 1.9 1.9 0 0 0-.63-.28 3 3 0 0 0-.74-.091q-.555 0-.978.169-.42.166-.685.477a1.6 1.6 0 0 0-.357.73l1.19.097a.7.7 0 0 1 .271-.38q.205-.154.553-.154.33 0 .507.157.18.156.181.443v.018a.25.25 0 0 1-.112.224.9.9 0 0 1-.359.115q-.247.036-.652.078-.345.033-.658.115a1.9 1.9 0 0 0-.555.241 1.15 1.15 0 0 0-.387.423 1.35 1.35 0 0 0-.142.649q0 .458.2.767.202.305.55.461m1.66-.845a1.1 1.1 0 0 1-.5.112.83.83 0 0 1-.49-.136.47.47 0 0 1-.19-.402q0-.18.094-.301a.63.63 0 0 1 .262-.197 1.6 1.6 0 0 1 .396-.105l.235-.033q.13-.021.26-.046a3 3 0 0 0 .232-.057.7.7 0 0 0 .166-.072v.492a.8.8 0 0 1-.124.437.9.9 0 0 1-.34.308"
      clipRule="evenodd"
    />
    <path fill="#FDB022" d="M26.747 5v.9h-1.8v6.2h1.8v.9h-4.5v-.9h1.8V5.9h-1.8V5z" />
  </svg>
);
export default SvgNoCodeColorTextInput;
