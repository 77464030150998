import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path fill="#fff" d="M0 0h128v128H0z" />
    <path fill="#0766FF" d="M64 8a56 56 0 1 0 0 112A56 56 0 0 0 64 8" />
    <path
      fill="#fff"
      d="M71.58 78.843h14.493l2.275-14.722h-16.77v-8.046c0-6.116 1.998-11.54 7.719-11.54h9.193V31.689c-1.615-.218-5.031-.695-11.486-.695-13.479 0-21.38 7.118-21.38 23.334v9.794H41.768v14.722h13.856v40.465c2.744.412 5.523.692 8.376.692 2.579 0 5.096-.236 7.58-.572z"
    />
  </svg>
);
export default SvgFacebook;
