import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPaperAirplane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 13"
    strokeWidth={1}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.5 6.5.907 1.323A34.9 34.9 0 0 1 11.533 6.5 34.9 34.9 0 0 1 .907 11.677zm0 0h4.375"
    />
  </svg>
);
export default SvgPaperAirplane;
