import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAiSummary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 11H8m2 4H8m8-8H8m12 3.5V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h.7m3.875 1v-2.632M12 21.684h2.75M18.05 13l-.954 2.373c-.155.386-.233.579-.353.741q-.162.218-.389.372c-.17.116-.37.19-.774.338l-2.48.913 2.48.913c.403.148.605.222.774.338q.227.155.389.371c.12.163.198.356.353.742l.954 2.373.954-2.373c.155-.386.232-.58.353-.742.107-.143.238-.27.389-.371.17-.116.37-.19.774-.338l2.48-.913-2.48-.913c-.403-.148-.605-.223-.774-.338a1.6 1.6 0 0 1-.389-.372c-.12-.162-.198-.355-.353-.74z"
    />
  </svg>
);
export default SvgAiSummary;
