import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path fill="#705AF8" d="M2.727 11.907h7.64V37.47h-2.84a4.8 4.8 0 0 1-4.8-4.8z" />
    <path
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M37.26 11.22h-35M11 38V2.657M20 38V11.22m-9 8.88h26.26M11 28.9h26.26M29 38V11.22M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
  </svg>
);
export default SvgNoCodeColorTable;
