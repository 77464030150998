import SvgGoogle from '../components/colored/Google';
import SvgWhatsapp from '../components/colored/Whatsapp';
import SvgMicrosoft from '../components/colored/Microsoft';
import SvgAtom02 from '../components/outline/Atom02';
import SvgGrid01 from '../components/outline/Grid01';
import Type01 from '../components/outline/Type01';
import Settings04 from '../components/outline/Settings04';
import Integer from '../components/outline/Integer';
import RefreshCcw01 from '../components/outline/RefreshCcw01';
import RefreshCcw02 from '../components/outline/RefreshCcw02';
import RefreshCcw05 from '../components/outline/RefreshCcw05';
import Decimal from '../components/outline/Decimal';
import Calendar from '../components/outline/Calendar';
import SvgCalendar from '../components/outline/Calendar';
import Clock from '../components/outline/Clock';
import ArrowSquareUpRight from '../components/outline/ArrowSquareUpRight';
import ArrowsDown from '../components/outline/ArrowsDown';
import ArrowsUp from '../components/outline/ArrowsUp';
import FilterLines from '../components/outline/FilterLines';
import SvgOkta from '../components/colored/Okta';
import Share04 from '../components/outline/Share04';
import InfoCircle from '../components/outline/InfoCircle';
import ArrowCircleUpLeft from '../components/outline/ArrowCircleUpLeft';
import ChevronRight from '../components/outline/ChevronRight';
import ChevronLeft from '../components/outline/ChevronLeft';
import SvgLock01 from '../components/outline/Lock01';
import AlertCircle from '../components/outline/AlertCircle';
import SvgCryptocurrency01 from '../components/outline/Cryptocurrency01';
import SvgFileCheck03 from '../components/outline/FileCheck03';
import SvgFileCheck02 from '../components/outline/FileCheck02';
import SvgSimcard from '../components/outline/Simcard';
import SvgSpeedometer03 from '../components/outline/Speedometer03';
import SvgLineChartUp03 from '../components/outline/LineChartUp03';
import SvgMarkerPin01 from '../components/outline/MarkerPin01';
import Plus from '../components/outline/Plus';
import Trash01 from '../components/outline/Trash01';
import SvgTrash01 from '../components/outline/Trash01';
import Archive from '../components/outline/Archive';
import Hash01 from '../components/outline/Hash01';
import Tool02 from '../components/outline/Tool02';
import Toggle01Right from '../components/outline/Toggle01Right';
import Code01 from '../components/outline/Code01';
import SvgPhone from '../components/outline/Phone';
import ArrowLeft from '../components/outline/ArrowNarrowLeft';
import ArrowRight from '../components/outline/ArrowNarrowRight';
import SvgArrowNarrowRight from '../components/outline/ArrowNarrowRight';
import ArrowUp from '../components/outline/ArrowUp';
import ArrowDown from '../components/outline/ArrowDown';
import Menu01 from '../components/outline/Menu01';
import SvgAward01 from '../components/outline/Award01';
import SvgUsersCheck from '../components/outline/UsersCheck';
import SvgSignal01 from '../components/outline/Signal01';
import Star01 from '../components/outline/Star01';
import SvgStar02 from '../components/outline/Star02';
import SvgTranslate02 from '../components/outline/Translate02';
import SvgSettings01 from '../components/outline/Settings01';
import Settings01 from '../components/outline/Settings01';
import Settings05 from '../components/outline/Settings05';
import DotsHorizontal from '../components/outline/DotsHorizontal';
import DotsVertical from '../components/outline/DotsVertical';
import SvgUsers01 from '../components/outline/Users01';
import Container from '../components/outline/Container';
import GridDotsLeft from '../components/outline/GridDotsLeft';
import GridDotsTop from '../components/outline/GridDotsTop';
import GridDotsRight from '../components/outline/GridDotsRight';
import GridDotsBottom from '../components/outline/GridDotsBottom';
import SvgLayoutGrid02 from '../components/outline/LayoutGrid02';
import Zap from '../components/outline/Zap';
import ArrowsRight from '../components/outline/ArrowsRight';
import SvgUnifyApi from '../components/outline/UnifyApi';
import Route from '../components/outline/Route';
import CustomConnector01 from '../components/outline/CustomConnector01';
import LayoutAlt01 from '../components/outline/LayoutAlt01';
import HelpCircle from '../components/outline/HelpCircle';
import Transform from '../components/outline/Transform';
import Dataflow02 from '../components/outline/Dataflow02';
import NoCodeColorAlert from '../components/colored/no-code/NoCodeColorAlert';
import NoCodeColorButton from '../components/colored/no-code/NoCodeColorButton';
import NoCodeColorCard from '../components/colored/no-code/NoCodeColorCard';
import NoCodeColorChart from '../components/colored/no-code/NoCodeColorChart';
import NoCodeColorComment from '../components/colored/no-code/NoCodeColorComment';
import NoCodeColorContainer from '../components/colored/no-code/NoCodeColorContainer';
import NoCodeColorDivider from '../components/colored/no-code/NoCodeColorDivider';
import NoCodeColorForm from '../components/colored/no-code/NoCodeColorForm';
import NoCodeColorIcon from '../components/colored/no-code/NoCodeColorIcon';
import NoCodeColorIconButton from '../components/colored/no-code/NoCodeColorIconButton';
import NoCodeColorImage from '../components/colored/no-code/NoCodeColorImage';
import NoCodeColorKeyValue from '../components/colored/no-code/NoCodeColorKeyValue';
import NoCodeColorLink from '../components/colored/no-code/NoCodeColorLink';
import NoCodeColorLoader from '../components/colored/no-code/NoCodeColorLoader';
import NoCodeColorMultiStatCard from '../components/colored/no-code/NoCodeColorMultiStatCard';
import NoCodeColorProgressBar from '../components/colored/no-code/NoCodeColorProgressBar';
import NoCodeColorQrCode from '../components/colored/no-code/NoCodeColorQrCode';
import NoCodeColorRepeatable from '../components/colored/no-code/NoCodeColorRepeatable';
import NoCodeColorRepeatableList from '../components/colored/no-code/NoCodeColorRepeatableList';
import NoCodeColorSelect from '../components/colored/no-code/NoCodeColorSelect';
import NoCodeColorStack from '../components/colored/no-code/NoCodeColorStack';
import NoCodeColorStatCard from '../components/colored/no-code/NoCodeColorStatCard';
import NoCodeColorCamera from '../components/colored/no-code/NoCodeColorCamera';
import NoCodeColorStatus from '../components/colored/no-code/NoCodeColorStatus';
import NoCodeColorTable from '../components/colored/no-code/NoCodeColorTable';
import NoCodeColorTabs from '../components/colored/no-code/NoCodeColorTabs';
import NoCodeColorFileUpload from '../components/colored/no-code/NoCodeColorFileUpload';
import NoCodeColorText from '../components/colored/no-code/NoCodeColorText';
import NoCodeAlert from '../components/outline/no-code/NoCodeAlert';
import NoCodeFileUpload from '../components/outline/no-code/NoCodeFileUpload';
import NoCodeButton from '../components/outline/no-code/NoCodeButton';
import NoCodeCard from '../components/outline/no-code/NoCodeCard';
import NoCodeChart from '../components/outline/no-code/NoCodeChart';
import NoCodeColumns3 from '../components/outline/no-code/NoCodeColumns3';
import NoCodeColumns4 from '../components/outline/no-code/NoCodeColumns4';
import NoCodeColumns5 from '../components/outline/no-code/NoCodeColumns5';
import NoCodeColumns6 from '../components/outline/no-code/NoCodeColumns6';
import NoCodeContainer from '../components/outline/no-code/NoCodeContainer';
import NoCodeContainer11 from '../components/outline/no-code/NoCodeContainer11';
import NoCodeContainer12 from '../components/outline/no-code/NoCodeContainer12';
import NoCodeContainer13 from '../components/outline/no-code/NoCodeContainer13';
import NoCodeContainer31 from '../components/outline/no-code/NoCodeContainer31';
import NoCodeContainer21 from '../components/outline/no-code/NoCodeContainer21';
import NoCodeContainer112 from '../components/outline/no-code/NoCodeContainer112';
import NoCodeContainer121 from '../components/outline/no-code/NoCodeContainer121';
import NoCodeContainer211 from '../components/outline/no-code/NoCodeContainer211';
import NoCodeColorSteppedContainer from '../components/colored/no-code/NoCodeColorSteppedContainer';
import NoCodeSteppedContainer from '../components/outline/no-code/NoCodeSteppedContainer';
import NoCodeStepper from '../components/outline/no-code/NoCodeStepper';
import NoCodeColorStepper from '../components/colored/no-code/NoCodeColorStepper';
import NoCodeDivider from '../components/outline/no-code/NoCodeDivider';
import NoCodeForm from '../components/outline/no-code/NoCodeForm';
import SvgNoCodeForm from '../components/outline/no-code/NoCodeForm';
import NoCodeNavigation from '../components/outline/no-code/NoCodeNavigation';
import NoCodeNavigationContainer from '../components/outline/no-code/NoCodeNavigationContainer';
import NoCodeColorNavigation from '../components/colored/no-code/NoCodeColorNavigation';
import NoCodeColorNavigationContainer from '../components/colored/no-code/NoCodeColorNavigationContainer';
import NoCodeIcon from '../components/outline/no-code/NoCodeIcon';
import NoCodeIconButton from '../components/outline/no-code/NoCodeIconButton';
import NoCodeImage from '../components/outline/no-code/NoCodeImage';
import NoCodeKeyValue from '../components/outline/no-code/NoCodeKeyValue';
import NoCodeCategoryFilter from '../components/outline/no-code/NoCodeCategoryFilter';
import NoCodeColorCategoryFilter from '../components/colored/no-code/NoCodeColorCategoryFilter';
import NoCodeColorCarousel from '../components/colored/no-code/NoCodeColorCarousel';
import NoCodeCalendar from '../components/outline/no-code/NoCodeCalendar';
import NoCodeColorCalendar from '../components/colored/no-code/NoCodeColorCalendar';
import NoCodeTextInput from '../components/outline/no-code/NoCodeTextInput';
import NoCodeColorTextInput from '../components/colored/no-code/NoCodeColorTextInput';
import NoCodeLink from '../components/outline/no-code/NoCodeLink';
import NoCodeLoader from '../components/outline/no-code/NoCodeLoader';
import NoCodeMultiStatCard from '../components/outline/no-code/NoCodeMultiStatCard';
import NoCodePinnedRightPane from '../components/outline/no-code/NoCodePinnedRightPane';
import NoCodeProgressBar from '../components/outline/no-code/NoCodeProgressBar';
import NoCodeCamera from '../components/outline/no-code/NoCodeCamera';
import NoCodeQrCode from '../components/outline/no-code/NoCodeQrCode';
import NoCodeRepeatable from '../components/outline/no-code/NoCodeRepeatable';
import NoCodeRepeatableList from '../components/outline/no-code/NoCodeRepeatableList';
import NoCodeStack from '../components/outline/no-code/NoCodeStack';
import NoCodeStatCard from '../components/outline/no-code/NoCodeStatCard';
import NoCodeTable from '../components/outline/no-code/NoCodeTable';
import NoCodeColorModal from '../components/colored/no-code/NoCodeColorModal';
import NoCodeColorPdfViewer from '../components/colored/no-code/NoCodeColorPdfViewer';
import NoCodeColorSideDrawer from '../components/colored/no-code/NoCodeColorSideDrawer';
import NoCodeModal from '../components/outline/no-code/NoCodeModal';
import NoCodePdfViewer from '../components/outline/no-code/NoCodePdfViewer';
import NoCodeSideDrawer from '../components/outline/no-code/NoCodeSideDrawer';
import SvgNoCodeSideDrawer from '../components/outline/no-code/NoCodeSideDrawer';
import NoCodeColorCollaboration from '../components/colored/no-code/NoCodeColorCollaboration';
import NoCodeCollaboration from '../components/outline/no-code/NoCodeCollaboration';
import NoCodeMenu from '../components/outline/no-code/NoCodeMenu';
import NoCodeColorMenu from '../components/colored/no-code/NoCodeColorMenu';
import NoCodeTabs from '../components/outline/no-code/NoCodeTabs';
import NoCodeText from '../components/outline/no-code/NoCodeText';
import SvgList from '../components/outline/List';
import Announcement03 from '../components/outline/Announcement03';
import FieldShield02 from '../components/outline/FileShield02';
import LayoutAlt04 from '../components/outline/LayoutAlt04';
import PieChart01 from '../components/outline/PieChart01';
import CoinsStacked04 from '../components/outline/CoinsStacked04';
import Edit05 from '../components/outline/Edit05';
import Stars02 from '../components/outline/Stars02';
import Server04 from '../components/outline/Server04';
import Dataflow04 from '../components/outline/Dataflow04';
import SvgLocation from '../components/outline/Location';
import SvgPageVariables from '../components/outline/PageVariables';
import SvgEye from '../components/outline/Eye';
import SvgPencil01 from '../components/outline/Pencil01';
import SvgShare07 from '../components/outline/Share07';
import RefreshCw01 from '../components/outline/RefreshCw01';
import SvgXClose from '../components/outline/XClose';
import Copy01 from '../components/outline/Copy01';
import NoCodeFilters from '../components/outline/no-code/NoCodeFilters';
import NoCodeColorFilters from '../components/colored/no-code/NoCodeColorFilters';
import NoCodeContainer23 from '../components/outline/no-code/NoCodeContainer23';
import NoCodeContainer32 from '../components/outline/no-code/NoCodeContainer32';
import NoCodeTag from '../components/outline/no-code/NoCodeTag';
import NoCodeColorTag from '../components/colored/no-code/NoCodeColorTag';
import SvgUser01 from '../components/outline/User01';
import SvgDownload01 from '../components/outline/Download01';
import SvgUserCheck01 from '../components/outline/UserCheck01';
import BracketsCheck from '../components/outline/BracketsCheck';
import PackageCheck from '../components/outline/PackageCheck';
import Codepen from '../components/outline/Codepen';
import SvgBracketsX from '../components/outline/BracketsX';
import SvgLink01 from '../components/outline/Link01';
import SvgPage from '../components/outline/File04';
import SvgNotification from '../components/outline/Bell01';
import SvgLayoutTop from '../components/outline/LayoutTop';
import SvgSalesforce from '../components/colored/Salesforce';
import Loop from '../components/outline/Loop';
import Mark from '../components/outline/Mark';
import Cube02 from '../components/outline/Cube02';
import Governance from '../components/outline/Governance';
import NoCodeTimeline from '../components/outline/no-code/NoCodeTimeline';
import NoCodeColorTimeline from '../components/outline/no-code/NoCodeColorTimeline';
import File05 from '../components/outline/File05';
import LayersThree02 from '../components/outline/LayersThree02';
import SvgHome01 from '../components/outline/Home01';
import SvgHome02 from '../components/outline/Home02';
import SvgHome03 from '../components/outline/Home03';
import SvgHome04 from '../components/outline/Home04';
import SvgHome05 from '../components/outline/Home05';
import SvgHomeLine from '../components/outline/HomeLine';
import SvgHomeSmile from '../components/outline/HomeSmile';
import ChevronUp from '../components/outline/ChevronUp';
import ChevronDown from '../components/outline/ChevronDown';
import SvgAward05 from '../components/outline/Award05';
import SvgRocket02 from '../components/outline/Rocket02';
import BracketsSquare from '../components/outline/BracketsSquare';
import BracketsEllipses from '../components/outline/BracketsEllipses';
import SvgShield02 from '../components/outline/Shield02';
import SvgCheckVerified02 from '../components/outline/CheckVerified02';
import SvgDataflow01 from '../components/outline/Dataflow01';
import SvgEdit03 from '../components/outline/Edit03';
import SvgDatabase02 from '../components/outline/Database02';
import SvgUploadCloud02 from '../components/outline/UploadCloud02';
import LinkExternal01 from '../components/outline/LinkExternal01';
import CheckCircleBroken from '../components/outline/CheckCircleBroken';
import FileDownload03 from '../components/outline/FileDownload03';
import MultiDevice from '../components/outline/MultiDevice';
import SvgEyeOff from '../components/outline/EyeOff';
import SvgCheck from '../components/outline/Check';
import SvgHanger from '../components/outline/Hanger';
import SvgStore from '../components/outline/Store';
import SvgCatalog from '../components/outline/Catalog';
import Globe01 from '../components/outline/Globe01';
import Csv from '../components/colored/Csv';
import Pdf2 from '../components/colored/Pdf2';
import Doc from '../components/colored/Doc';
import Docx from '../components/colored/Docx';
import Ppt from '../components/colored/Ppt';
import Pptx from '../components/colored/Pptx';
import Epubs from '../components/colored/Epubs';
import Xls from '../components/colored/Xls';
import Xlsx from '../components/colored/Xlsx';
import Cross from '../components/outline/X';
import XCircle from '../components/outline/XCircle';
import AlertTriangle from '../components/outline/AlertTriangle';
import BellNotification from '../components/outline/BellNotification';
import SvgCurrencyYen from '../components/outline/CurrencyYen';
import SvgCurrencyEuro from '../components/outline/CurrencyEuro';
import SvgCurrencyPound from '../components/outline/CurrencyPound';
import SvgCurrencyRuble from '../components/outline/CurrencyRuble';
import SvgCurrencyRupee from '../components/outline/CurrencyRupee';
import SvgCurrencyDollar from '../components/outline/CurrencyDollar';
import NoCodeColorButtonGroup from '../components/colored/no-code/NoCodeColorButtonGroup';
import SvgPercent02 from '../components/outline/Percent02';
import SvgCalendarClock from '../components/outline/CalendarClock';
import SvgTag01 from '../components/outline/Tag01';
import SvgClipboardCheck from '../components/outline/ClipboardCheck';
import SvgLcLink from '../components/outline/LcLink';
import SvgFile02 from '../components/outline/File02';
import SvgLcIcon from '../components/outline/LcIcon';
import SvgLcProgressBar from '../components/outline/LcProgressBar';
import SvgCheckSquare from '../components/outline/CheckSquare';
import SvgRadioSelect from '../components/outline/RadioSelect';
import SvgSingleSelect from '../components/outline/SingleSelect';
import SvgMultiSelect from '../components/outline/MultiSelect';
import LcFileUpload from '../components/outline/LcFileUpload';
import SvgLcIconButton from '../components/outline/LcIconButton';
import RichText from '../components/outline/RichText';
import NoCodeTimer from '../components/outline/no-code/NoCodeTimer';
import NoCodeColorTimer from '../components/colored/no-code/NoCodeColorTimer';
import SvgPasscodeLock from '../components/outline/PasscodeLock';
import SvgPasscode from '../components/outline/Passcode';
import SvgNoCodeContextualDialog from '../components/outline/no-code/NoCodeContextualDialog';
import SvgNoCodeColorContextualDialog from '../components/colored/no-code/NoCodeColorContextualDialog';
import SvgPhone02 from '../components/outline/Phone02';
import SvgLaptop02 from '../components/outline/Laptop02';
import Reply from '../components/outline/Reply';
import SvgShieldLock from '../components/outline/ShieldLock';
import SvgMail01 from '../components/outline/Mail01';
import NoCodeColorCopilot from '../components/colored/no-code/NoCodeColorCopilot';
import NoCodeCopilot from '../components/outline/no-code/NoCodeCopilot';
import NoCodeMessageInput from '../components/outline/no-code/NoCodeMessageInput';
import NoCodeColorMessageInput from '../components/colored/no-code/NoCodeColorMessageInput';
import SvgHeart from '../components/outline/Heart';
import SvgThumbsDown from '../components/outline/ThumbsDown';
import SvgThumbsUp from '../components/outline/ThumbsUp';
import QuoteStart from '../components/outline/QuoteStart';
import QuoteEnd from '../components/outline/QuoteEnd';
import SvgArrowNarrowDown from '../components/outline/ArrowNarrowDown';
import SvgAlignHorizontalStart from '../components/outline/AlignHorizontalStart';
import SvgAlignHorizontalEnd from '../components/outline/AlignHorizontalEnd';
import SvgAlignHorizontalCenter from '../components/outline/AlignHorizontalCenter';
import SvgAlignHorizontalSpaceBetween from '../components/outline/AlignHorizontalSpaceBetween';
import SvgAlignHorizontalStretch from '../components/outline/AlignHorizontalStretch';
import SvgAlignVerticalStart from '../components/outline/AlignVerticalStart';
import SvgAlignVerticalEnd from '../components/outline/AlignVerticalEnd';
import SvgAlignVerticalCenter from '../components/outline/AlignVerticalCenter';
import SvgAlignVerticalSpaceBetween from '../components/outline/AlignVerticalSpaceBetween';
import SvgAlignVerticalStretch from '../components/outline/AlignVerticalStretch';
import SvgVariable from '../components/outline/Variable';
import SvgFunction from '../components/outline/Function';
import SvgSliders04 from '../components/outline/Sliders04';
import NoCodeMedia from '../components/outline/no-code/NoCodeMedia';
import NoCodeColorMedia from '../components/colored/no-code/NoCodeColorMedia';
import NotificationText from '../components/outline/NotificationText';
import Certificate02 from '../components/outline/Certificate02';
import SvgBookOpen01 from '../components/outline/BookOpen01';
import SvgTerminalBrowser from '../components/outline/TerminalBrowser';
import SvgLightbulb02 from '../components/outline/Lightbulb02';
import CursorInteractive from '../components/outline/CursorInteractive';
import SvgFileSearch02 from '../components/outline/FileSearch02';
import SvgSearchRefraction from '../components/outline/SearchRefraction';
import ArrowLeftToLine from '../components/outline/ArrowLeftToLine';
import ArrowRightToLine from '../components/outline/ArrowRightToLine';
import ArrowUpToLine from '../components/outline/ArrowUpToLine';
import ArrowDownToLine from '../components/outline/ArrowDownToLine';
import SvgAnnotationAlert from '../components/outline/AnnotationAlert';
import SvgArrowCircleBrokenDownRight from '../components/outline/ArrowCircleBrokenDownRight';
import SvgArrowCircleBrokenUpRight from '../components/outline/ArrowCircleBrokenUpRight';
import SvgSlashCircle01 from '../components/outline/SlashCircle01';
import SvgArrowCircleRight from '../components/outline/ArrowCircleRight';
import SvgDownloadCloud02 from '../components/outline/DownloadCloud02';
import SvgFaceId from '../components/outline/FaceId';
import SvgClockCheck from '../components/outline/ClockCheck';
import SvgClockRewind from '../components/outline/ClockRewind';
import SvgCheckHeart from '../components/outline/CheckHeart';
import SvgZapFast from '../components/outline/ZapFast';
import SvgClockStopwatch from '../components/outline/ClockStopwatch';
import SvgWebhook from '../components/outline/Webhook';
import MessageTextSquare02 from '../components/outline/MessageTextSquare02';
import Minus from '../components/outline/Minus';
import BarChart10 from '../components/outline/BarChart10';
import HorizontalBarChart02 from '../components/outline/HorizontalBarChart02';
import LineChartUp01 from '../components/outline/LineChartUp01';
import IntersectCircle from '../components/outline/IntersectCircle';
import DualAxesLineChartUp01 from '../components/outline/DualAxesLineChartUp01';
import SankeyChart01 from '../components/outline/SankeyChart01';
import SvgFileLock02 from '../components/outline/FileLock02';
import ClockFastForward from '../components/outline/ClockFastForward';
import SchemaMapping from '../components/outline/SchemaMapping';
import SvgDatabase01 from '../components/outline/Database01';
import SvgSessionStorage from '../components/outline/SessionStorage';
import SvgLcForm from '../components/outline/LcForm';
import SvgCode02 from '../components/outline/Code02';
import SvgSlack from '../components/colored/Slack';
import SvgGoogleMeet from '../components/colored/GoogleMeet';
import SvgZoom from '../components/colored/Zoom';
import ChevronLeftDouble from '../components/outline/ChevronLeftDouble';
import ChevronRightDouble from '../components/outline/ChevronRightDouble';
import NoCodeAudioPlayer from '../components/outline/no-code/NoCodeAudioPlayer';
import NoCodeColorAudioPlayer from '../components/colored/no-code/NoCodeColorAudioPlayer';
import AISummary from '../components/outline/AiSummary';
import HeartFilled from '../components/solid/HeartFilled';
import SvgUnifyAi3 from '../components/colored/UnifyAi3';
import UsersPlus from '../components/outline/UsersPlus';
import BriefCase02 from '../components/outline/Briefcase02';
import SvgSupport from '../components/outline/Support';
import Linkedin from '../components/colored/Linkedin';
import Facebook from '../components/colored/Facebook';
import DataTypeBoolean from '../components/outline/data-types/DataTypeBoolean';
import DataTypeDate from '../components/outline/data-types/DataTypeDate';
import DataTypeDatetime from '../components/outline/data-types/DataTypeDatetime';
import DataTypeDecimal from '../components/outline/data-types/DataTypeDecimal';
import DataTypeDouble from '../components/outline/data-types/DataTypeDouble';
import DataTypeInteger from '../components/outline/data-types/DataTypeInteger';
import DataTypeObject from '../components/outline/data-types/DataTypeObject';
import DataTypeText from '../components/outline/data-types/DataTypeText';
import DataTypeTime from '../components/outline/data-types/DataTypeTime';
import DataTypeTimestamp from '../components/outline/data-types/DataTypeTimestamp';
import DataTypeBinary from '../components/outline/data-types/DataTypeBinary';
import DataTypeArray from '../components/outline/data-types/DataTypeArray';
import DataTypeArrayZero from '../components/outline/data-types/DataTypeArrayZero';
import NoCodeCarousel from '../components/outline/no-code/NoCodeCarousel';
import withAdaptedStrokeWidth from './withAdaptedStrokeWidth';
import NoCodeColorBusinessHour from '../components/colored/no-code/NoCodeColorBusinessHour';
import NoCodeBusinessHour from '../components/colored/no-code/NoCodeBusinessHour';
import SvgSwitchOn from '../components/outline/SwitchOn';
import SvgSwitchOff from '../components/outline/SwitchOff';
import SvgJsonLanguage from '../components/outline/JsonLanguage';
import SvgXmlLanguage from '../components/outline/XmlLanguage';
import SvgJsLanguage from '../components/outline/JsLanguage';
import SvgGroovyLanguage from '../components/outline/GroovyLanguage';
import SvgJavaLanguage from '../components/outline/JavaLanguage';
import SvgPythonLanguage from '../components/outline/PythonLanguage';
import SvgJsonSchemaLanguage from '../components/outline/JsonSchemaLanguage';
import SvgAlignLeft from '../components/outline/AlignLeft';
import SvgAlignCenter from '../components/outline/AlignCenter';
import SvgAlignRight from '../components/outline/AlignRight';
import SvgArrowUpRight from '../components/outline/ArrowUpRight';
import SvgResetPassword from '../components/outline/ResetPassword';
import SvgTextInput from '../components/outline/TextInput';
import LinkBroken01 from '../components/outline/LinkBroken01';
import SvgHubspot from '../components/colored/Hubspot';
import SvgPaperAirplane from '../components/outline/PaperAirplane';
import SvgPeople from '../components/outline/People';
import SvgSignal02 from '../components/outline/Signal02';
import SvgUsersRight from '../components/outline/UsersRight';
import SvgShieldTick from '../components/outline/ShieldTick';
import SvgShield03 from '../components/outline/Shield03';
import SvgMessageHeartSquare from '../components/outline/MessageHeartSquare';
import SvgMedicalCross from '../components/outline/MedicalCross';
import SvgFingerprint04 from '../components/outline/Fingerprint04';
import SvgCurrencyDollarCircle from '../components/outline/CurrencyDollarCircle';
import SvgCalculator from '../components/outline/Calculator';
import SvgGitBranch01 from '../components/outline/GitBranch01';

export const ICONS_REGISTRY: Record<
  string,
  | {
      icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
      label: string;
      id: string;
    }
  | undefined
> = {
  MessageTextSquare02: {
    icon: MessageTextSquare02,
    label: 'MessageTextSquare02',
    id: 'MessageTextSquare02',
  },
  ZapFast: {
    icon: SvgZapFast,
    label: 'Zap Fast',
    id: 'ZapFast',
  },
  ClockStopwatch: {
    icon: SvgClockStopwatch,
    label: 'Clock Stopwatch',
    id: 'ClockStopwatch',
  },
  Toggle01Right: {
    icon: Toggle01Right,
    label: 'Toggle01Right',
    id: 'Toggle01Right',
  },
  Webhook: {
    icon: SvgWebhook,
    label: 'Webhook',
    id: 'Webhook',
  },
  ArrowCircleBrokenDownRight: {
    icon: SvgArrowCircleBrokenDownRight,
    label: 'Arrow Circle Broken Down Right',
    id: 'ArrowCircleBrokenDownRight',
  },
  ArrowCircleBrokenUpRight: {
    icon: SvgArrowCircleBrokenUpRight,
    label: 'Arrow Circle Broken Up Right',
    id: 'ArrowCircleBrokenUpRight',
  },
  SlashCircle01: {
    icon: SvgSlashCircle01,
    label: 'Slash Circle',
    id: 'SlashCircle01',
  },
  ArrowCircleRight: {
    icon: SvgArrowCircleRight,
    label: 'Arrow Circle Right',
    id: 'ArrowCircleRight',
  },
  ArrowNarrowDown: {
    icon: SvgArrowNarrowDown,
    label: 'ArrowNarrowDown',
    id: 'ArrowNarrowDown',
  },
  ArrowNarrowRight: {
    icon: SvgArrowNarrowRight,
    label: 'ArrowNarrowRight',
    id: 'ArrowNarrowRight',
  },
  Phone02: {
    icon: SvgPhone02,
    label: 'Phone02',
    id: 'Phone02',
  },
  Laptop02: {
    icon: SvgLaptop02,
    label: 'Laptop02',
    id: 'Laptop02',
  },
  NoCodeContextualDialog: {
    icon: SvgNoCodeContextualDialog,
    label: 'NoCodeContextualDialog',
    id: 'NoCodeContextualDialog',
  },
  NoCodeColorContextualDialog: {
    icon: SvgNoCodeColorContextualDialog,
    label: 'NoCodeColorContextualDialog',
    id: 'NoCodeColorContextualDialog',
  },
  SvgTextInput: {
    icon: SvgTextInput,
    label: 'TextInput',
    id: 'TextInput',
  },
  SvgResetPassword: {
    icon: SvgResetPassword,
    label: 'ResetPassword',
    id: 'ResetPassword',
  },
  RichText: {
    icon: RichText,
    label: 'RichText',
    id: 'RichText',
  },
  LinkBroken01: {
    icon: LinkBroken01,
    label: 'LinkBroken01',
    id: 'LinkBroken01',
  },
  SvgLcIconButton: {
    icon: SvgLcIconButton,
    label: 'LcIconButton',
    id: 'SvgLcIconButton',
  },
  TextAlignLeft: {
    icon: SvgAlignLeft,
    label: 'TextAlignLeft',
    id: 'TextAlignLeft',
  },
  TextAlignCenter: {
    icon: SvgAlignCenter,
    label: 'TextAlignCenter',
    id: 'TextAlignCenter',
  },
  TextAlignRight: {
    icon: SvgAlignRight,
    label: 'TextAlignRight',
    id: 'TextAlignRight',
  },
  SvgAlignHorizontalStart: {
    icon: SvgAlignHorizontalStart,
    label: 'AlignHorizontalStart',
    id: 'SvgAlignHorizontalStart',
  },
  SvgAlignHorizontalEnd: {
    icon: SvgAlignHorizontalEnd,
    label: 'AlignHorizontalEnd',
    id: 'SvgAlignHorizontalEnd',
  },
  SvgAlignHorizontalCenter: {
    icon: SvgAlignHorizontalCenter,
    label: 'AlignHorizontalCenter',
    id: 'SvgAlignHorizontalCenter',
  },
  SvgCertificate02: {
    icon: Certificate02,
    label: 'Certificate02',
    id: 'SvgCertificate02',
  },
  SvgAlignHorizontalSpaceBetween: {
    icon: SvgAlignHorizontalSpaceBetween,
    label: 'AlignHorizontalSpaceBetween',
    id: 'SvgAlignHorizontalSpaceBetween',
  },
  SvgAlignHorizontalStretch: {
    icon: SvgAlignHorizontalStretch,
    label: 'AlignHorizontalStretch',
    id: 'SvgAlignHorizontalStretch',
  },
  SvgAlignVerticalStart: {
    icon: SvgAlignVerticalStart,
    label: 'AlignVerticalStart',
    id: 'SvgAlignVerticalStart',
  },
  SvgAlignVerticalEnd: {
    icon: SvgAlignVerticalEnd,
    label: 'AlignVerticalEnd',
    id: 'SvgAlignVerticalEnd',
  },
  SvgAlignVerticalCenter: {
    icon: SvgAlignVerticalCenter,
    label: 'AlignVerticalCenter',
    id: 'SvgAlignVerticalCenter',
  },
  SvgAlignVerticalSpaceBetween: {
    icon: SvgAlignVerticalSpaceBetween,
    label: 'AlignVerticalSpaceBetween',
    id: 'SvgAlignVerticalSpaceBetween',
  },
  SvgAlignVerticalStretch: {
    icon: SvgAlignVerticalStretch,
    label: 'AlignVerticalStretch',
    id: 'SvgAlignVerticalStretch',
  },
  LcFileUpload: {
    icon: LcFileUpload,
    label: 'LcFileUpload',
    id: 'LcFileUpload',
  },
  SvgMultiSelect: {
    icon: SvgMultiSelect,
    label: 'Multi Select',
    id: 'SvgMultiSelect',
  },
  SvgSingleSelect: {
    icon: SvgSingleSelect,
    label: 'Single Select',
    id: 'SvgSingleSelect',
  },
  SvgRadioSelect: {
    icon: SvgRadioSelect,
    label: 'Radio Select',
    id: 'SvgRadioSelect',
  },
  SvgCheckSquare: {
    icon: SvgCheckSquare,
    label: 'Check Square',
    id: 'SvgCheckSquare',
  },
  SvgCalendar: {
    icon: SvgCalendar,
    label: 'Calendar',
    id: 'SvgCalendar',
  },
  SvgLcProgressBar: {
    icon: SvgLcProgressBar,
    label: 'LcProgressBar',
    id: 'SvgLcProgressBar',
  },
  SvgLcIcon: {
    icon: SvgLcIcon,
    label: 'LcIcon',
    id: 'SvgLcIcon',
  },
  SvgFile02: {
    icon: SvgFile02,
    label: 'File',
    id: 'SvgFile02',
  },
  SvgCurrencyRupee: {
    icon: SvgCurrencyRupee,
    label: 'Currency Rupee',
    id: 'SvgCurrencyRupee',
  },
  SvgLcLink: {
    icon: SvgLcLink,
    label: 'LcLink',
    id: 'SvgLcLink',
  },
  SvgTag01: {
    icon: SvgTag01,
    label: 'Tag',
    id: 'SvgTag01',
  },
  SvgLock01: {
    icon: SvgLock01,
    label: 'Lock',
    id: 'SvgLock01',
  },
  SvgAnnotationAlert: {
    icon: SvgAnnotationAlert,
    label: 'Annotation Alert',
    id: 'SvgAnnotationAlert',
  },
  SvgPasscodeLock: {
    icon: SvgPasscodeLock,
    label: 'Passcode Lock',
    id: 'SvgPasscodeLock',
  },
  Mail01: {
    icon: SvgMail01,
    label: 'Mail',
    id: 'Mail01',
  },
  SvgPasscode: {
    icon: SvgPasscode,
    label: 'Passcode',
    id: 'SvgPasscode',
  },
  SvgCalendarClock: { icon: SvgCalendarClock, label: 'Calendar Clock', id: 'SvgCalendarClock' },
  SvgPercent02: {
    icon: SvgPercent02,
    label: 'Percent',
    id: 'SvgPercent02',
  },
  SvgQuotes: {
    icon: QuoteStart,
    label: 'Quote Start',
    id: 'SvgQuotes',
  },
  QuoteEnd: {
    icon: QuoteEnd,
    label: 'Quote End',
    id: 'QuoteEnd',
  },
  SvgCurrencyYen: {
    icon: SvgCurrencyYen,
    label: 'Currency Yen',
    id: 'SvgCurrencyYen',
  },
  SvgCurrencyEuro: {
    icon: SvgCurrencyEuro,
    label: 'Currency Euro',
    id: 'SvgCurrencyEuro',
  },
  SvgCurrencyPound: {
    icon: SvgCurrencyPound,
    label: 'Currency Pound',
    id: 'SvgCurrencyPound',
  },
  SvgCurrencyRuble: {
    icon: SvgCurrencyRuble,
    label: 'Currency Ruble',
    id: 'SvgCurrencyRuble',
  },
  SvgCurrencyDollar: {
    icon: SvgCurrencyDollar,
    label: 'Currency Dollar',
    id: 'SvgCurrencyDollar',
  },
  AlertCircle: {
    icon: AlertCircle,
    label: 'Alert Circle',
    id: 'AlertCircle',
  },
  Archive: {
    icon: Archive,
    label: 'Archive',
    id: 'Archive',
  },
  ArrowCircleUpLeft: {
    icon: ArrowCircleUpLeft,
    label: 'Arrow Circle Up Left',
    id: 'ArrowCircleUpLeft',
  },
  ArrowSquareUpRight: {
    icon: ArrowSquareUpRight,
    label: 'Arrow Square Up Right',
    id: 'ArrowSquareUpRight',
  },
  ArrowsDown: {
    icon: ArrowsDown,
    label: 'Arrows Down',
    id: 'ArrowsDown',
  },
  ArrowsUp: {
    icon: ArrowsUp,
    label: 'Arrows Up',
    id: 'ArrowsUp',
  },
  FilterLines: {
    icon: FilterLines,
    label: 'Filter Lines',
    id: 'FilterLines',
  },
  Atom02: {
    icon: SvgAtom02,
    label: 'Atom',
    id: 'Atom02',
  },
  ChevronRight: {
    icon: ChevronRight,
    label: 'Chevron Right',
    id: 'ChevronRight',
  },
  ChevronUp: {
    icon: ChevronUp,
    label: 'Chevron Up',
    id: 'ChevronUp',
  },
  CheckHeart: {
    icon: SvgCheckHeart,
    label: 'Check Heart',
    id: 'CheckHeart',
  },
  SvgFaceId: {
    icon: SvgFaceId,
    label: 'Face Id',
    id: 'SvgFaceId',
  },
  SvgClockCheck: {
    icon: SvgClockCheck,
    label: 'Clock Check',
    id: 'SvgClockCheck',
  },
  SvgClockRewind: {
    icon: SvgClockRewind,
    label: 'Clock Rewind',
    id: 'SvgClockRewind',
  },
  ChevronDown: {
    icon: ChevronDown,
    label: 'Chevron Down',
    id: 'ChevronDown',
  },
  Calendar: {
    icon: Calendar,
    label: 'Calendar',
    id: 'Calendar',
  },
  SvgCatalog: {
    icon: SvgCatalog,
    label: 'Catalog',
    id: 'SvgCatalog',
  },
  Clock: {
    icon: Clock,
    label: 'Clock',
    id: 'Clock',
  },
  SvgCheckVerified02: {
    icon: SvgCheckVerified02,
    label: 'Check Verified',
    id: 'SvgCheckVerified02',
  },
  SvgCheck: {
    // Temporary UI fix: Increasing stroke width to make it visually balanced.
    icon: withAdaptedStrokeWidth(SvgCheck),
    label: 'Check',
    id: 'SvgCheck',
  },
  SvgUsersCheck: {
    icon: SvgUsersCheck,
    label: 'Users Check',
    id: 'SvgUsersCheck',
  },
  SvgClipboardCheck: {
    icon: SvgClipboardCheck,
    label: 'Clipboard Check',
    id: 'SvgClipboardCheck',
  },
  SvgHanger: {
    icon: SvgHanger,
    label: 'Hanger',
    id: 'SvgHanger',
  },
  SvgStore: {
    icon: SvgStore,
    label: 'Store',
    id: 'SvgStore',
  },
  Decimal: {
    icon: Decimal,
    label: 'Decimal',
    id: 'Decimal',
  },
  Trash01: {
    icon: Trash01,
    label: 'Trash01',
    id: 'Trash01',
  },
  InfoCircle: {
    icon: InfoCircle,
    label: 'Info Circle',
    id: 'InfoCircle',
  },
  SvgFileCheck03: {
    icon: SvgFileCheck03,
    label: 'File Check',
    id: 'SvgFileCheck03',
  },
  SvgFileCheck02: {
    icon: SvgFileCheck02,
    label: 'File Check',
    id: 'SvgFileCheck02',
  },
  File05: {
    icon: File05,
    label: 'File 05',
    id: 'File05',
  },
  Json: {
    icon: SvgJsonLanguage,
    label: 'Json Language',
    id: 'SvgJsonLanguage',
  },
  SvgJsonLanguage: {
    icon: SvgJsonLanguage,
    label: 'Json Language',
    id: 'SvgJsonLanguage',
  },
  SvgJsonSchemaLanguage: {
    icon: SvgJsonSchemaLanguage,
    label: 'Json Schema Language',
    id: 'SvgJsonSchemaLanguage',
  },
  SvgXmlLanguage: {
    icon: SvgXmlLanguage,
    label: 'Xml Language',
    id: 'SvgXmlLanguage',
  },
  SvgPythonLanguage: {
    icon: SvgPythonLanguage,
    label: 'Python Language',
    id: 'SvgPythonLanguage',
  },
  SvgJavaLanguage: {
    icon: SvgJavaLanguage,
    label: 'Java Language',
    id: 'SvgJavaLanguage',
  },
  SvgGroovyLanguage: {
    icon: SvgGroovyLanguage,
    label: 'Groovy Language',
    id: 'SvgGroovyLanguage',
  },
  SvgJsLanguage: {
    icon: SvgJsLanguage,
    label: 'Js Language',
    id: 'SvgJsLanguage',
  },
  LayersThree02: {
    icon: LayersThree02,
    label: 'Layers Three 02',
    id: 'LayersThree02',
  },
  Integer: {
    icon: Integer,
    label: 'Integer',
    id: 'Integer',
  },
  SvgDownload01: {
    icon: SvgDownload01,
    label: 'Download',
    id: 'SvgDownload01',
  },
  RefreshCcw01: {
    icon: RefreshCcw01,
    label: 'Refresh Ccw 01',
    id: 'RefreshCcw01',
  },
  RefreshCcw02: {
    icon: RefreshCcw02,
    label: 'Refresh Ccw 02',
    id: 'RefreshCcw02',
  },
  RefreshCcw05: {
    icon: RefreshCcw05,
    label: 'Refresh Ccw 05',
    id: 'RefreshCcw05',
  },
  Settings04: {
    icon: Settings04,
    label: 'Settings 01',
    id: 'Settings04',
  },
  Settings05: {
    icon: Settings05,
    label: 'Settings 05',
    id: 'Settings05',
  },
  Share04: {
    icon: Share04,
    label: 'Share',
    id: 'Share04',
  },
  Type01: {
    icon: Type01,
    label: 'Type',
    id: 'Type01',
  },
  SvgPage: {
    icon: SvgPage,
    label: 'Page',
    id: 'SvgPage',
  },
  SvgLink01: {
    icon: SvgLink01,
    label: 'Link',
    id: 'SvgLink01',
  },
  SvgNotification: {
    icon: SvgNotification,
    label: 'Notification',
    id: 'SvgNotification',
  },
  SvgNotificationText: {
    icon: NotificationText,
    label: 'NotificationText',
    id: 'SvgNotificationText',
  },
  SvgNoCodeForm: {
    icon: SvgNoCodeForm,
    label: 'NoCodeForm',
    id: 'SvgNoCodeForm',
  },
  SvgNoCodeSideDrawer: {
    icon: SvgNoCodeSideDrawer,
    label: 'NoCodeSideDrawer',
    id: 'SvgNoCodeSideDrawer',
  },
  SvgBracketsX: {
    icon: SvgBracketsX,
    label: 'BracketsX',
    id: 'SvgBracketsX',
  },
  SvgLayoutTop: {
    icon: SvgLayoutTop,
    label: 'LayoutTop',
    id: 'SvgLayoutTop',
  },
  SvgGrid01: {
    icon: SvgGrid01,
    label: 'Grid',
    id: 'SvgGrid01',
  },
  SvgSimcard: {
    icon: SvgSimcard,
    label: 'Simcard',
    id: 'SvgSimcard',
  },
  SvgSpeedometer03: {
    icon: SvgSpeedometer03,
    label: 'Speedometer',
    id: 'SvgSpeedometer03',
  },
  SvgLineChartUp03: {
    icon: SvgLineChartUp03,
    label: 'LineChartUp',
    id: 'SvgLineChartUp03',
  },
  SvgMarkerPin01: {
    icon: SvgMarkerPin01,
    label: 'MarkerPin',
    id: 'SvgMarkerPin01',
  },
  SvgPhone: {
    icon: SvgPhone,
    label: 'Phone',
    id: 'SvgPhone',
  },
  Reply: {
    icon: Reply,
    label: 'Reply',
    id: 'Reply',
  },
  Hash01: {
    icon: Hash01,
    label: 'Hash',
    id: 'Hash01',
  },
  CheckCircleBroken: {
    icon: CheckCircleBroken,
    label: 'Check Circle Broken',
    id: 'CheckCircleBroken',
  },
  Tool02: {
    icon: Tool02,
    label: 'Tool',
    id: 'Tool02',
  },
  Code01: {
    icon: Code01,
    label: 'Code',
    id: 'Code01',
  },
  SvgLocation: {
    icon: SvgLocation,
    label: 'Location',
    id: 'SvgLocation',
  },
  SvgPageVariables: {
    icon: SvgPageVariables,
    label: 'Page Variables',
    id: 'SvgPageVariables',
  },
  Database01: {
    icon: SvgDatabase01,
    label: 'Database',
    id: 'Database01',
  },
  SessionStorage: {
    icon: SvgSessionStorage,
    label: 'Session Storage',
    id: 'SessionStorage',
  },
  LcForm: {
    icon: SvgLcForm,
    label: 'LcForm',
    id: 'LcForm',
  },
  Code02: {
    icon: SvgCode02,
    label: 'Code 02',
    id: 'Code02',
  },
  SvgVariable: {
    icon: SvgVariable,
    label: 'Variable',
    id: 'SvgVariable',
  },
  SvgFunction: {
    icon: SvgFunction,
    label: 'Function',
    id: 'SvgFunction',
  },
  ArrowLeft: {
    icon: ArrowLeft,
    label: 'Arrow Left',
    id: 'ArrowLeft',
  },
  ArrowRight: {
    icon: ArrowRight,
    label: 'Arrow Right',
    id: 'ArrowRight',
  },
  ArrowDown: {
    icon: ArrowDown,
    label: 'Arrow Down',
    id: 'ArrowDown',
  },
  ArrowUp: {
    icon: ArrowUp,
    label: 'Arrow Up',
    id: 'ArrowUp',
  },
  Menu01: {
    icon: Menu01,
    label: 'Menu',
    id: 'Menu01',
  },
  SvgAward01: {
    icon: SvgAward01,
    label: 'Award',
    id: 'SvgAward01',
  },
  SvgAward05: {
    icon: SvgAward05,
    label: 'Award',
    id: 'SvgAward05',
  },
  Star01: {
    icon: Star01,
    label: 'Star',
    id: 'Star01',
  },
  SvgStar02: {
    icon: SvgStar02,
    label: 'Star',
    id: 'SvgStar02',
  },
  SvgTranslate02: {
    icon: SvgTranslate02,
    label: 'Translate',
    id: 'SvgTranslate02',
  },
  SvgCryptocurrency01: {
    icon: SvgCryptocurrency01,
    label: 'Cryptocurrency',
    id: 'SvgCryptocurrency01',
  },
  ChevronLeft: {
    icon: ChevronLeft,
    label: 'Chevron Left',
    id: 'ChevronLeft',
  },
  DotsHorizontal: {
    icon: DotsHorizontal,
    label: 'Dots Horizontal',
    id: 'DotsHorizontal',
  },
  DotsVertical: {
    icon: DotsVertical,
    label: 'Dots Vertical',
    id: 'DotsVertical',
  },
  SvgSettings01: {
    icon: SvgSettings01,
    label: 'Settings 02',
    id: 'SvgSettings01',
  },
  SvgSignal01: {
    icon: SvgSignal01,
    label: 'Signal',
    id: 'SvgSignal01',
  },
  SvgSignal02: {
    icon: SvgSignal02,
    label: 'Signal 02',
    id: 'SvgSignal02',
  },
  SvgUsers01: {
    icon: SvgUsers01,
    label: 'Users',
    id: 'SvgUsers01',
  },
  SvgUserCheck01: {
    icon: SvgUserCheck01,
    label: 'SvgUserCheck01',
    id: 'SvgUserCheck01',
  },
  Plus: {
    icon: Plus,
    label: 'Plus',
    id: 'Plus',
  },
  BracketsCheck: {
    icon: BracketsCheck,
    label: 'BracketsCheck',
    id: 'BracketsCheck',
  },
  GridDotsLeft: {
    icon: GridDotsLeft,
    label: 'Grid Dots Left',
    id: 'GridDotsLeft',
  },
  Container: { icon: Container, label: 'Container', id: 'Container' },
  GridDotsTop: {
    icon: GridDotsTop,
    label: 'Grid Dots Top',
    id: 'GridDotsTop',
  },
  GridDotsRight: {
    icon: GridDotsRight,
    label: 'Grid Dots Right',
    id: 'GridDotsRight',
  },
  GridDotsBottom: {
    icon: GridDotsBottom,
    label: 'Grid Dots Bottom',
    id: 'GridDotsBottom',
  },
  SvgLayoutGrid02: {
    icon: SvgLayoutGrid02,
    label: 'Layout Grid',
    id: 'SvgLayoutGrid02',
  },
  Zap: {
    icon: Zap,
    label: 'Zap',
    id: 'Zap',
  },
  ArrowsRight: {
    icon: ArrowsRight,
    label: 'Arrows Right',
    id: 'ArrowsRight',
  },
  SvgUnifyApi: {
    icon: SvgUnifyApi,
    label: 'Unify Api',
    id: 'SvgUnifyApi',
  },
  Route: {
    icon: Route,
    label: 'route',
    id: 'Route',
  },
  SvgRocket02: {
    icon: SvgRocket02,
    label: 'rocket',
    id: 'SvgRocket02',
  },
  CustomConnector01: {
    icon: CustomConnector01,
    label: 'Custom Connector',
    id: 'CustomConnector01',
  },
  LayoutAlt01: {
    icon: LayoutAlt01,
    label: 'Layout Alt',
    id: 'LayoutAlt01',
  },
  HelpCircle: {
    icon: HelpCircle,
    label: 'Help Circle',
    id: 'HelpCircle',
  },
  Settings01: {
    icon: Settings01,
    label: 'Settings01',
    id: 'Settings01',
  },
  SvgUploadCloud02: {
    icon: SvgUploadCloud02,
    label: 'Upload Cloud',
    id: 'SvgUploadCloud02',
  },
  SvgDownloadCloud02: {
    icon: SvgDownloadCloud02,
    label: 'Download Cloud',
    id: 'SvgDownloadCloud02',
  },
  Transform: {
    icon: Transform,
    label: 'Transform',
    id: 'Transform',
  },
  SvgDataflow01: {
    icon: SvgDataflow01,
    label: 'Dataflow',
    id: 'SvgDataflow01',
  },
  Dataflow02: {
    icon: Dataflow02,
    label: 'Dataflow02',
    id: 'Dataflow02',
  },
  CoinsStacked04: {
    icon: CoinsStacked04,
    label: 'CoinsStacked04',
    id: 'CoinsStacked04',
  },
  NoCodeAlert: {
    icon: NoCodeAlert,
    label: 'NoCodeAlert',
    id: 'NoCodeAlert',
  },

  NoCodeFileUpload: {
    icon: NoCodeFileUpload,
    label: 'NoCodeFileUpload',
    id: 'NoCodeFileUpload',
  },
  NoCodeButton: {
    icon: NoCodeButton,
    label: 'NoCodeButton',
    id: 'NoCodeButton',
  },
  NoCodeCard: {
    icon: NoCodeCard,
    label: 'NoCodeCard',
    id: 'NoCodeCard',
  },
  NoCodeChart: {
    icon: NoCodeChart,
    label: 'NoCodeChart',
    id: 'NoCodeChart',
  },
  NoCodeColumns3: {
    icon: NoCodeColumns3,
    label: 'NoCodeColumns3',
    id: 'NoCodeColumns3',
  },
  NoCodeColumns4: {
    icon: NoCodeColumns4,
    label: 'NoCodeColumns4',
    id: 'NoCodeColumns4',
  },
  LinkExternal01: {
    icon: LinkExternal01,
    label: 'LinkExternal01',
    id: 'LinkExternal01',
  },
  NoCodeColumns5: {
    icon: NoCodeColumns5,
    label: 'NoCodeColumns5',
    id: 'NoCodeColumns5',
  },
  NoCodeCollaboration: {
    icon: NoCodeCollaboration,
    label: 'NoCodeCollaboration',
    id: 'NoCodeCollaboration',
  },
  NoCodeNavigation: {
    icon: NoCodeNavigation,
    label: 'NoCodeNavigation',
    id: 'NoCodeNavigation',
  },
  NoCodeColorNavigation: {
    icon: NoCodeColorNavigation,
    label: 'NoCodeColorNavigation',
    id: 'NoCodeColorNavigation',
  },
  NoCodeColorNavigationContainer: {
    icon: NoCodeColorNavigationContainer,
    label: 'NoCodeColorNavigationContainer',
    id: 'NoCodeColorNavigationContainer',
  },
  NoCodeNavigationContainer: {
    icon: NoCodeNavigationContainer,
    label: 'NoCodeNavigationContainer',
    id: 'NoCodeNavigationContainer',
  },
  NoCodeColorCollaboration: {
    icon: NoCodeColorCollaboration,
    label: 'NoCodeColorCollaboration',
    id: 'NoCodeColorCollaboration',
  },
  NoCodeMenu: {
    icon: NoCodeMenu,
    label: 'NoCodeMenu',
    id: 'NoCodeMenu',
  },
  NoCodeColorMenu: {
    icon: NoCodeColorMenu,
    label: 'NoCodeColorMenu',
    id: 'NoCodeColorMenu',
  },
  NoCodeColumns6: {
    icon: NoCodeColumns6,
    label: 'NoCodeColumns6',
    id: 'NoCodeColumns6',
  },
  NoCodeContainer: {
    icon: NoCodeContainer,
    label: 'NoCodeContainer',
    id: 'NoCodeContainer',
  },
  Cross: {
    // Temporary UI fix: Increasing stroke width to make it visually balanced.
    icon: withAdaptedStrokeWidth(Cross),
    label: 'Cross',
    id: 'Cross',
  },
  NoCodeModal: {
    icon: NoCodeModal,
    label: 'NoCodeModal',
    id: 'NoCodeModal',
  },
  NoCodeSideDrawer: {
    icon: NoCodeSideDrawer,
    label: 'NoCodeSideDrawer',
    id: 'NoCodeSideDrawer',
  },
  NoCodeContainer11: {
    icon: NoCodeContainer11,
    label: 'NoCodeContainer11',
    id: 'NoCodeContainer11',
  },
  NoCodeContainer12: {
    icon: NoCodeContainer12,
    label: 'NoCodeContainer12',
    id: 'NoCodeContainer12',
  },
  NoCodeContainer13: {
    icon: NoCodeContainer13,
    label: 'NoCodeContainer13',
    id: 'NoCodeContainer13',
  },
  NoCodeContainer21: {
    icon: NoCodeContainer21,
    label: 'NoCodeContainer21',
    id: 'NoCodeContainer21',
  },
  NoCodeContainer23: {
    icon: NoCodeContainer23,
    label: 'NoCodeContainer23',
    id: 'NoCodeContainer23',
  },
  NoCodeContainer31: {
    icon: NoCodeContainer31,
    label: 'NoCodeContainer31',
    id: 'NoCodeContainer31',
  },
  NoCodeContainer32: {
    icon: NoCodeContainer32,
    label: 'NoCodeContainer32',
    id: 'NoCodeContainer32',
  },
  NoCodeContainer112: {
    icon: NoCodeContainer112,
    label: 'NoCodeContainer112',
    id: 'NoCodeContainer112',
  },
  NoCodeContainer121: {
    icon: NoCodeContainer121,
    label: 'NoCodeContainer121',
    id: 'NoCodeContainer121',
  },
  NoCodeContainer211: {
    icon: NoCodeContainer211,
    label: 'NoCodeContainer211',
    id: 'NoCodeContainer211',
  },
  NoCodeDivider: {
    icon: NoCodeDivider,
    label: 'NoCodeDivider',
    id: 'NoCodeDivider',
  },
  NoCodeForm: {
    icon: NoCodeForm,
    label: 'NoCodeForm',
    id: 'NoCodeForm',
  },
  NoCodeIcon: {
    icon: NoCodeIcon,
    label: 'NoCodeIcon',
    id: 'NoCodeIcon',
  },
  NoCodeIconButton: {
    icon: NoCodeIconButton,
    label: 'NoCodeIconButton',
    id: 'NoCodeIconButton',
  },
  NoCodeFilters: {
    icon: NoCodeFilters,
    label: 'NoCodeFilters',
    id: 'NoCodeFilters',
  },
  NoCodeImage: {
    icon: NoCodeImage,
    label: 'NoCodeImage',
    id: 'NoCodeImage',
  },
  NoCodeKeyValue: {
    icon: NoCodeKeyValue,
    label: 'NoCodeKeyValue',
    id: 'NoCodeKeyValue',
  },
  NoCodeCategoryFilter: {
    icon: NoCodeCategoryFilter,
    label: 'NoCodeCategoryFilter',
    id: 'NoCodeCategoryFilter',
  },
  NoCodeColorCategoryFilter: {
    icon: NoCodeColorCategoryFilter,
    label: 'NoCodeColorCategoryFilter',
    id: 'NoCodeColorCategoryFilter',
  },
  NoCodeTextInput: {
    icon: NoCodeTextInput,
    label: 'NoCodeTextInput',
    id: 'NoCodeTextInput',
  },
  NoCodeCarousel: {
    icon: NoCodeCarousel,
    label: 'NoCodeCarousel',
    id: 'NoCodeCarousel',
  },
  NoCodeColorCarousel: {
    icon: NoCodeColorCarousel,
    label: 'NoCodeColorCarousel',
    id: 'NoCodeColorCarousel',
  },
  NoCodeCalendar: {
    icon: NoCodeCalendar,
    label: 'NoCodeCalendar',
    id: 'NoCodeCalendar',
  },
  NoCodeColorCalendar: {
    icon: NoCodeColorCalendar,
    label: 'NoCodeColorCalendar',
    id: 'NoCodeColorCalendar',
  },
  NoCodeColorTextInput: {
    icon: NoCodeColorTextInput,
    label: 'NoCodeColorTextInput',
    id: 'NoCodeColorTextInput',
  },
  NoCodeSteppedContainer: {
    icon: NoCodeSteppedContainer,
    label: 'NoCodeSteppedContainer',
    id: 'NoCodeSteppedContainer',
  },
  NoCodeColorSteppedContainer: {
    icon: NoCodeColorSteppedContainer,
    label: 'NoCodeColorSteppedContainer',
    id: 'NoCodeColorSteppedContainer',
  },
  NoCodeStepper: {
    icon: NoCodeStepper,
    label: 'NoCodeStepper',
    id: 'NoCodeStepper',
  },
  NoCodeColorStepper: {
    icon: NoCodeColorStepper,
    label: 'NoCodeColorStepper',
    id: 'NoCodeColorStepper',
  },
  NoCodeLink: {
    icon: NoCodeLink,
    label: 'NoCodeLink',
    id: 'NoCodeLink',
  },
  NoCodeLoader: {
    icon: NoCodeLoader,
    label: 'NoCodeLoader',
    id: 'NoCodeLoader',
  },
  NoCodeMultiStatCard: {
    icon: NoCodeMultiStatCard,
    label: 'NoCodeMultiStatCard',
    id: 'NoCodeMultiStatCard',
  },
  NoCodePinnedRightPane: {
    icon: NoCodePinnedRightPane,
    label: 'NoCodePinnedRightPane',
    id: 'NoCodePinnedRightPane',
  },
  PackageCheck: {
    icon: PackageCheck,
    label: 'PackageCheck',
    id: 'PackageCheck',
  },
  Codepen: {
    icon: Codepen,
    label: 'Codepen',
    id: 'Codepen',
  },
  NoCodeProgressBar: {
    icon: NoCodeProgressBar,
    label: 'NoCodeProgressBar',
    id: 'NoCodeProgressBar',
  },
  NoCodeQrCode: {
    icon: NoCodeQrCode,
    label: 'NoCodeQrCode',
    id: 'NoCodeQrCode',
  },
  NoCodeRepeatable: {
    icon: NoCodeRepeatable,
    label: 'NoCodeRepeatable',
    id: 'NoCodeRepeatable',
  },
  NoCodeRepeatableList: {
    icon: NoCodeRepeatableList,
    label: 'NoCodeRepeatableList',
    id: 'NoCodeRepeatableList',
  },
  NoCodeStack: {
    icon: NoCodeStack,
    label: 'NoCodeStack',
    id: 'NoCodeStack',
  },
  NoCodeStatCard: {
    icon: NoCodeStatCard,
    label: 'NoCodeStatCard',
    id: 'NoCodeStatCard',
  },
  NoCodeTable: {
    icon: NoCodeTable,
    label: 'NoCodeTable',
    id: 'NoCodeTable',
  },
  NoCodeBusinessHour: {
    icon: NoCodeBusinessHour,
    label: 'NoCodeBusinessHour',
    id: 'NoCodeBusinessHour',
  },
  NoCodeTabs: {
    icon: NoCodeTabs,
    label: 'NoCodeTabs',
    id: 'NoCodeTabs',
  },
  NoCodeText: {
    icon: NoCodeText,
    label: 'NoCodeText',
    id: 'NoCodeText',
  },
  NoCodeCopilot: {
    icon: NoCodeCopilot,
    label: 'NoCodeCopilot',
    id: 'NoCodeCopilot',
  },
  NoCodeMessageInput: {
    icon: NoCodeMessageInput,
    label: 'NoCodeMessageInput',
    id: 'NoCodeMessageInput',
  },
  List: {
    icon: SvgList,
    label: 'List',
    id: 'List',
  },
  Announcement03: {
    icon: Announcement03,
    label: 'Announcement',
    id: 'Announcement03',
  },
  FileShield02: {
    icon: FieldShield02,
    label: 'File Shield 2',
    id: 'FileShield02',
  },
  SvgShield02: {
    icon: SvgShield02,
    label: 'Shield',
    id: 'SvgShield02',
  },
  LayoutAlt04: {
    icon: LayoutAlt04,
    label: 'Layout Alt 4',
    id: 'LayoutAlt04',
  },
  User01: {
    icon: SvgUser01,
    label: 'User',
    id: 'User01',
  },
  PieChart01: {
    icon: PieChart01,
    label: 'Pie Chart',
    id: 'PieChart01',
  },
  Edit05: {
    icon: Edit05,
    label: 'Edit',
    id: 'Edit05',
  },
  SvgEdit03: {
    icon: SvgEdit03,
    label: 'Edit',
    id: 'SvgEdit03',
  },
  SvgEye: {
    icon: SvgEye,
    label: 'Eye',
    id: 'SvgEye',
  },
  SvgEyeOff: {
    icon: SvgEyeOff,
    label: 'Eye Off',
    id: 'SvgEyeOff',
  },
  SvgPencil01: {
    icon: SvgPencil01,
    label: 'Pencil',
    id: 'SvgPencil01',
  },
  SvgTrash01: {
    icon: SvgTrash01,
    label: 'Trash',
    id: 'SvgTrash01',
  },
  SvgShare07: {
    icon: SvgShare07,
    label: 'Share',
    id: 'SvgShare07',
  },
  RefreshCw01: {
    icon: RefreshCw01,
    label: 'Refresh Cw 01',
    id: 'RefreshCw01',
  },
  Copy01: {
    icon: Copy01,
    label: 'Copy',
    id: 'Copy01',
  },
  Stars02: { icon: Stars02, label: 'Stars 2', id: 'Stars02' },
  Server04: { icon: Server04, label: 'Server 4', id: 'Server04' },
  Dataflow04: { icon: Dataflow04, label: 'Dataflow 4', id: 'Dataflow04' },
  SvgDatabase02: {
    icon: SvgDatabase02,
    label: 'Database 2',
    id: 'SvgDatabase02',
  },
  SvgXClose: {
    icon: SvgXClose,
    label: 'Close',
    id: 'SvgXClose',
  },
  Home01: {
    icon: SvgHome01,
    label: 'Home',
    id: 'Home01',
  },
  Home02: {
    icon: SvgHome02,
    label: 'Home',
    id: 'Home02',
  },
  Home03: {
    icon: SvgHome03,
    label: 'Home',
    id: 'Home03',
  },
  Home04: {
    icon: SvgHome04,
    label: 'Home',
    id: 'Home04',
  },
  Home05: {
    icon: SvgHome05,
    label: 'Home',
    id: 'Home05',
  },
  HomeLine: {
    icon: SvgHomeLine,
    label: 'Home',
    id: 'HomeLine',
  },
  HomeSmile: {
    icon: SvgHomeSmile,
    label: 'Home',
    id: 'HomeSmile',
  },
  //colored-icons
  NoCodeColorCamera: {
    icon: NoCodeColorCamera,
    label: 'NoCodeColorCamera',
    id: 'NoCodeColorCamera',
  },
  NoCodeColorFileUpload: {
    icon: NoCodeColorFileUpload,
    label: 'NoCodeColorFileUpload',
    id: 'NoCodeColorFileUpload',
  },
  NoCodeColorAlert: {
    icon: NoCodeColorAlert,
    label: 'NoCodeColorAlert',
    id: 'NoCodeColorAlert',
  },
  NoCodeColorFilters: {
    icon: NoCodeColorFilters,
    label: 'NoCodeColorFilters',
    id: 'NoCodeColorFilters',
  },
  NoCodeColorModal: {
    icon: NoCodeColorModal,
    label: 'NoCodeColorModal',
    id: 'NoCodeColorModal',
  },
  NoCodeColorSideDrawer: {
    icon: NoCodeColorSideDrawer,
    label: 'NoCodeColorSideDrawer',
    id: 'NoCodeColorSideDrawer',
  },
  NoCodeColorButton: {
    icon: NoCodeColorButton,
    label: 'NoCodeColorButton',
    id: 'NoCodeColorButton',
  },
  NoCodeColorCard: {
    icon: NoCodeColorCard,
    label: 'NoCodeColorCard',
    id: 'NoCodeColorCard',
  },
  NoCodeColorChart: {
    icon: NoCodeColorChart,
    label: 'NoCodeColorChart',
    id: 'NoCodeColorChart',
  },
  NoCodeColorComment: {
    icon: NoCodeColorComment,
    label: 'NoCodeColorComment',
    id: 'NoCodeColorComment',
  },
  NoCodeColorContainer: {
    icon: NoCodeColorContainer,
    label: 'NoCodeColorContainer',
    id: 'NoCodeColorContainer',
  },
  NoCodeColorDivider: {
    icon: NoCodeColorDivider,
    label: 'NoCodeColorDivider',
    id: 'NoCodeColorDivider',
  },
  NoCodeColorForm: {
    icon: NoCodeColorForm,
    label: 'NoCodeColorForm',
    id: 'NoCodeColorForm',
  },
  NoCodeColorIcon: {
    icon: NoCodeColorIcon,
    label: 'NoCodeColorIcon',
    id: 'NoCodeColorIcon',
  },
  NoCodeColorIconButton: {
    icon: NoCodeColorIconButton,
    label: 'NoCodeColorIconButton',
    id: 'NoCodeColorIconButton',
  },
  NoCodeColorImage: {
    icon: NoCodeColorImage,
    label: 'NoCodeColorImage',
    id: 'NoCodeColorImage',
  },
  NoCodeColorKeyValue: {
    icon: NoCodeColorKeyValue,
    label: 'NoCodeColorKeyValue',
    id: 'NoCodeColorKeyValue',
  },
  NoCodeColorLink: {
    icon: NoCodeColorLink,
    label: 'NoCodeColorLink',
    id: 'NoCodeColorLink',
  },
  NoCodeColorLoader: {
    icon: NoCodeColorLoader,
    label: 'NoCodeColorLoader',
    id: 'NoCodeColorLoader',
  },
  NoCodeColorMultiStatCard: {
    icon: NoCodeColorMultiStatCard,
    label: 'NoCodeColorMultiStatCard',
    id: 'NoCodeColorMultiStatCard',
  },
  NoCodeColorProgressBar: {
    icon: NoCodeColorProgressBar,
    label: 'NoCodeColorProgressBar',
    id: 'NoCodeColorProgressBar',
  },
  NoCodeColorQrCode: {
    icon: NoCodeColorQrCode,
    label: 'NoCodeColorQrCode',
    id: 'NoCodeColorQrCode',
  },
  NoCodeColorRepeatable: {
    icon: NoCodeColorRepeatable,
    label: 'NoCodeColorRepeatable',
    id: 'NoCodeColorRepeatable',
  },
  NoCodeColorRepeatableList: {
    icon: NoCodeColorRepeatableList,
    label: 'NoCodeColorRepeatableList',
    id: 'NoCodeColorRepeatableList',
  },
  NoCodeColorSelect: {
    icon: NoCodeColorSelect,
    label: 'NoCodeColorSelect',
    id: 'NoCodeColorSelect',
  },
  NoCodeColorStack: {
    icon: NoCodeColorStack,
    label: 'NoCodeColorStack',
    id: 'NoCodeColorStack',
  },
  NoCodeColorStatCard: {
    icon: NoCodeColorStatCard,
    label: 'NoCodeColorStatCard',
    id: 'NoCodeColorStatCard',
  },
  NoCodeColorStatus: {
    icon: NoCodeColorStatus,
    label: 'NoCodeColorStatus',
    id: 'NoCodeColorStatus',
  },
  NoCodeColorTable: {
    icon: NoCodeColorTable,
    label: 'NoCodeColorTable',
    id: 'NoCodeColorTable',
  },
  NoCodeColorBusinessHour: {
    icon: NoCodeColorBusinessHour,
    label: 'NoCodeColorBusinessHour',
    id: 'NoCodeColorBusinessHour',
  },
  NoCodeColorTabs: {
    icon: NoCodeColorTabs,
    label: 'NoCodeColorTabs',
    id: 'NoCodeColorTabs',
  },
  NoCodeColorText: {
    icon: NoCodeColorText,
    label: 'NoCodeColorText',
    id: 'NoCodeColorText',
  },
  NoCodeColorCopilot: {
    icon: NoCodeColorCopilot,
    label: 'NoCodeColorCopilot',
    id: 'NoCodeColorCopilot',
  },
  SvgOkta: {
    icon: SvgOkta,
    label: 'Okta',
    id: 'SvgOkta',
  },
  SvgGoogle: {
    icon: SvgGoogle,
    label: 'Google',
    id: 'SvgGoogle',
  },
  SvgWhatsapp: {
    icon: SvgWhatsapp,
    label: 'Whatsapp',
    id: 'SvgWhatsapp',
  },
  SvgMicrosoft: {
    icon: SvgMicrosoft,
    label: 'Microsoft',
    id: 'SvgMicrosoft',
  },
  SvgSalesforce: {
    icon: SvgSalesforce,
    label: 'Salesforce',
    id: 'SvgSalesforce',
  },
  SvgHubspot: {
    icon: SvgHubspot,
    label: 'Hubspot',
    id: 'SvgHubspot',
  },
  NoCodeTag: {
    icon: NoCodeTag,
    label: 'NoCodeTag',
    id: 'NoCodeTag',
  },
  NoCodeCamera: {
    icon: NoCodeCamera,
    label: 'NoCodeCamera',
    id: 'NoCodeCamera',
  },
  NoCodeColorTag: {
    icon: NoCodeColorTag,
    label: 'NoCodeColorTag',
    id: 'NoCodeColorTag',
  },
  Loop: {
    icon: Loop,
    label: 'Loop',
    id: 'Loop',
  },
  ShieldLock: {
    icon: SvgShieldLock,
    label: 'ShieldLock',
    id: 'ShieldLock',
  },
  Mark: {
    icon: Mark,
    label: 'Mark',
    id: 'Mark',
  },
  Cube02: {
    icon: Cube02,
    label: 'Cube02',
    id: 'Cube02',
  },
  Governance: {
    icon: Governance,
    label: 'Governance',
    id: 'Governance',
  },
  NoCodeTimeline: {
    icon: NoCodeTimeline,
    label: 'NoCodeTimeline',
    id: 'NoCodeTimeline',
  },
  NoCodeColorTimeline: {
    icon: NoCodeColorTimeline,
    label: 'NoCodeColorTimeline',
    id: 'NoCodeColorTimeline',
  },
  NoCodeColorMessageInput: {
    icon: NoCodeColorMessageInput,
    label: 'NoCodeColorMessageInput',
    id: 'NoCodeColorMessageInput',
  },
  BracketsSquare: {
    icon: BracketsSquare,
    label: 'BracketsSquare',
    id: 'BracketsSquare',
  },
  Double: {
    icon: DataTypeDouble,
    label: 'Double',
    id: 'Double',
  },
  BracketsEllipses: {
    icon: BracketsEllipses,
    label: 'BracketsEllipses',
    id: 'BracketsEllipses',
  },
  FileDownload03: {
    icon: FileDownload03,
    label: 'FileDownload03',
    id: 'FileDownload03',
  },
  MultiDevice: {
    icon: MultiDevice,
    label: 'MultiDevice',
    id: 'MultiDevice',
  },
  Globe01: {
    icon: Globe01,
    label: 'Globe01',
    id: 'Globe01',
  },
  Pdf2: {
    icon: Pdf2,
    label: 'Pdf2',
    id: 'Pdf2',
  },
  Csv: {
    icon: Csv,
    label: 'Csv',
    id: 'Csv',
  },
  Doc: {
    icon: Doc,
    label: 'Doc',
    id: 'Doc',
  },
  Docx: {
    icon: Docx,
    label: 'Docx',
    id: 'Docx',
  },
  Ppt: {
    icon: Ppt,
    label: 'Ppt',
    id: 'Ppt',
  },
  Pptx: {
    icon: Pptx,
    label: 'Pptx',
    id: 'Pptx',
  },
  Epubs: {
    icon: Epubs,
    label: 'Epubs',
    id: 'Epubs',
  },
  Xls: {
    icon: Xls,
    label: 'Xls',
    id: 'Xls',
  },
  Xlsx: {
    icon: Xlsx,
    label: 'Xlsx',
    id: 'Xlsx',
  },
  XCircle: {
    icon: XCircle,
    label: 'XCircle',
    id: 'XCircle',
  },
  AlertTriangle: {
    icon: AlertTriangle,
    label: 'AlertTriangle',
    id: 'AlertTriangle',
  },
  BellNotification: {
    icon: BellNotification,
    label: 'BellNotification',
    id: 'BellNotification',
  },
  NoCodeColorButtonGroup: {
    icon: NoCodeColorButtonGroup,
    label: 'NoCodeColorButtonGroup',
    id: 'NoCodeColorButtonGroup',
  },
  NoCodeTimer: {
    icon: NoCodeTimer,
    label: 'NoCodeTimer',
    id: 'NoCodeTimer',
  },
  NoCodeColorTimer: {
    icon: NoCodeColorTimer,
    label: 'NoCodeColorTimer',
    id: 'NoCodeColorTimer',
  },
  NoCodeMedia: {
    icon: NoCodeMedia,
    label: 'NoCodeMedia',
    id: 'NoCodeMedia',
  },
  NoCodeColorMedia: {
    icon: NoCodeColorMedia,
    label: 'NoCodeColorMedia',
    id: 'NoCodeColorMedia',
  },
  ThumbsUp: {
    icon: SvgThumbsUp,
    label: 'ThumbsUp',
    id: 'ThumbsUp',
  },
  ThumbsDown: {
    icon: SvgThumbsDown,
    label: 'ThumbsDown',
    id: 'ThumbsDown',
  },
  Heart: {
    icon: SvgHeart,
    label: 'Heart',
    id: 'Heart',
  },
  SvgSliders04: {
    icon: SvgSliders04,
    label: 'SvgSliders04',
    id: 'SvgSliders04',
  },
  SvgBookOpen01: {
    icon: SvgBookOpen01,
    label: 'SvgBookOpen01',
    id: 'SvgBookOpen01',
  },
  SvgTerminalBrowser: {
    icon: SvgTerminalBrowser,
    label: 'SvgTerminalBrowser',
    id: 'SvgTerminalBrowser',
  },
  SvgLightbulb02: {
    icon: SvgLightbulb02,
    label: 'SvgLightbulb02',
    id: 'SvgLightbulb02',
  },
  CursorInteractive: {
    icon: CursorInteractive,
    label: 'CursorInteractive',
    id: 'CursorInteractive',
  },
  SvgFileSearch02: {
    icon: SvgFileSearch02,
    label: 'SvgFileSearch02',
    id: 'SvgFileSearch02',
  },
  SvgFileLock02: {
    icon: SvgFileLock02,
    label: 'SvgFileLock02',
    id: 'SvgFileLock02',
  },
  SvgSearchRefraction: {
    icon: SvgSearchRefraction,
    label: 'SvgSearchRefraction',
    id: 'SvgSearchRefraction',
  },
  ArrowLeftToLine: {
    icon: ArrowLeftToLine,
    label: 'ArrowLeftToLine',
    id: 'ArrowLeftToLine',
  },
  ArrowRightToLine: {
    icon: ArrowRightToLine,
    label: 'ArrowRightToLine',
    id: 'ArrowRightToLine',
  },
  ArrowUpToLine: {
    icon: ArrowUpToLine,
    label: 'ArrowUpToLine',
    id: 'ArrowUpToLine',
  },
  ArrowDownToLine: {
    icon: ArrowDownToLine,
    label: 'ArrowDownToLine',
    id: 'ArrowDownToLine',
  },
  Minus: {
    icon: Minus,
    label: 'Minus',
    id: 'Minus',
  },
  BarChart10: {
    icon: BarChart10,
    label: 'BarChart10',
    id: 'BarChart10',
  },
  HorizontalBarChart02: {
    icon: HorizontalBarChart02,
    label: 'HorizontalBarChart02',
    id: 'HorizontalBarChart02',
  },
  LineChartUp01: {
    icon: LineChartUp01,
    label: 'LineChartUp01',
    id: 'LineChartUp01',
  },
  IntersectCircle: {
    icon: IntersectCircle,
    label: 'IntersectCircle',
    id: 'IntersectCircle',
  },
  DualAxesLineChartUp01: {
    icon: DualAxesLineChartUp01,
    label: 'DualAxesLineChartUp01',
    id: 'DualAxesLineChartUp01',
  },
  SankeyChart01: {
    icon: SankeyChart01,
    label: 'SankeyChart01',
    id: 'SankeyChart01',
  },
  ClockFastForward: {
    icon: ClockFastForward,
    label: 'ClockFastForward',
    id: 'ClockFastForward',
  },
  ChevronLeftDouble: {
    icon: ChevronLeftDouble,
    label: 'Chevron Left Double',
    id: 'ChevronLeftDouble',
  },
  ChevronRightDouble: {
    icon: ChevronRightDouble,
    label: 'Chevron Right Double',
    id: 'ChevronRightDouble',
  },
  SchemaMapping: {
    icon: SchemaMapping,
    label: 'SchemaMapping',
    id: 'SchemaMapping',
  },
  SvgSlack: {
    icon: SvgSlack,
    label: 'Slack',
    id: 'SvgSlack',
  },
  SvgZoom: {
    icon: SvgZoom,
    label: 'Zoom',
    id: 'SvgZoom',
  },
  SvgGoogleMeet: {
    icon: SvgGoogleMeet,
    label: 'GoogleMeet',
    id: 'SvgGoogleMeet',
  },
  NoCodeAudioPlayer: {
    icon: NoCodeAudioPlayer,
    label: 'NoCodeAudioPlayer',
    id: 'NoCodeAudioPlayer',
  },
  NoCodeColorAudioPlayer: {
    icon: NoCodeColorAudioPlayer,
    label: 'NoCodeColorAudioPlayer',
    id: 'NoCodeColorAudioPlayer',
  },
  AISummary: {
    icon: AISummary,
    label: 'AISummary',
    id: 'AISummary',
  },
  NoCodePdfViewer: {
    icon: NoCodePdfViewer,
    label: 'NoCodePdfViewer',
    id: 'NoCodePdfViewer',
  },
  NoCodeColorPdfViewer: {
    icon: NoCodeColorPdfViewer,
    label: 'NoCodeColorPdfViewer',
    id: 'NoCodeColorPdfViewer',
  },
  HeartFilled: {
    icon: HeartFilled,
    label: 'HeartFilled',
    id: 'HeartFilled',
  },
  UnifyAi3: {
    icon: SvgUnifyAi3,
    label: 'UnifyAi3',
    id: 'UnifyAi3',
  },
  UsersPlus: {
    icon: UsersPlus,
    label: 'UsersPlus',
    id: 'UsersPlus',
  },
  BriefCase02: {
    icon: BriefCase02,
    label: 'BriefCase02',
    id: 'BriefCase02',
  },
  SvgSupport: {
    icon: SvgSupport,
    label: 'Support',
    id: 'SvgSupport',
  },
  Linkedin: {
    icon: Linkedin,
    label: 'Linkedin',
    id: 'Linkedin',
  },
  Facebook: {
    icon: Facebook,
    label: 'Facebook',
    id: 'Facebook',
  },
  DataTypeBoolean: {
    icon: DataTypeBoolean,
    label: 'DataTypeBoolean',
    id: 'DataTypeBoolean',
  },
  DataTypeDecimal: {
    icon: DataTypeDecimal,
    label: 'DataTypeDecimal',
    id: 'DataTypeDecimal',
  },
  DataTypeDouble: {
    icon: DataTypeDouble,
    label: 'DataTypeDouble',
    id: 'DataTypeDouble',
  },
  DataTypeInteger: {
    icon: DataTypeInteger,
    label: 'DataTypeInteger',
    id: 'DataTypeInteger',
  },
  DataTypeObject: {
    icon: DataTypeObject,
    label: 'DataTypeObject',
    id: 'DataTypeObject',
  },
  DataTypeText: {
    icon: DataTypeText,
    label: 'DataTypeText',
    id: 'DataTypeText',
  },
  DataTypeDate: {
    icon: DataTypeDate,
    label: 'DataTypeDate',
    id: 'DataTypeDate',
  },
  DataTypeDatetime: {
    icon: DataTypeDatetime,
    label: 'DataTypeDatetime',
    id: 'DataTypeDatetime',
  },
  DataTypeTime: {
    icon: DataTypeTime,
    label: 'DataTypeTime',
    id: 'DataTypeTime',
  },
  DataTypeTimestamp: {
    icon: DataTypeTimestamp,
    label: 'DataTypeTimestamp',
    id: 'DataTypeTimestamp',
  },
  DataTypeBinary: {
    icon: DataTypeBinary,
    label: 'DataTypeBinary',
    id: 'DataTypeBinary',
  },
  DataTypeArray: {
    icon: DataTypeArray,
    label: 'DataTypeArray',
    id: 'DataTypeArray',
  },
  DataTypeArrayZero: {
    icon: DataTypeArrayZero,
    label: 'DataTypeArrayZero',
    id: 'DataTypeArrayZero',
  },
  SvgSwitchOn: {
    icon: SvgSwitchOn,
    label: 'SvgSwitchOn',
    id: 'SvgSwitchOn',
  },
  SvgSwitchOff: {
    icon: SvgSwitchOff,
    label: 'SvgSwitchOff',
    id: 'SvgSwitchOff',
  },
  SvgArrowUpRight: {
    icon: SvgArrowUpRight,
    label: 'ArrowUpRight',
    id: 'SvgArrowUpRight',
  },
  SvgPaperAirplane: {
    icon: SvgPaperAirplane,
    label: 'PaperAirplane',
    id: 'SvgPaperAirplane',
  },
  SvgPeople: {
    icon: SvgPeople,
    label: 'People',
    id: 'SvgPeople',
  },
  SvgUsersRight: {
    icon: SvgUsersRight,
    label: 'UsersRight',
    id: 'SvgUsersRight',
  },
  SvgShieldTick: {
    icon: SvgShieldTick,
    label: 'ShieldTick',
    id: 'SvgShieldTick',
  },
  SvgShield03: {
    icon: SvgShield03,
    label: 'Shield03',
    id: 'SvgShield03',
  },
  SvgMessageHeartSquare: {
    icon: SvgMessageHeartSquare,
    label: 'MessageHeartSquare',
    id: 'SvgMessageHeartSquare',
  },
  SvgMedicalCross: {
    icon: SvgMedicalCross,
    label: 'MedicalCross',
    id: 'SvgMedicalCross',
  },
  SvgFingerprint04: {
    icon: SvgFingerprint04,
    label: 'Fingerprint04',
    id: 'SvgFingerprint04',
  },
  SvgCurrencyDollarCircle: {
    icon: SvgCurrencyDollarCircle,
    label: 'CurrencyDollarCircle',
    id: 'SvgCurrencyDollarCircle',
  },
  SvgCalculator: {
    icon: SvgCalculator,
    label: 'Calculator',
    id: 'SvgCalculator',
  },
  GitBranch01: {
    icon: SvgGitBranch01,
    label: 'GitBranch01',
    id: 'GitBranch01',
  },
};

export const getIconFromRegistry = (iconName?: string) => {
  if (!iconName) {
    return undefined;
  }

  const icon = ICONS_REGISTRY[iconName]?.icon;

  if (!icon) {
    console.error(`Icon not found for ${iconName}`);
    return undefined;
  }

  return icon;
};
