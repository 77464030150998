import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSchemaMapping = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 12"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.5 9 3.25 2.75m5.5-.089L3.5 5.5m5.134 1L3.5 9.5m5 .5a1 1 0 1 0 2 0 1 1 0 0 0-2 0m0-4a1 1 0 1 0 2 0 1 1 0 0 0-2 0m0-4a1 1 0 1 1 2 0 1 1 0 0 1-2 0m-7 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0m0-8a1 1 0 1 0 2 0 1 1 0 0 0-2 0m0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSchemaMapping;
