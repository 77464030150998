import * as React from 'react';
import type { SVGProps } from 'react';
const SvgXls = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={16.498} height={9} y={10} fill="#079455" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.446 13.466a.6.6 0 0 0-.245-.447q-.216-.16-.586-.16-.252 0-.426.072a.6.6 0 0 0-.266.193.46.46 0 0 0-.09.28.4.4 0 0 0 .055.228.6.6 0 0 0 .168.168q.107.07.248.122.14.05.299.085l.437.105q.318.07.584.19.267.118.46.292.195.173.303.408.109.236.111.54a1.34 1.34 0 0 1-.228.774 1.45 1.45 0 0 1-.646.506q-.42.178-1.014.178-.59 0-1.026-.18a1.5 1.5 0 0 1-.68-.535q-.242-.356-.254-.881h.995q.018.245.14.408a.8.8 0 0 0 .336.245q.21.08.477.08.26 0 .454-.075.195-.075.301-.212a.5.5 0 0 0 .107-.31.4.4 0 0 0-.097-.276.74.74 0 0 0-.28-.19 3 3 0 0 0-.45-.143l-.53-.133q-.615-.15-.97-.468-.357-.318-.354-.857a1.27 1.27 0 0 1 .235-.772q.24-.33.658-.516.417-.185.95-.185.54 0 .945.185.406.186.632.516t.233.765zM7.012 16.93v-4.865H8.04v4.017h2.086v.848zm-2.836-3.206-.981-1.658H2.029l1.494 2.432L2 16.93h1.178l.998-1.66h.038l.997 1.66h1.183L4.876 14.5l1.485-2.433H5.2l-.986 1.659z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgXls;
