import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorMessageInput = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M31 9H7"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M25 16H7"
    />
    <rect width={28} height={11} x={5} y={23} stroke="#CFD4DE" strokeWidth={1.3} rx={2} />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M30 26.5v.762c0 .63 0 .946-.123 1.186-.107.212-.28.384-.491.492-.24.122-1.756.122-2.386.122h-1.7m0 0L27 27.5m-1.7 1.563 1.7 1.651"
    />
  </svg>
);
export default SvgNoCodeColorMessageInput;
