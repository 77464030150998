import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeRepeatableList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.641 13.397H5.271c-.404 0-.606 0-.775-.033a1.74 1.74 0 0 1-1.368-1.368c-.033-.168-.033-.37-.033-.775s0-.607.033-.775c.138-.691.678-1.231 1.368-1.368.169-.034.37-.034.775-.034h.91m-.935 0h13.483c.404 0 .607 0 .775.034.69.137 1.23.677 1.368 1.368.033.168.033.37.033.775 0 .404 0 .607-.033.775a1.74 1.74 0 0 1-1.368 1.368m0 0c-.169.034-.37.034-.775.034H5.246zm-1.937-6.567H5.27c-.404 0-.606 0-.775-.034a1.74 1.74 0 0 1-1.368-1.368c-.033-.168-.033-.37-.033-.775s0-.607.033-.775c.138-.69.678-1.23 1.368-1.368.169-.034.37-.034.775-.034h10.804m-.829 0h3.483c.404 0 .607 0 .775.034.69.137 1.23.677 1.368 1.368.033.168.033.37.033.775s0 .607-.033.775a1.74 1.74 0 0 1-1.368 1.368c-.169.034-.37.034-.775.034h-3.483m2.32 14.758v-3m0 0v-3m0 3h-3m3 0h3m-17.471-.774c0-.405 0-.607.033-.776.138-.69.678-1.23 1.368-1.368.169-.033.37-.033.775-.033h3.483c.405 0 .607 0 .775.033.69.138 1.23.678 1.368 1.368.034.169.034.37.034.776 0 .404 0 .606-.034.774a1.74 1.74 0 0 1-1.368 1.369c-.168.033-.37.033-.775.033H5.271c-.404 0-.606 0-.775-.033a1.74 1.74 0 0 1-1.368-1.369c-.033-.168-.033-.37-.033-.774"
    />
  </svg>
);
export default SvgNoCodeRepeatableList;
