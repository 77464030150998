import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAlignVerticalStart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-align-vertical-justify-start"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={14} height={6} x={5} y={16} rx={2} />
    <rect width={10} height={6} x={7} y={6} rx={2} />
    <path d="M2 2h20" />
  </svg>
);
export default SvgAlignVerticalStart;
