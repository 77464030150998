import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPythonLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.506 8.294V5.118c0-1.17.948-2.118 2.117-2.118h2.753c1.17 0 2.118.948 2.118 2.118v3.176m0 7.2v3.388c0 1.17-.948 2.118-2.118 2.118h-2.752a2.12 2.12 0 0 1-2.118-2.118v-6.564c0-.234.19-.424.423-.424h6.141c.235 0 .424-.19.424-.423V8.294M12.5 15.282h6.382c1.17 0 2.118-.948 2.118-2.117v-2.753c0-1.17-.948-2.118-2.118-2.118h-3.388M11.5 5.618h.01M12.606 18h.01m-4.11-2.718H5.118A2.12 2.12 0 0 1 3 13.165v-2.753c0-1.17.948-2.118 2.118-2.118H11m1-2.676a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M13.106 18a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
    />
  </svg>
);
export default SvgPythonLanguage;
