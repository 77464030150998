import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowCircleBrokenDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.41 2.34a10 10 0 0 1 9.661 2.589c3.905 3.905 3.905 10.237 0 14.142s-10.237 3.905-14.142 0a10 10 0 0 1-2.59-9.66M15 9v6m0 0H9m6 0L5 5"
    />
  </svg>
);
export default SvgArrowCircleBrokenDownRight;
