import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4v3.2m0 9.6V20m-4.8-8H4m16 0h-3.2m.863 5.663L15.4 15.4m2.263-9L15.4 8.663m-9.063 9L8.6 15.4m-2.263-9L8.6 8.663"
    />
  </svg>
);
export default SvgNoCodeLoader;
