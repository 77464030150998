import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeNavigationContainer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g
      opacity={0.7}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <path
        fill="#fff"
        d="M32.75 2C35.65 2 38 4.35 38 7.25v25.5c0 2.9-2.35 5.25-5.25 5.25H7.25A5.25 5.25 0 0 1 2 32.75V7.25C2 4.35 4.35 2 7.25 2h25.5"
      />
      <path
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M15 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38H15m0-36h17.75C35.65 2 38 4.35 38 7.25v25.5c0 2.9-2.35 5.25-5.25 5.25H15m0-36v36"
      />
      <rect width={14.625} height={10.362} x={18.875} y={7} fill="#CFD4DE" rx={2.5} />
      <path
        fill="#EAE9FE"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M2 32.75C2 35.65 4.35 38 7.25 38H15V2H7.25A5.25 5.25 0 0 0 2 7.25z"
      />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M6.66 9.09h3.84" />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M6.66 15.09h3.84M6.66 21.09h3.84" />
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M20.351 21.836h11.68M20.351 27.501h11.68"
      />
    </g>
  </svg>
);
export default SvgNoCodeNavigationContainer;
