import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCopilot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2.5H7.25A5.25 5.25 0 0 0 2 7.75v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25V7.75c0-2.9-2.35-5.25-5.25-5.25Z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M22 11.894h9M22 20.643h9M8.7 29.392h22.6"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.227}
      d="M8.701 22.206v-3.001m0-6.003V10.2m-1.5 1.5h3.001m-3.001 9.006h3.001m3.602-9.905-1.041 2.706c-.17.44-.254.66-.386.845-.116.165-.26.308-.424.425-.185.131-.405.216-.845.385L8.4 16.203l2.707 1.041c.44.17.66.254.845.386q.248.177.424.424c.132.185.217.405.386.845l1.04 2.707 1.042-2.707c.17-.44.254-.66.385-.845q.177-.247.425-.424c.185-.132.405-.216.845-.386l2.707-1.04-2.707-1.042c-.44-.17-.66-.254-.845-.385a1.8 1.8 0 0 1-.425-.425c-.131-.185-.216-.405-.385-.845z"
    />
  </svg>
);
export default SvgNoCodeColorCopilot;
