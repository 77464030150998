import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorRepeatable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 38H7.25A5.25 5.25 0 0 1 2 32.75V26m0-12V7.25C2 4.35 4.35 2 7.25 2H14m24 24v6.75c0 2.9-2.35 5.25-5.25 5.25H26m0-36h6.75C35.65 2 38 4.35 38 7.25V14"
    />
    <rect width={7} height={7} x={7.25} y={7.563} fill="#CFD4DE" rx={0.1} />
    <rect width={7} height={7} x={7.25} y={16.771} fill="#CFD4DE" rx={0.1} />
    <rect width={7} height={7} x={7.25} y={25.98} fill="#705AF8" rx={0.1} />
    <rect width={7} height={7} x={16.5} y={7.563} fill="#CFD4DE" rx={0.1} />
    <rect width={7} height={7} x={16.5} y={16.771} fill="#705AF8" rx={0.1} />
    <rect width={7} height={7} x={16.5} y={25.98} fill="#CFD4DE" rx={0.1} />
    <rect width={7} height={7} x={25.75} y={7.563} fill="#705AF8" rx={0.1} />
    <rect width={7} height={7} x={25.75} y={16.771} fill="#CFD4DE" rx={0.1} />
    <rect width={7} height={7} x={25.75} y={25.98} fill="#CFD4DE" rx={0.1} />
  </svg>
);
export default SvgNoCodeColorRepeatable;
