import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#2EB67D"
      d="M107.97 59.812c5.781 0 10.467-4.687 10.467-10.468 0-5.782-4.687-10.468-10.468-10.468-5.782 0-10.469 4.687-10.469 10.469v10.467zm-29.314 0c5.782 0 10.469-4.687 10.469-10.47v-29.31c0-5.782-4.687-10.469-10.469-10.469s-10.469 4.687-10.469 10.47v29.31c0 5.782 4.688 10.469 10.47 10.469"
    />
    <path
      fill="#E01E5A"
      d="M20.03 68.188c-5.78 0-10.468 4.687-10.468 10.468s4.688 10.468 10.469 10.468c5.782 0 10.469-4.687 10.469-10.469V68.188zm29.314 0c-5.782 0-10.469 4.687-10.469 10.47v29.31c0 5.782 4.687 10.469 10.469 10.469s10.468-4.687 10.468-10.469v-29.31c0-5.783-4.686-10.47-10.468-10.47"
    />
    <path
      fill="#ECB22E"
      d="M68.188 107.97c0 5.781 4.687 10.467 10.468 10.467s10.468-4.687 10.468-10.468c0-5.782-4.687-10.469-10.469-10.469H68.188zm0-29.314c0 5.782 4.687 10.469 10.469 10.469h29.311c5.781 0 10.468-4.687 10.468-10.469s-4.687-10.469-10.468-10.469H78.657c-5.782 0-10.469 4.688-10.469 10.47"
    />
    <path
      fill="#36C5F0"
      d="M59.812 20.03c0-5.78-4.687-10.467-10.468-10.467-5.782 0-10.468 4.687-10.468 10.468 0 5.782 4.687 10.469 10.468 10.469h10.468zm0 29.314c0-5.782-4.688-10.469-10.47-10.469h-29.31c-5.782 0-10.469 4.687-10.469 10.469s4.687 10.468 10.469 10.468h29.31c5.782 0 10.47-4.686 10.47-10.468"
    />
  </svg>
);
export default SvgSlack;
