import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorAudioPlayer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path fill="#705AF8" d="M18.24 3.173a.747.747 0 1 1 1.493 0v8.96H18.24z" />
    <path
      fill="#705AF8"
      d="M18.24 3.412c0-.584.384-1.099.945-1.264l6.12-1.809a.895.895 0 1 1 .507 1.717l-6.726 1.988a.66.66 0 0 1-.846-.632M18.24 5.975l8.214-2.427v1.867L18.24 7.84z"
    />
    <path fill="#705AF8" d="M24.96.933h1.493v9.333H24.96z" />
    <circle cx={17.867} cy={12.133} r={1.867} fill="#705AF8" />
    <circle cx={24.587} cy={10.267} r={1.867} fill="#705AF8" />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.8}
      d="M5 6v4M11 7v2"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.8}
      d="M8 3v10M2 7.5v1"
    />
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1 21a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.8}
      d="M19.417 22v2M22.537 22v2M5.16 23h10.4"
    />
  </svg>
);
export default SvgNoCodeColorAudioPlayer;
