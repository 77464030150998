import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGoogleMeet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path fill="#fff" d="M0 0h128v128H0z" />
    <path fill="#fff" d="M34.93 44.93v37.21h37.21V44.93z" />
    <path fill="#1E88E5" d="M14 47.256v32.558l11.628 2.326 11.628-2.326V47.256L25.628 44.93z" />
    <path
      fill="#4CAF50"
      d="M93.07 63.535v32.558a6.976 6.976 0 0 1-6.977 6.977H37.256L34.93 91.442l2.326-11.628h32.558v-16.28l11.628-2.325z"
    />
    <path
      fill="#FBC02D"
      d="M93.07 30.977v32.558H69.814v-16.28H37.256L34.93 35.629 37.256 24h48.837a6.976 6.976 0 0 1 6.977 6.977"
    />
    <path fill="#1565C0" d="M37.256 79.814v23.256h-16.28A6.977 6.977 0 0 1 14 96.093V79.814z" />
    <path fill="#E53935" d="M37.256 24v23.256H14z" />
    <path fill="#2E7D32" d="M95.395 63.535 93.07 83.186 69.814 63.535 93.07 43.884z" />
    <path
      fill="#4CAF50"
      d="M114 31.233v64.604c0 1.954-2.279 3.047-3.791 1.814l-17.14-14.465V43.884l17.14-14.465c1.512-1.233 3.791-.14 3.791 1.814"
    />
  </svg>
);
export default SvgGoogleMeet;
