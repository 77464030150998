import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.643 2.709h-25.5a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25v-25.5c0-2.9-2.35-5.25-5.25-5.25Z"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M17.95 11.285h13.738M17.95 20.709h13.738M17.95 30.123h13.738"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M17.95 11.285h6.238"
    />
    <path
      stroke="#F04438"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M17.95 20.709h9.613"
    />
    <path
      stroke="#FDB022"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M17.95 30.123h2.744"
    />
    <path fill="#F04438" d="M9.691 24.539a3.835 3.835 0 1 0 0-7.67 3.835 3.835 0 0 0 0 7.67" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.75}
      d="m11.157 19.238-2.931 2.93m0-2.93 2.93 2.93"
    />
    <path fill="#705AF8" d="M9.691 15.12a3.835 3.835 0 1 0 0-7.67 3.835 3.835 0 0 0 0 7.67" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.672}
      d="m7.639 11.332 1.09 1.09c.089.089.133.133.184.15a.22.22 0 0 0 .138 0c.052-.017.096-.061.185-.15l2.434-2.434"
    />
    <path fill="#FDB022" d="M9.691 33.958a3.835 3.835 0 1 0 0-7.67 3.835 3.835 0 0 0 0 7.67" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.75}
      d="M9.654 28.27v1.66m0 1.658h.004"
    />
  </svg>
);
export default SvgNoCodeColorStatus;
