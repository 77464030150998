import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCurrencyEuro = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 5.519a8.5 8.5 0 1 0 0 12.962M3 14h10M3 10h10"
    />
  </svg>
);
export default SvgCurrencyEuro;
