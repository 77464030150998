import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBellNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.354 21c.705.622 1.632 1 2.646 1s1.94-.378 2.646-1M13 2.084A6 6 0 0 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.732.099 1.928.099H18.61c1.196 0 1.794 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.088-1.936-.492-.79-.955-1.81-1.264-3.105"
      />
      <path
        fill="#5C37EB"
        d="M20.902 2.598a3.75 3.75 0 1 1-5.304 5.304 3.75 3.75 0 0 1 5.304-5.304"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBellNotification;
