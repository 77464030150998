import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPptx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={21.775} height={9} y={10} fill="#E62E05" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2 12v5h1.057v-1.621h.889q.576 0 .981-.213.408-.212.623-.593t.215-.879-.213-.879a1.47 1.47 0 0 0-.61-.598q-.4-.217-.97-.217zm1.057 2.532h.718q.305 0 .503-.105a.7.7 0 0 0 .298-.296.9.9 0 0 0 .1-.437.9.9 0 0 0-.1-.434.67.67 0 0 0-.298-.29 1.1 1.1 0 0 0-.508-.106h-.713zM6.451 17v-5h1.973q.569 0 .97.217.4.215.61.598.212.38.212.88 0 .497-.215.878-.214.381-.622.593-.405.213-.982.213h-.889V17zm1.775-2.468h-.718v-1.668h.713q.308 0 .508.105a.67.67 0 0 1 .298.29q.1.187.1.435 0 .247-.1.437a.7.7 0 0 1-.298.296q-.198.105-.503.105M10.712 12v.872h1.53V17h1.046v-4.128h1.53V12zm6.782 1.704L16.486 12h-1.199l1.536 2.5-1.565 2.5h1.211l1.025-1.707h.04L18.558 17h1.216l-1.56-2.5L19.74 12h-1.194l-1.014 1.704z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPptx;
