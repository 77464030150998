import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <rect width={32} height={15} x={4} y={13} fill="#705AF8" rx={3} />
    <path
      fill="#fff"
      d="M10.156 23h-.984l1.792-5.09h1.138L13.897 23h-.984l-1.36-4.047h-.04zm.032-1.996h2.685v.74h-2.685zm5.89 2.07q-.573 0-.983-.25a1.7 1.7 0 0 1-.629-.694 2.3 2.3 0 0 1-.218-1.024q0-.582.223-1.027.224-.447.632-.696a1.8 1.8 0 0 1 .97-.25q.463 0 .822.17.36.17.574.48t.244.721h-.86a.82.82 0 0 0-.249-.46q-.194-.186-.52-.186a.8.8 0 0 0-.484.15.96.96 0 0 0-.326.422 1.7 1.7 0 0 0-.114.66q0 .391.114.672a1 1 0 0 0 .32.43q.21.15.49.15a.8.8 0 0 0 .356-.075.7.7 0 0 0 .266-.221.85.85 0 0 0 .147-.351h.86a1.5 1.5 0 0 1-.239.718q-.206.31-.562.488a1.9 1.9 0 0 1-.835.174m4.28-3.892v.696h-2.195v-.696zm-1.653-.915h.9v3.585q0 .18.055.278a.3.3 0 0 0 .149.13q.092.033.204.034a1 1 0 0 0 .154-.012q.072-.013.11-.023l.15.704a1.686 1.686 0 0 1-.53.09 1.5 1.5 0 0 1-.608-.103.95.95 0 0 1-.43-.353 1.05 1.05 0 0 1-.154-.596zM21.111 23v-3.818h.9V23zm.452-4.36a.53.53 0 0 1-.368-.142.46.46 0 0 1-.154-.345q0-.204.154-.346a.52.52 0 0 1 .368-.144q.216 0 .368.144.154.142.154.346 0 .202-.154.345a.52.52 0 0 1-.368.142m3.039 4.435a1.85 1.85 0 0 1-.97-.247 1.67 1.67 0 0 1-.636-.688 2.26 2.26 0 0 1-.224-1.034q0-.592.224-1.037.226-.445.636-.69.41-.247.97-.247.558 0 .969.246.41.247.634.691.225.445.226 1.037 0 .591-.226 1.034-.224.442-.634.688a1.85 1.85 0 0 1-.97.247m.004-.721a.78.78 0 0 0 .507-.167q.204-.169.304-.452.102-.285.102-.632 0-.35-.102-.634a1 1 0 0 0-.304-.454.77.77 0 0 0-.507-.17.8.8 0 0 0-.517.17 1 1 0 0 0-.305.454q-.1.284-.1.634 0 .348.1.632.102.284.305.452.207.167.517.167m3.489-1.591V23h-.9v-3.818h.86v.649h.045q.13-.321.42-.51t.718-.189q.395 0 .689.17.296.168.457.489.164.32.162.778V23h-.9v-2.292q0-.383-.2-.599-.196-.216-.544-.216a.84.84 0 0 0-.42.104.72.72 0 0 0-.285.296 1 1 0 0 0-.102.47"
    />
  </svg>
);
export default SvgNoCodeColorButton;
