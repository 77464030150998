import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAlignVerticalStretch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-stretch-vertical"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={6} height={20} x={4} y={2} rx={2} />
    <rect width={6} height={20} x={14} y={2} rx={2} />
  </svg>
);
export default SvgAlignVerticalStretch;
