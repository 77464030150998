import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#705AF8"
      d="M8.951 32C8.15 32 7.5 31.284 7.5 30.4V19.725c0-.884.65-1.6 1.451-1.6h1.598c.801 0 1.451.716 1.451 1.6V30.4c0 .884-.65 1.6-1.451 1.6z"
    />
    <path
      fill="#938AFB"
      d="M15.784 32c-.801 0-1.45-.691-1.45-1.544V9.544c0-.853.649-1.544 1.45-1.544h1.598c.802 0 1.451.691 1.451 1.544v20.912c0 .853-.65 1.544-1.45 1.544z"
    />
    <path
      fill="#B7B4FE"
      d="M22.618 32c-.802 0-1.451-.716-1.451-1.6V14.725c0-.884.65-1.6 1.45-1.6h1.599c.801 0 1.45.716 1.45 1.6V30.4c0 .884-.649 1.6-1.45 1.6z"
    />
    <path
      fill="#D6D5FF"
      d="M29.451 32C28.65 32 28 31.284 28 30.4v-7.675c0-.884.65-1.6 1.451-1.6h1.598c.801 0 1.451.716 1.451 1.6V30.4c0 .884-.65 1.6-1.451 1.6z"
    />
  </svg>
);
export default SvgNoCodeColorChart;
