import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorProgressBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.643 1.88h-25.5a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25V7.13c0-2.899-2.35-5.25-5.25-5.25Z"
    />
    <path
      stroke="#705AF8"
      strokeMiterlimit={10}
      strokeWidth={0.67}
      d="M30.495 20.743H9.505a2.69 2.69 0 0 0-2.69 2.69v.09a2.69 2.69 0 0 0 2.69 2.69h20.99a2.69 2.69 0 0 0 2.69-2.69v-.09a2.69 2.69 0 0 0-2.69-2.69Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={5.3}
      d="M9.535 23.474h11.68"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M8.45 31.366h23.1M8.45 14.756h5.62"
    />
    <path
      fill="#667085"
      d="M31.21 14.744v-.25q0-.283.12-.52.123-.24.353-.384.233-.146.568-.146.339 0 .57.144a.9.9 0 0 1 .35.383q.12.237.12.524v.25q0 .282-.12.523a.93.93 0 0 1-.352.38 1.05 1.05 0 0 1-.568.146 1.05 1.05 0 0 1-.57-.145.94.94 0 0 1-.351-.381q-.12-.24-.12-.524m.672-.25v.25q0 .164.078.31.08.145.29.145t.287-.143a.64.64 0 0 0 .079-.312v-.25a.7.7 0 0 0-.074-.313q-.074-.146-.291-.146-.208 0-.289.146a.64.64 0 0 0-.08.314m-3.098-2.455v-.25q0-.285.122-.524.123-.24.353-.383.234-.143.564-.143.34 0 .572.143.23.143.35.383.121.239.121.524v.25q0 .285-.122.524a.93.93 0 0 1-.354.38q-.23.142-.567.141a1.07 1.07 0 0 1-.57-.143.96.96 0 0 1-.351-.38 1.16 1.16 0 0 1-.118-.522m.676-.25v.25q0 .168.079.311.08.144.284.143.211 0 .288-.143a.64.64 0 0 0 .079-.311v-.25a.7.7 0 0 0-.074-.313q-.075-.146-.293-.146-.206 0-.284.148a.66.66 0 0 0-.079.312m-.371 3.841 3.25-4.727h.604l-3.25 4.727zM26 15.735q-.594-.003-1.024-.293-.427-.291-.658-.843-.229-.552-.226-1.327 0-.774.229-1.318.23-.545.657-.829.43-.285 1.023-.286.593 0 1.02.286.429.286.66.831.23.542.229 1.316 0 .777-.231 1.33-.227.55-.656.842-.426.29-1.022.29m0-.829q.407 0 .65-.409.241-.408.24-1.225 0-.538-.111-.896-.11-.357-.31-.538a.67.67 0 0 0-.468-.18q-.405 0-.646.404-.243.405-.245 1.21 0 .544.108.91.111.36.312.544.2.18.47.18M20.57 15.63l1.96-3.877v-.033h-2.283v-.817h3.317v.829l-1.962 3.899z"
    />
  </svg>
);
export default SvgNoCodeColorProgressBar;
