import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowNarrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m0 0 6-6m-6 6-6-6" />
  </svg>
);
export default SvgArrowNarrowDown;
