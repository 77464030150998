import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOkta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 129 128"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#007DC1"
      d="M17.831 53.478c-8.618 0-15.593 6.975-15.593 15.593s6.975 15.593 15.593 15.593 15.593-6.975 15.593-15.593-6.975-15.593-15.593-15.593m0 23.405c-4.309 0-7.812-3.503-7.812-7.812s3.503-7.812 7.812-7.812 7.812 3.503 7.812 7.812-3.503 7.812-7.812 7.812m27.404-2.573c0-1.24 1.488-1.829 2.356-.961 3.906 3.968 10.354 10.788 10.385 10.819.093.093.186.248.558.372.155.062.403.062.682.062h7.037c1.271 0 1.643-1.457 1.054-2.201L55.651 70.466l-.62-.62c-1.333-1.581-1.178-2.201.341-3.813L64.61 55.71c.59-.744.217-2.17-1.085-2.17H57.14c-.248 0-.434 0-.62.062-.372.124-.527.248-.62.372-.03.031-5.146 5.549-8.308 8.928-.868.93-2.418.31-2.418-.961V44.24c0-.899-.744-1.24-1.333-1.24h-5.208c-.899 0-1.333.589-1.333 1.116v39.246c0 .899.744 1.147 1.364 1.147h5.208c.806 0 1.333-.589 1.333-1.178V74.31h.031Zm42.532 8.866-.558-5.208c-.062-.713-.744-1.209-1.457-1.085a8 8 0 0 1-1.209.093c-4.154 0-7.533-3.255-7.78-7.378v-6.82c0-.837.62-1.519 1.456-1.519h6.975c.496 0 1.24-.434 1.24-1.333v-4.929c0-.961-.62-1.457-1.178-1.457H78.22c-.806 0-1.457-.589-1.488-1.395V44.24c0-.496-.372-1.24-1.333-1.24h-5.177c-.65 0-1.27.403-1.27 1.209v25.389c.216 8.432 7.13 15.159 15.592 15.159.713 0 1.395-.062 2.077-.155.744-.093 1.24-.713 1.147-1.426Zm37.572-6.541c-4.402 0-5.084-1.581-5.084-7.502V54.842c0-.496-.372-1.333-1.364-1.333h-5.208c-.651 0-1.364.527-1.364 1.333v.651a15.4 15.4 0 0 0-7.688-2.046c-8.618 0-15.593 6.975-15.593 15.593s6.975 15.593 15.593 15.593c3.875 0 7.409-1.426 10.137-3.751 1.457 2.232 3.813 3.72 7.502 3.751.62 0 3.968.124 3.968-1.457v-5.549c0-.465-.372-.992-.899-.992m-20.677.248c-4.309 0-7.812-3.503-7.812-7.812s3.503-7.812 7.812-7.812 7.812 3.503 7.812 7.812c-.031 4.309-3.534 7.812-7.812 7.812"
    />
  </svg>
);
export default SvgOkta;
