import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#0A66C2"
      d="M106.618 14H21.382A7.38 7.38 0 0 0 14 21.382v85.236A7.383 7.383 0 0 0 21.382 114h85.236a7.385 7.385 0 0 0 7.382-7.382V21.382A7.383 7.383 0 0 0 106.618 14M43.806 99.188H28.77V51.43h15.035zm-7.528-54.375a8.632 8.632 0 1 1 8.68-8.626 8.506 8.506 0 0 1-8.68 8.626m62.944 54.416H84.195V73.14c0-7.694-3.271-10.07-7.494-10.07-4.458 0-8.833 3.362-8.833 10.264V99.23H52.833V51.465h14.459v6.618h.194c1.451-2.937 6.535-7.958 14.292-7.958 8.389 0 17.451 4.98 17.451 19.563z"
    />
  </svg>
);
export default SvgLinkedin;
