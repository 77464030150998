import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuoteEnd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#000"
      d="M13.464 18.93 14.19 20C19.21 16.765 22 12.834 22 9.599 22 6.471 19.6 5 17.565 5c-2.567 0-4.38 2.086-4.38 4.278 0 1.845 1.228 3.423 2.874 4.011.474.161.92.295.92 1.07 0 .989-.753 2.459-3.515 4.571m-11.074 0L3.116 20c4.965-3.235 7.81-7.166 7.81-10.401C10.926 6.471 8.471 5 6.436 5 3.84 5 2 7.086 2 9.278c0 1.845 1.255 3.423 2.957 4.011.474.161.893.295.893 1.07 0 .989-.781 2.46-3.459 4.572"
    />
  </svg>
);
export default SvgQuoteEnd;
