import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodePdfViewer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#000"
      d="M15.172 14.768c-.624 0-1.405.112-1.66.151-1.057-1.136-1.358-1.782-1.426-1.955.092-.242.41-1.163.456-2.346.023-.593-.099-1.035-.361-1.316A.94.94 0 0 0 11.51 9c-.318 0-.852.166-.852 1.276 0 .964.436 1.986.557 2.25-.636 1.905-1.318 3.21-1.463 3.477C7.2 16.993 7 17.95 7 18.223c0 .487.337.778.902.778 1.372 0 2.624-2.372 2.831-2.783.975-.4 2.279-.648 2.61-.707.952.933 2.052 1.182 2.508 1.182.344 0 1.149 0 1.149-.852 0-.791-.985-1.072-1.828-1.072m-.067.56c.742 0 .938.252.938.385 0 .084-.031.357-.429.357-.356 0-.97-.212-1.576-.672.252-.034.626-.07 1.067-.07m-3.632-5.786c.067 0 .112.023.148.075.214.304.042 1.3-.168 2.078-.201-.668-.353-1.692-.14-2.052.042-.07.09-.1.16-.1m-.36 5.96c.268-.558.569-1.371.733-1.831.327.565.768 1.089 1.023 1.373-.794.173-1.394.345-1.756.458m-3.58 2.794c-.018-.021-.02-.067-.007-.122.028-.114.241-.682 1.786-1.393-.222.359-.567.871-.947 1.254q-.402.387-.619.388c-.05 0-.121-.014-.213-.127"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M14 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V8z"
    />
  </svg>
);
export default SvgNoCodePdfViewer;
