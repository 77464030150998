import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorDivider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32 8v4.33c0 1.674-1.567 3.031-3.5 3.031h-17c-1.933 0-3.5-1.357-3.5-3.031V8M8 32v-4.33c0-1.674 1.567-3.031 3.5-3.031h17c1.933 0 3.5 1.357 3.5 3.031V32"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.6}
      d="M8.5 20h23"
    />
  </svg>
);
export default SvgNoCodeColorDivider;
