import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReply = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.927 13.354 4.5 8.927m0 0L8.927 4.5M4.5 8.927h5.667c2.975 0 4.463 0 5.599.58 1 .508 1.812 1.321 2.322 2.32.579 1.137.579 2.625.579 5.6v1.24"
    />
  </svg>
);
export default SvgReply;
