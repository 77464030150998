import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGovernance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 2.667c.852 0 1.279 0 1.628.093a2.75 2.75 0 0 1 1.945 1.945c.094.35.094.776.094 1.628v8.434c0 1.54 0 2.31-.3 2.898a2.75 2.75 0 0 1-1.202 1.202c-.588.3-1.358.3-2.898.3H6.9c-1.54 0-2.31 0-2.898-.3A2.75 2.75 0 0 1 2.8 17.665c-.3-.588-.3-1.358-.3-2.898V6.333c0-.852 0-1.278.094-1.628A2.75 2.75 0 0 1 4.538 2.76c.35-.093.776-.093 1.629-.093m8.25 15.583c0-.782 0-1.173-.099-1.49a2.27 2.27 0 0 0-1.528-1.495c-.325-.096-.725-.096-1.524-.096H8.4c-.8 0-1.2 0-1.525.096A2.27 2.27 0 0 0 5.35 16.76c-.099.318-.099.71-.099 1.491M7.633 4.5h4.4c.514 0 .77 0 .966-.1A.9.9 0 0 0 13.4 4c.1-.197.1-.453.1-.967V2.3c0-.513 0-.77-.1-.966a.9.9 0 0 0-.4-.4c-.197-.1-.453-.1-.967-.1h-4.4c-.513 0-.77 0-.966.1a.9.9 0 0 0-.4.4c-.1.196-.1.453-.1.966v.733c0 .514 0 .77.1.966a.9.9 0 0 0 .4.401c.196.1.453.1.966.1m4.779 6.188c0 1.392-1.155 2.52-2.579 2.52s-2.578-1.128-2.578-2.52S8.41 8.166 9.833 8.166s2.579 1.128 2.579 2.52"
    />
  </svg>
);
export default SvgGovernance;
