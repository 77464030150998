import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuoteStart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#000"
      d="M10.536 6.07 9.81 5C4.79 8.235 2 12.166 2 15.401 2 18.529 4.4 20 6.435 20c2.567 0 4.38-2.086 4.38-4.278 0-1.845-1.228-3.423-2.874-4.011-.474-.161-.92-.295-.92-1.07 0-.989.753-2.459 3.515-4.571m11.074 0L20.884 5c-4.965 3.235-7.81 7.166-7.81 10.401 0 3.128 2.454 4.599 4.49 4.599C20.16 20 22 17.914 22 15.722c0-1.845-1.255-3.423-2.957-4.011-.474-.161-.893-.295-.893-1.07 0-.989.781-2.46 3.459-4.572"
    />
  </svg>
);
export default SvgQuoteStart;
