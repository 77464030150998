import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475q.029.029.029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={18.398} height={9} y={10} fill="#155EEF" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.341 14.5q0 .795-.302 1.355-.3.558-.817.853a2.3 2.3 0 0 1-1.16.292q-.648 0-1.164-.295a2.1 2.1 0 0 1-.815-.853q-.3-.558-.3-1.352 0-.795.3-1.355.299-.558.815-.85A2.3 2.3 0 0 1 9.062 12q.645 0 1.16.295.518.292.817.85.303.559.302 1.355m-1.043 0q0-.516-.155-.87a1.18 1.18 0 0 0-.43-.537 1.16 1.16 0 0 0-.65-.183q-.375 0-.652.183-.278.183-.432.537t-.153.87q0 .516.153.87.154.354.432.537.277.183.651.183.373 0 .651-.183.279-.183.43-.537.155-.354.155-.87m6.1-.73h-1.041a1 1 0 0 0-.117-.358.9.9 0 0 0-.226-.271 1 1 0 0 0-.318-.171 1.2 1.2 0 0 0-.387-.06q-.378 0-.659.188a1.2 1.2 0 0 0-.434.542 2.15 2.15 0 0 0-.155.86q0 .521.155.874.156.355.437.535.28.18.649.18.206 0 .382-.054.178-.055.316-.159a.9.9 0 0 0 .228-.26q.093-.15.129-.346l1.04.005a1.941 1.941 0 0 1-.632 1.2q-.268.242-.641.385-.37.14-.839.14-.65 0-1.165-.295-.51-.294-.807-.853-.295-.558-.295-1.352 0-.795.3-1.355.299-.558.812-.85A2.3 2.3 0 0 1 14.285 12q.423 0 .784.119.364.119.644.347.281.225.457.554.177.327.228.75M2 16.933h1.725q.743 0 1.274-.292.535-.292.82-.839.287-.546.287-1.307 0-.758-.287-1.302a1.97 1.97 0 0 0-.815-.834q-.53-.292-1.264-.292H2zm1.683-.881h-.654v-3.104h.656q.456 0 .767.162.312.162.469.504.159.342.159.881 0 .545-.16.89a1.04 1.04 0 0 1-.47.505q-.31.162-.767.162"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDoc;
