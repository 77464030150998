import * as React from 'react';
import type { SVGProps } from 'react';
const SvgXlsx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={21.354} height={9} y={10} fill="#079455" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.201 13.019a.6.6 0 0 1 .245.447h.986a1.36 1.36 0 0 0-.233-.765 1.5 1.5 0 0 0-.632-.516 2.25 2.25 0 0 0-.945-.185q-.533 0-.95.185a1.6 1.6 0 0 0-.658.516q-.238.33-.236.772-.002.54.354.857.357.318.972.468l.53.133q.266.064.449.143a.74.74 0 0 1 .28.19.4.4 0 0 1 .097.275q0 .176-.107.311a.7.7 0 0 1-.301.212q-.193.076-.454.076a1.3 1.3 0 0 1-.477-.081.8.8 0 0 1-.335-.245.75.75 0 0 1-.14-.408h-.996q.012.524.254.881.245.354.68.535.436.18 1.026.18.594 0 1.014-.178.423-.181.646-.506.226-.328.228-.774a1.3 1.3 0 0 0-.111-.54 1.2 1.2 0 0 0-.302-.408 1.8 1.8 0 0 0-.46-.292 3 3 0 0 0-.585-.19l-.437-.105a3 3 0 0 1-.3-.085 1.3 1.3 0 0 1-.247-.122.6.6 0 0 1-.168-.168.4.4 0 0 1-.055-.228q0-.157.09-.28a.6.6 0 0 1 .266-.193q.174-.07.426-.071.37 0 .586.159m3.934.706-.98-1.658h-1.167l1.494 2.432-1.522 2.432h1.178l.997-1.66h.038l.998 1.66h1.183L17.836 14.5l1.485-2.433h-1.162l-.986 1.659zM7.012 16.93v-4.865H8.04v4.017h2.086v.848zm-2.836-3.206-.981-1.658H2.029l1.494 2.432L2 16.93h1.178l.998-1.66h.038l.997 1.66h1.183L4.876 14.5l1.485-2.433H5.2l-.986 1.659z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgXlsx;
