import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.768 12.421h4.211v4.21m-7.57-4.21H4.4m4.219 4.21h-.008M11.987 20h-.008m7.587-7.579h-.008M4.4 16.631h1.263m9.263-4.21h1.684M4.4 20h4.21M11.98 4v5.053M16.695 20h1.515c.472 0 .708 0 .888-.092a.84.84 0 0 0 .368-.368c.092-.18.092-.416.092-.887v-1.516c0-.472 0-.708-.092-.888a.84.84 0 0 0-.368-.368c-.18-.091-.416-.091-.887-.091h-1.516c-.472 0-.708 0-.888.091a.84.84 0 0 0-.368.368c-.092.18-.092.416-.092.888v1.516c0 .471 0 .707.092.887.08.159.21.288.368.368.18.092.416.092.888.092m0-10.947h1.515c.472 0 .708 0 .888-.092a.84.84 0 0 0 .368-.368c.092-.18.092-.416.092-.888V6.19c0-.471 0-.707-.092-.887a.84.84 0 0 0-.368-.368c-.18-.092-.416-.092-.887-.092h-1.516c-.472 0-.708 0-.888.092a.84.84 0 0 0-.368.368c-.092.18-.092.416-.092.887v1.516c0 .472 0 .708.092.888.08.158.21.287.368.368.18.092.416.092.888.092m-10.948 0h1.516c.472 0 .708 0 .888-.092a.84.84 0 0 0 .368-.368c.092-.18.092-.416.092-.888V6.19c0-.471 0-.707-.092-.887a.84.84 0 0 0-.368-.368c-.18-.092-.416-.092-.888-.092H5.747c-.471 0-.707 0-.887.092a.84.84 0 0 0-.368.368c-.092.18-.092.416-.092.887v1.516c0 .472 0 .708.092.888.08.158.21.287.368.368.18.092.416.092.887.092"
    />
  </svg>
);
export default SvgNoCodeQrCode;
