import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwitchOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.658 6.147a8.15 8.15 0 0 1 2.187 4.15 8.22 8.22 0 0 1-.45 4.682 8.1 8.1 0 0 1-2.935 3.643A7.9 7.9 0 0 1 12.025 20a7.9 7.9 0 0 1-4.443-1.35 8.1 8.1 0 0 1-2.958-3.624 8.22 8.22 0 0 1-.479-4.68 8.15 8.15 0 0 1 2.162-4.162M11.969 2v9.017"
    />
  </svg>
);
export default SvgSwitchOn;
