import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3 11 7.594-7.594c.519-.519.778-.778 1.081-.964.269-.164.561-.286.867-.36C12.888 2 13.255 2 13.988 2H18m3 6.7v1.975c0 .489 0 .733-.055.963-.05.204-.13.4-.24.579-.123.201-.296.374-.642.72l-7.8 7.8c-.792.792-1.188 1.188-1.645 1.337a2 2 0 0 1-1.236 0c-.457-.149-.853-.545-1.645-1.337l-2.474-2.474c-.792-.792-1.188-1.188-1.337-1.645a2 2 0 0 1 0-1.236c.149-.457.545-.853 1.337-1.645l7.3-7.3c.346-.346.519-.519.72-.642q.271-.165.579-.24c.23-.055.474-.055.963-.055H17.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C21 7.02 21 7.58 21 8.7"
    />
  </svg>
);
export default SvgNoCodeTag;
