import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMultiDevice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.667 13.334V6.001c0-.934 0-1.4.181-1.757.16-.314.415-.569.729-.728.356-.182.823-.182 1.756-.182H14c.933 0 1.4 0 1.757.182.313.16.568.414.728.728.088.173.134.372.157.638m-6.225 11.785H3.056c-.517 0-.775 0-.987-.057A1.67 1.67 0 0 1 .89 15.432c-.057-.212-.057-.47-.057-.987 0-.258 0-.387.029-.493a.83.83 0 0 1 .589-.59c.106-.028.235-.028.493-.028h8.473m7.13-6.667v.711c0 .285 0 .427-.062.535a.54.54 0 0 1-.25.222c-.122.056-.282.056-.602.056h-1.6c-.32 0-.48 0-.602-.056a.54.54 0 0 1-.25-.222c-.062-.108-.062-.25-.062-.535v-.71m.514 10.833h2.4c.747 0 1.12 0 1.406-.148.25-.13.454-.337.582-.592.146-.29.146-.669.146-1.427v-6.5c0-.758 0-1.138-.146-1.427a1.35 1.35 0 0 0-.582-.592c-.286-.148-.659-.148-1.406-.148h-2.4c-.746 0-1.12 0-1.405.148-.25.13-.455.337-.583.592-.145.29-.145.669-.145 1.427v6.5c0 .758 0 1.138.145 1.427.128.255.332.462.583.592.285.148.659.148 1.405.148"
    />
  </svg>
);
export default SvgMultiDevice;
