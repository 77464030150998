import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorForm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M9.145 17.749H31.07"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M9.145 24.112H31.07"
    />
    <rect width={11.875} height={4.962} x={19.572} y={28.268} fill="#FDB022" rx={1.6} />
    <path stroke="#CFD4DE" strokeMiterlimit={10} strokeWidth={1.5} d="M37.607 11.403h-35.5" />
  </svg>
);
export default SvgNoCodeColorForm;
