import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorSideDrawer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      d="M32.75 2C35.65 2 38 4.35 38 7.25v25.5c0 2.9-2.35 5.25-5.25 5.25H7.25A5.25 5.25 0 0 1 2 32.75V7.25C2 4.35 4.35 2 7.25 2h25.5"
    />
    <path
      stroke="#CFD4DE"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M13 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38H13m0-36h19.75C35.65 2 38 4.35 38 7.25v25.5c0 2.9-2.35 5.25-5.25 5.25H13m0-36v36"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M19 12h13"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M19 20h13M19 28h13"
    />
    <path
      stroke="#FDB022"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9 24-3.987-3.987 3.889-3.89"
    />
  </svg>
);
export default SvgNoCodeColorSideDrawer;
