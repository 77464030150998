import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeProgressBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.599 9h10.977C20.915 9 22 10.12 22 11.5S20.915 14 19.576 14H8.599m0-5H4.424C3.085 9 2 10.12 2 11.5S3.085 14 4.424 14H8.6m0-5h4.25m-4.25 5h4.25M14 12H4.5a.5.5 0 0 1-.5-.5v0a.5.5 0 0 1 .5-.5H14a.5.5 0 0 1 .5.5v0a.5.5 0 0 1-.5.5"
    />
  </svg>
);
export default SvgNoCodeProgressBar;
