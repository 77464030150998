import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHanger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m12 11.381 8.4 5.6c1.105.737.584 2.46-.746 2.46H4.346c-1.33 0-1.851-1.723-.745-2.46zm0 0v-1.68c0-.37.309-.66.658-.781a2.015 2.015 0 1 0-2.673-1.905"
    />
  </svg>
);
export default SvgHanger;
