import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeCopilot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m20.16 11.656-3.697-1.39c-.733-.282-1.1-.423-1.408-.642a3 3 0 0 1-.706-.707c-.22-.308-.36-.675-.642-1.408L11.972 3l-1.734 4.509c-.282.733-.423 1.1-.642 1.408a3 3 0 0 1-.707.707c-.308.219-.675.36-1.408.642L2.972 12l4.51 1.734c.732.282 1.1.423 1.407.643a3 3 0 0 1 .707.706c.22.308.36.675.642 1.408l1.36 3.384M4.971 7V4.5m0 0V2m0 2.5h-2.5m2.5 0h2.5M4.972 22v-2.5m0 0V17m0 2.5h-2.5m2.5 0h2.5m7.928-.9h3m-4 1.8 2.136-5.296c.115-.287.173-.43.253-.475a.23.23 0 0 1 .221 0c.08.045.138.188.254.475L19.4 20.4m2.55-5.77v5.77"
    />
  </svg>
);
export default SvgNoCodeCopilot;
