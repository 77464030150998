import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeTimeline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 14.81c1.105 0 2-.853 2-1.905S7.105 11 6 11m0 3.81c-1.105 0-2-.853-2-1.905S4.895 11 6 11m0 3.81v3.38M6 11V7.714m0 10.477c-1.105 0-2 .852-2 1.904S4.895 22 6 22s2-.853 2-1.905-.895-1.904-2-1.904M6 7.714c1.657 0 3-1.279 3-2.857S7.657 2 6 2 3 3.28 3 4.857s1.343 2.857 3 2.857m6-2.857h8M12 13h8m-8 7.571h8"
    />
  </svg>
);
export default SvgNoCodeTimeline;
