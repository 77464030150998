import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.349}
      d="M2.235 11.527c0-3.316 0-4.974.645-6.241a5.92 5.92 0 0 1 2.588-2.588c1.267-.645 2.925-.645 6.242-.645h16.58c3.317 0 4.975 0 6.242.645a5.92 5.92 0 0 1 2.588 2.588c.645 1.267.645 2.925.645 6.241v10.66c0 3.316 0 4.974-.645 6.24a5.92 5.92 0 0 1-2.588 2.589c-1.267.645-2.925.645-6.242.645h-4.966c-1.232 0-1.848 0-2.437.121a6 6 0 0 0-1.504.527c-.535.274-1.016.659-1.978 1.428l-4.709 3.767c-.821.657-1.232.986-1.578.986a.99.99 0 0 1-.772-.371c-.216-.27-.216-.796-.216-1.848v-4.61c-1.835 0-2.753 0-3.506-.202a5.92 5.92 0 0 1-4.187-4.187c-.202-.753-.202-1.67-.202-3.506z"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M15.709 9.586h15.616M15.709 15.924h15.616"
    />
    <circle cx={9.273} cy={9.586} r={2.307} fill="#705AF8" />
    <circle cx={9.273} cy={15.924} r={2.307} fill="#B7B4FE" />
    <circle cx={9.273} cy={23.837} r={3.165} fill="#FDB022" />
    <path
      stroke="#FEC84B"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.6}
      d="M16.163 23.836h14.709"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.7}
      d="m9.291 21.976-.02 3.737m-1.859-1.879 3.737.02"
    />
  </svg>
);
export default SvgNoCodeColorComment;
