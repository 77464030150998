import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFunction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 1c-3.966 0-3.618 2.921-3.966 6.53m0 0C11.035 13 11.668 21 8 21m3.034-13.47H8m3.034 0H14M17.57 19a2.286 2.286 0 0 0 2.287-2.286v-4.571L21 11l-1.143-1.143V5.286A2.285 2.285 0 0 0 17.57 3M4.429 3a2.285 2.285 0 0 0-2.286 2.286v4.571L1 11l1.143 1.143v4.571A2.285 2.285 0 0 0 4.429 19"
    />
  </svg>
);
export default SvgFunction;
