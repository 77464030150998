import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCarousel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#B7B4FE"
      d="M11 9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v22a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2z"
    />
    <path
      fill="#705AF8"
      fillRule="evenodd"
      d="M10 10H8a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h2zm20 20h2a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2h-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNoCodeColorCarousel;
