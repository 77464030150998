import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeStepper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <path
        fill="#fff"
        strokeMiterlimit={10}
        d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
      />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M28.919 17h-18.5" />
      <path
        fill="#705AF8"
        d="M17 17a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M7 17a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M29.835 20.67a3.835 3.835 0 1 0 0-7.67 3.835 3.835 0 0 0 0 7.67"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m27.746 16.882 1.09 1.09c.089.089.133.133.184.15a.22.22 0 0 0 .139 0c.05-.017.095-.061.184-.15l2.434-2.434"
      />
      <path
        fill="#667085"
        d="M10.61 23.065v4.727h-1v-3.779h-.027l-1.083.679v-.887l1.17-.74zM18.156 27.824v-.72l1.683-1.558q.215-.208.36-.374.147-.166.224-.326a.8.8 0 0 0 .076-.348.66.66 0 0 0-.095-.358.6.6 0 0 0-.258-.233.8.8 0 0 0-.372-.083q-.216 0-.379.088a.6.6 0 0 0-.249.251.8.8 0 0 0-.088.39h-.948q0-.464.21-.805.21-.342.588-.529.38-.187.873-.187.507 0 .884.18.378.178.589.494.21.317.21.725 0 .268-.107.529a2.2 2.2 0 0 1-.371.579q-.268.316-.755.76l-.69.676v.032h1.985v.817zM30.32 27.857q-.517 0-.922-.178a1.6 1.6 0 0 1-.634-.494 1.24 1.24 0 0 1-.238-.73h1.006a.53.53 0 0 0 .113.305q.107.13.282.2.175.073.395.072a.96.96 0 0 0 .404-.08.66.66 0 0 0 .274-.224.56.56 0 0 0 .1-.33.55.55 0 0 0-.107-.335.7.7 0 0 0-.3-.23 1.2 1.2 0 0 0-.461-.084h-.441v-.734h.44q.227 0 .4-.078a.64.64 0 0 0 .272-.217.56.56 0 0 0 .097-.328.57.57 0 0 0-.32-.524.8.8 0 0 0-.351-.076.9.9 0 0 0-.372.074.65.65 0 0 0-.27.205.54.54 0 0 0-.109.314h-.957q.006-.409.233-.72.225-.312.61-.487.384-.178.87-.178.488 0 .856.178.366.177.57.48.205.3.203.674a.92.92 0 0 1-.247.662q-.248.266-.644.337v.037q.521.067.794.363.275.293.272.734.003.403-.233.718-.233.313-.644.494-.411.18-.942.18"
      />
    </g>
  </svg>
);
export default SvgNoCodeStepper;
