import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVariable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.906 21A20.26 20.26 0 0 0 22 12c0-3.233-.754-6.289-2.094-9M4.094 3A20.25 20.25 0 0 0 2 12c0 3.233.754 6.289 2.094 9M16.549 8.625h-.09c-.654 0-1.274.287-1.7.786l-5.374 6.303a2.23 2.23 0 0 1-1.7.786h-.09m1.12-7.875h1.394c.5 0 .939.333 1.076.816l1.774 6.243a1.12 1.12 0 0 0 1.076.816h1.395"
    />
  </svg>
);
export default SvgVariable;
