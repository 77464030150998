import * as React from 'react';
import type { SVGProps } from 'react';
const SvgZoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#4087FC"
      d="M12.375 47.775c0-12.391 0-18.587 2.412-23.32a22.13 22.13 0 0 1 9.669-9.668c4.732-2.412 10.928-2.412 23.319-2.412h32.45c12.391 0 18.587 0 23.32 2.412a22.13 22.13 0 0 1 9.669 9.669c2.411 4.732 2.411 10.928 2.411 23.319v32.45c0 12.391 0 18.587-2.411 23.32a22.13 22.13 0 0 1-9.669 9.669c-4.733 2.411-10.929 2.411-23.32 2.411h-32.45c-12.391 0-18.587 0-23.32-2.411a22.13 22.13 0 0 1-9.668-9.669c-2.412-4.733-2.412-10.929-2.412-23.32z"
    />
    <g fill="#fff">
      <path d="M35.483 41.875c-2.58 0-4.67 2.358-4.67 5.268v25.549c0 7.419 5.332 13.433 11.91 13.433l27.669-.263c2.58 0 4.67-2.359 4.67-5.268V54.78c0-7.419-6.328-12.906-12.906-12.906zM81.376 51.933c-1.669 1.65-2.626 4.032-2.626 6.536v10.69c0 2.504.957 4.886 2.626 6.537l10.386 9.147c2.114 2.09 5.426.397 5.426-2.772V46.06c0-3.17-3.312-4.862-5.425-2.773z" />
    </g>
  </svg>
);
export default SvgZoom;
