import * as React from 'react';
import type { SVGProps } from 'react';
const SvgJsLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.039 6.5v11H4.5v-2m15-9h-5.538a.613.613 0 0 0-.616.611v4.278c0 .337.276.611.616.611h4.923c.34 0 .615.274.615.611v4.278a.613.613 0 0 1-.615.611h-5.539"
    />
  </svg>
);
export default SvgJsLanguage;
