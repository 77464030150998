import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.59}
      d="M32.23 1.79H7.35a5.56 5.56 0 0 0-5.56 5.56v24.88a5.56 5.56 0 0 0 5.56 5.56h24.88a5.56 5.56 0 0 0 5.56-5.56V7.35a5.56 5.56 0 0 0-5.56-5.56Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M24.438 14.27h6.188"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M8.555 25.16h22.47M8.555 31.028h22.47"
    />
    <path fill="#FDB022" d="M13.233 20.705a6.767 6.767 0 1 0 0-13.535 6.767 6.767 0 0 0 0 13.535" />
    <path
      fill="#fff"
      d="M13.233 17.284a1 1 0 0 1-.705-.29.92.92 0 0 1-.29-.704c0-.264.089-.503.29-.692a.99.99 0 0 1 1.396 0 .93.93 0 0 1 .302.692c0 .176-.05.352-.138.503a1.1 1.1 0 0 1-.365.365.86.86 0 0 1-.49.138z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.598}
      d="M13.233 13.71v-3.107"
    />
  </svg>
);
export default SvgNoCodeColorAlert;
