import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <path fill="#B7B4FE" d="M16.673 7.363h4.654z" />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M16.673 7.363h4.654"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 30.637c5.463 0 9.891-4.428 9.891-9.891S24.463 10.855 19 10.855s-9.891 4.428-9.891 9.89c0 5.464 4.428 9.892 9.891 9.892"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.805}
      d="M19.404 15.557v4.812l3.008 1.805"
    />
  </svg>
);
export default SvgNoCodeColorTimer;
