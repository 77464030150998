import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeArray = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.5 4.51V19.5H7m-3.5-15H7m13.5 14.99V4.5H17m3.5 15H17"
    />
  </svg>
);
export default SvgDataTypeArray;
