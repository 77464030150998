import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g
      opacity={0.7}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <path
        fill="#fff"
        strokeMiterlimit={10}
        d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
      />
      <path strokeMiterlimit={10} d="M38 11.241H2" />
      <path strokeLinecap="round" strokeLinejoin="round" d="m29.82 5.888 2.015 2.015 2.015-2.015" />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M9.144 17H31" />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M9 24h21.856M9 31h21.856" />
    </g>
  </svg>
);
export default SvgNoCodeMenu;
