import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAlignHorizontalStretch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-stretch-horizontal"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={20} height={6} x={2} y={4} rx={2} />
    <rect width={20} height={6} x={2} y={14} rx={2} />
  </svg>
);
export default SvgAlignHorizontalStretch;
