import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorContainer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 38H7.25A5.25 5.25 0 0 1 2 32.75V26m0-12V7.25C2 4.35 4.35 2 7.25 2H14m24 24v6.75c0 2.9-2.35 5.25-5.25 5.25H26m0-36h6.75C35.65 2 38 4.35 38 7.25V14"
    />
    <path
      fill="#B7B4FE"
      d="M9.22 18.77h21.46c1.01 0 1.82.82 1.82 1.82v10.48c0 1.01-.82 1.82-1.82 1.82H9.22a1.82 1.82 0 0 1-1.82-1.82V20.59c0-1.01.82-1.82 1.82-1.82"
    />
    <path
      fill="#705AF8"
      d="M30.82 7.87H9.09A1.69 1.69 0 0 0 7.4 9.56v4.89c0 .933.757 1.69 1.69 1.69h21.73a1.69 1.69 0 0 0 1.69-1.69V9.56a1.69 1.69 0 0 0-1.69-1.69"
    />
  </svg>
);
export default SvgNoCodeColorContainer;
