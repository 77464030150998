import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path stroke="#CFD4DE" strokeMiterlimit={10} strokeWidth={1.5} d="M38 14.38H2" />
    <path
      fill="#B7B4FE"
      d="M31.074 19.5H8.926C7.862 19.5 7 20.233 7 21.137v9.726c0 .904.862 1.637 1.926 1.637h22.148c1.064 0 1.926-.733 1.926-1.637v-9.726c0-.904-.862-1.637-1.926-1.637"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M7 6.5h7m-6 4h5"
    />
  </svg>
);
export default SvgNoCodeColorFilters;
