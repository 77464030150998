import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeDatetime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 3.5h-.2c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3.007 5.767 3 6.586 3 8.2m5-4.7h8m-8 0v-2m0 2v1m8-1h.2c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311c.32.629.327 1.448.327 3.062m-5-4.7v-2m0 2v1m5 3.7v3.3m0-3.3H3m0 0v8.5c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311c.642.327 1.482.327 3.162.327h1.7m6.6-7v3l2 1m3-1a5 5 0 1 1-10 0 5 5 0 0 1 10 0"
    />
  </svg>
);
export default SvgDataTypeDatetime;
