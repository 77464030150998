import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorPdfViewer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.643 1.88h-25.5a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25V7.13c0-2.899-2.35-5.25-5.25-5.25Z"
    />
    <path
      fill="#F9F9FF"
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M10.225 11.333a3.71 3.71 0 0 1 3.708-3.708H22.6a.16.16 0 0 1 .113.047l.442-.442-.442.442 7.015 7.015q.046.047.047.113v13.867a3.71 3.71 0 0 1-3.708 3.708H13.933a3.71 3.71 0 0 1-3.708-3.708z"
    />
    <path
      fill="#D92D20"
      d="M23.71 23.474c-.73 0-1.643.127-1.941.172-1.237-1.292-1.589-2.026-1.668-2.222a8.7 8.7 0 0 0 .533-2.667c.027-.673-.116-1.176-.422-1.495a1.11 1.11 0 0 0-.784-.343c-.372 0-.997.188-.997 1.45 0 1.095.51 2.257.651 2.557-.743 2.165-1.541 3.647-1.71 3.952-2.988 1.125-3.222 2.212-3.222 2.52 0 .554.394.885 1.055.885 1.606 0 3.07-2.695 3.313-3.163 1.14-.454 2.666-.736 3.054-.803 1.113 1.06 2.4 1.343 2.934 1.343.402 0 1.344 0 1.344-.968 0-.9-1.152-1.218-2.14-1.218m-.077.635c.867 0 1.097.287 1.097.438 0 .096-.037.406-.501.406-.417 0-1.137-.24-1.845-.763a10 10 0 0 1 1.25-.08m-4.25-6.574c.08 0 .131.025.174.085.25.346.048 1.477-.196 2.362-.237-.76-.414-1.923-.165-2.333.05-.08.105-.114.187-.114m-.421 6.773c.314-.634.666-1.559.857-2.081.384.642.9 1.238 1.198 1.56-.929.197-1.632.392-2.055.52m-4.188 3.175c-.021-.024-.024-.076-.008-.138.032-.13.282-.775 2.089-1.583-.26.407-.664.99-1.108 1.424q-.47.44-.724.441c-.06 0-.142-.016-.25-.144"
    />
  </svg>
);
export default SvgNoCodeColorPdfViewer;
