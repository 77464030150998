import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeDivider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 12h.009m3.989 0h.009m7.986 0h.01m-4.007 0h.008m7.987 0H20m-.009 8v-.711c0-.996 0-1.494-.194-1.874a1.78 1.78 0 0 0-.776-.777c-.38-.194-.878-.194-1.873-.194H6.843c-.995 0-1.493 0-1.873.194-.334.17-.606.443-.776.777C4 17.795 4 18.293 4 19.29V20M19.991 4v.711c0 .996 0 1.494-.194 1.874-.17.334-.442.606-.776.777-.38.194-.878.194-1.873.194H6.843c-.995 0-1.493 0-1.873-.194a1.78 1.78 0 0 1-.776-.777C4 6.205 4 5.707 4 4.71V4"
    />
  </svg>
);
export default SvgNoCodeDivider;
