import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSessionStorage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.5 5c0 1.657-4.03 3-9 3s-9-1.343-9-3m18 0c0-1.657-4.03-3-9-3s-9 1.343-9 3m18 0v6.5M2.5 5v14c0 1.33 2.566 2.454 6.156 2.849q.7.077 1.447.116c.24.012.457.014.674.005M2.5 12c0 1.247 2.257 2.313 5.5 2.767q.902.127 1.892.186M16.5 14.5v3l2 1m3-1a5 5 0 1 1-10 0 5 5 0 0 1 10 0"
    />
  </svg>
);
export default SvgSessionStorage;
