import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeArrayZero = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.5 4.51V19.5H7m-3.5-15H7m13.5 14.99V4.5H17m3.5 15H17m-5.027-2.647c-.553 0-.829 0-1.059-.046a2.38 2.38 0 0 1-1.868-1.869C9 14.708 9 14.432 9 13.88v-3.757c0-.553 0-.829.046-1.059a2.38 2.38 0 0 1 1.868-1.868c.23-.046.507-.046 1.059-.046.553 0 .829 0 1.059.046A2.38 2.38 0 0 1 14.9 9.064c.046.23.046.506.046 1.059v3.757c0 .552 0 .829-.046 1.058a2.38 2.38 0 0 1-1.868 1.869c-.23.046-.506.046-1.059.046"
    />
  </svg>
);
export default SvgDataTypeArrayZero;
