import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCategoryFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      d="M31.75 1C34.65 1 37 3.35 37 6.25v25.5c0 2.9-2.35 5.25-5.25 5.25H6.25A5.25 5.25 0 0 1 1 31.75V6.25C1 3.35 3.35 1 6.25 1h25.5"
    />
    <path
      stroke="#CFD4DE"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37H17m0-36h14.75C34.65 1 37 3.35 37 6.25v25.5c0 2.9-2.35 5.25-5.25 5.25H17m0-36v36"
    />
    <path
      fill="#B7B4FE"
      d="M22 7.926v22.148c0 1.064.733 1.926 1.637 1.926h6.726c.904 0 1.637-.862 1.637-1.926V7.926C32 6.862 31.267 6 30.363 6h-6.726C22.733 6 22 6.862 22 7.926"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M6 11h6"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.13}
      d="M6 19h6M6 27h6"
    />
  </svg>
);
export default SvgNoCodeColorCategoryFilter;
