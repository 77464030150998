import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMicrosoft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 128 129"
    {...props}
  >
    <path fill="#F1511B" d="M62 62H11V12h51z" />
    <path fill="#80CC28" d="M117 62H67V12h50z" />
    <path fill="#00ADEF" d="M62 118H11V67h51z" />
    <path fill="#FBBC09" d="M117 118H67V67h50z" />
  </svg>
);
export default SvgMicrosoft;
