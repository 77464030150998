import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <g
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <path
        strokeMiterlimit={10}
        d="M8.875 4H7.369C4.47 4 2.12 6.22 2.12 8.958v24.084C2.12 35.78 4.47 38 7.37 38h25.5c2.9 0 5.25-2.22 5.25-4.958V8.958C38.12 6.22 35.77 4 32.87 4h-1.556M15.375 4h9.344M37.607 12.696h-35.5"
      />
      <rect width={6.345} height={6.345} x={7.677} y={17.087} fill="#705AF8" rx={1.6} />
      <rect width={6.345} height={6.345} x={7.677} y={26.433} fill="#CFD4DE" rx={1.6} />
      <rect width={6.345} height={6.345} x={17.023} y={17.087} fill="#CFD4DE" rx={1.6} />
      <rect width={6.345} height={6.345} x={17.023} y={26.433} fill="#CFD4DE" rx={1.6} />
      <rect width={6.345} height={6.345} x={26.368} y={17.087} fill="#CFD4DE" rx={1.6} />
      <rect width={6.345} height={6.345} x={26.368} y={26.433} fill="#CFD4DE" rx={1.6} />
      <path strokeLinecap="round" strokeMiterlimit={10} d="M12.107 1.875v6.219M27.92 1.875v6.219" />
    </g>
  </svg>
);
export default SvgNoCodeCalendar;
