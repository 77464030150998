import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeDouble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.849 9.6c.404 0 .607 0 .775.034.69.138 1.23.678 1.368 1.368.033.169.033.37.033.775s0 .607-.033.776a1.74 1.74 0 0 1-1.368 1.368c-.168.033-.37.033-.775.033H5.366c-.405 0-.607 0-.775-.033a1.74 1.74 0 0 1-1.368-1.368c-.034-.169-.034-.37-.034-.776 0-.404 0-.606.034-.775.137-.69.677-1.23 1.368-1.368.168-.033.37-.033.775-.033zM8.849 16.16c.404 0 .607 0 .775.034.69.137 1.23.677 1.368 1.368.033.168.033.37.033.775s0 .607-.033.775a1.74 1.74 0 0 1-1.368 1.368c-.168.034-.37.034-.775.034H5.366c-.405 0-.607 0-.775-.034a1.74 1.74 0 0 1-1.368-1.368c-.034-.168-.034-.37-.034-.775 0-.404 0-.607.034-.775.137-.69.677-1.23 1.368-1.368.168-.034.37-.034.775-.034zM18.823 9.6c.405 0 .607 0 .775.034.691.138 1.231.678 1.369 1.368.033.169.033.37.033.775s0 .607-.034.776a1.74 1.74 0 0 1-1.368 1.368c-.168.033-.37.033-.775.033h-3.482c-.405 0-.607 0-.775-.033a1.74 1.74 0 0 1-1.369-1.368c-.033-.169-.033-.37-.033-.776 0-.404 0-.606.034-.775.137-.69.677-1.23 1.368-1.368.168-.033.37-.033.775-.033zM18.823 16.16c.405 0 .607 0 .775.034.691.137 1.231.677 1.369 1.368.033.168.033.37.033.775s0 .607-.034.775a1.74 1.74 0 0 1-1.368 1.368c-.168.034-.37.034-.775.034h-3.482c-.405 0-.607 0-.775-.034a1.74 1.74 0 0 1-1.369-1.368c-.033-.168-.033-.37-.033-.775 0-.404 0-.607.034-.775.137-.69.677-1.23 1.368-1.368.168-.034.37-.034.775-.034zM8.849 3c.404 0 .607 0 .775.034.69.137 1.23.677 1.368 1.368.033.168.033.37.033.775 0 .404 0 .607-.033.775A1.74 1.74 0 0 1 9.624 7.32c-.168.033-.37.033-.775.033H5.366c-.405 0-.607 0-.775-.033a1.74 1.74 0 0 1-1.368-1.368c-.034-.168-.034-.37-.034-.775s0-.607.034-.775C3.36 3.71 3.9 3.17 4.59 3.034 4.759 3 4.961 3 5.366 3zM18.823 3c.405 0 .607 0 .775.034.691.137 1.231.677 1.369 1.368.033.168.033.37.033.775 0 .404 0 .607-.034.775a1.74 1.74 0 0 1-1.368 1.368c-.168.033-.37.033-.775.033h-3.482c-.405 0-.607 0-.775-.033a1.74 1.74 0 0 1-1.369-1.368c-.033-.168-.033-.37-.033-.775s0-.607.034-.775c.137-.691.677-1.231 1.368-1.368.168-.034.37-.034.775-.034z"
    />
  </svg>
);
export default SvgDataTypeDouble;
