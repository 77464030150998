import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSwitchOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.744 6.734a8.08 8.08 0 0 0-2.883 5.836 8.06 8.06 0 0 0 2.365 6.064 8.1 8.1 0 0 0 6.079 2.358 8.12 8.12 0 0 0 5.851-2.875M3 3l18 17.953M17.724 7.165a8.07 8.07 0 0 1 2.169 7.504M12 3v3.59"
    />
  </svg>
);
export default SvgSwitchOff;
