import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSankeyChart01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 20.4V3m18 17.4V3M3 8.048c4-.5 6 3 7.5 5s4.5 5 10.5 5M3 5c6 0 7.5 3.6 9 5.6s3 4.8 9 4.8M21 5c-6 0-7 5-8.5 7S9 18.048 3 18.048"
    />
  </svg>
);
export default SvgSankeyChart01;
