import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 11.292h6m-8 4 4.272-9.398c.231-.51.347-.764.507-.843a.5.5 0 0 1 .442 0c.16.08.276.334.507.843L17 15.292m4 4H3"
    />
  </svg>
);
export default SvgDataTypeText;
