import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 38H7.25A5.25 5.25 0 0 1 2 32.75V26m0-12V7.25C2 4.35 4.35 2 7.25 2H14m24 24v6.75c0 2.9-2.35 5.25-5.25 5.25H26m0-36h6.75C35.65 2 38 4.35 38 7.25V14"
    />
    <path
      fill="#705AF8"
      d="M14.29 6H9.36A3.36 3.36 0 0 0 6 9.36v20.59a3.36 3.36 0 0 0 3.36 3.36h4.93a3.36 3.36 0 0 0 3.36-3.36V9.36A3.36 3.36 0 0 0 14.29 6"
    />
    <path
      fill="#B7B4FE"
      d="M30.12 6h-6.39a3.04 3.04 0 0 0-3.04 3.04v6.43a3.04 3.04 0 0 0 3.04 3.04h6.39a3.04 3.04 0 0 0 3.04-3.04V9.04A3.04 3.04 0 0 0 30.12 6M23.6 20.79h6.52c1.68 0 3.04 1.36 3.04 3.04v6.43c0 1.68-1.36 3.04-3.04 3.04h-6.39c-1.68 0-3.04-1.36-3.04-3.04V23.7c0-1.61 1.31-2.91 2.91-2.91"
    />
  </svg>
);
export default SvgNoCodeColorStack;
