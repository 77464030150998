import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorSteppedContainer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 38H7.25A5.25 5.25 0 0 1 2 32.75V26m0-12V7.25C2 4.35 4.35 2 7.25 2H14m24 24v6.75c0 2.9-2.35 5.25-5.25 5.25H26m0-36h6.75C35.65 2 38 4.35 38 7.25V14"
    />
    <path
      fill="#B7B4FE"
      d="M30.25 17.3H8.86A1.86 1.86 0 0 0 7 19.16v11.05a1.86 1.86 0 0 0 1.86 1.86h21.39a1.86 1.86 0 0 0 1.86-1.86V19.16a1.86 1.86 0 0 0-1.86-1.86"
    />
    <path stroke="#705AF8" strokeLinecap="round" strokeMiterlimit={10} d="M28.919 10h-18.5" />
    <path
      fill="#705AF8"
      d="M16.5 10a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M7 10a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M29.799 13.67a3.835 3.835 0 1 0 0-7.67 3.835 3.835 0 0 0 0 7.67"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.672}
      d="m27.746 9.882 1.09 1.09c.089.089.133.133.184.15a.22.22 0 0 0 .139 0c.05-.017.095-.061.184-.15l2.434-2.434"
    />
  </svg>
);
export default SvgNoCodeColorSteppedContainer;
