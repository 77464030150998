import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      stroke="#4E26DB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M19.667 8v4.667"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M19.667 26.667v4.667"
    />
    <path
      stroke="#421FB8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m11.418 11.419 3.302 3.302"
    />
    <path
      stroke="#938AFB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m24.613 24.613 3.302 3.302"
    />
    <path
      stroke="#EAE9FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M8 19.667h4.667"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M26.667 19.667h4.666"
    />
    <path
      stroke="#D6D5FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m11.418 27.915 3.302-3.302"
    />
    <path
      stroke="#5C37EB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m24.613 14.72 3.302-3.301"
    />
  </svg>
);
export default SvgNoCodeColorLoader;
