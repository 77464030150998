import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#EAE9FE"
      d="M7 20a5.5 5.5 0 0 1 5.5-5.5h15a5.5 5.5 0 1 1 0 11h-15A5.5 5.5 0 0 1 7 20"
    />
    <g clipPath="url(#a)">
      <path
        stroke="#5C37EB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M14.65 19.2h-.001m1.201-.56v.895c0 .097 0 .146-.01.192a.4.4 0 0 1-.049.116 1 1 0 0 1-.128.144L14.13 21.52c-.238.238-.357.357-.494.401a.6.6 0 0 1-.37 0c-.137-.044-.256-.163-.494-.4l-.442-.443c-.238-.238-.356-.356-.4-.493a.6.6 0 0 1 0-.371c.044-.137.162-.256.4-.493l1.533-1.534c.07-.069.104-.103.145-.128a.4.4 0 0 1 .115-.048c.046-.011.095-.011.193-.011h.894c.224 0 .336 0 .422.044a.4.4 0 0 1 .175.174c.043.086.043.198.043.422m-1.3.56a.1.1 0 1 0 .2 0 .1.1 0 0 0-.2 0"
      />
    </g>
    <path
      fill="#5C37EB"
      d="M18.299 18.05v-.597h3.133v.596h-1.214v3.331h-.705v-3.33zM22.452 21.44q-.279 0-.504-.1a.8.8 0 0 1-.353-.299.88.88 0 0 1-.128-.487.83.83 0 0 1 .092-.412.7.7 0 0 1 .25-.261q.16-.098.36-.148.201-.052.416-.075a16 16 0 0 0 .42-.048.6.6 0 0 0 .233-.069q.075-.047.075-.147v-.012a.43.43 0 0 0-.128-.335q-.13-.12-.37-.12a.66.66 0 0 0-.405.112.55.55 0 0 0-.2.263l-.648-.092q.078-.27.254-.45t.431-.271q.255-.093.564-.092.213 0 .424.05.21.05.385.164t.28.31q.108.195.108.488v1.971h-.668v-.404h-.023a.85.85 0 0 1-.464.4 1.2 1.2 0 0 1-.4.064m.18-.51q.21 0 .363-.083a.6.6 0 0 0 .236-.222.57.57 0 0 0 .084-.301v-.347a.3.3 0 0 1-.111.05 2 2 0 0 1-.173.04 5 5 0 0 1-.352.053 1.2 1.2 0 0 0-.278.07.46.46 0 0 0-.194.134.33.33 0 0 0-.071.218.33.33 0 0 0 .14.29.6.6 0 0 0 .357.098M25.987 22.546q-.375 0-.643-.101a1.14 1.14 0 0 1-.431-.269.9.9 0 0 1-.227-.374l.625-.151a.7.7 0 0 0 .123.17.6.6 0 0 0 .217.142.9.9 0 0 0 .347.058q.296 0 .489-.144.195-.142.194-.468v-.558h-.035a1 1 0 0 1-.157.22.8.8 0 0 1-.27.19 1 1 0 0 1-.42.077 1.2 1.2 0 0 1-.616-.159 1.14 1.14 0 0 1-.437-.48q-.162-.32-.161-.8 0-.486.16-.82.164-.336.44-.508.277-.174.616-.174.258 0 .425.088a.8.8 0 0 1 .269.209q.1.12.151.228h.038v-.487h.685v2.993q0 .378-.18.625-.18.248-.493.37a1.9 1.9 0 0 1-.71.123m.005-1.752a.65.65 0 0 0 .376-.108.66.66 0 0 0 .236-.308q.08-.202.08-.484 0-.277-.08-.487a.7.7 0 0 0-.234-.324.6.6 0 0 0-.378-.117.6.6 0 0 0-.387.12.73.73 0 0 0-.234.333q-.078.208-.079.475 0 .27.079.474.08.201.236.314a.65.65 0 0 0 .385.112"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M11.5 17.6h4.799v4.8H11.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNoCodeColorTag;
