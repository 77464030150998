import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeMultiStatCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.5 14.6h-11m11 3h-11M6 7.29l1.045-.484v4.792m2.793-4.23c.075-.15.41-.7 1.248-.7 1.048 0 1.722 1.249.874 2.247-.68.799-1.814 1.83-2.222 2.246v.3h2.746m3.662-2.517c.646-.067 1.158-.484 1.203-1.084.048-.656-.462-1.191-1.191-1.191-.567 0-.95.35-1.142.656m1.13 1.619q-.09.009-.182.009a1 1 0 0 1 .182-.01m0 0c.59.027 1.495.543 1.373 1.418-.136.972-.964 1.183-1.361 1.167-.346-.016-1.022-.18-1.312-.9M7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21"
    />
  </svg>
);
export default SvgNoCodeMultiStatCard;
