import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAlignHorizontalEnd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-align-horizontal-justify-end"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={6} height={14} x={2} y={5} rx={2} />
    <rect width={6} height={10} x={12} y={7} rx={2} />
    <path d="M22 2v20" />
  </svg>
);
export default SvgAlignHorizontalEnd;
