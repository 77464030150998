import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSignal02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.243 5.757a6 6 0 0 1 0 8.486m-8.486 0a6 6 0 0 1 0-8.486M4.93 17.071c-3.906-3.905-3.906-10.237 0-14.142m14.142 0c3.906 3.905 3.906 10.237 0 14.142M12 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 0v9"
    />
  </svg>
);
export default SvgSignal02;
