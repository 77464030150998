import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorContextualDialog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.5}
      d="M2.75 11C2.75 8.1 5.1 5.75 8 5.75h20c2.9 0 5.25 2.35 5.25 5.25v21c0 2.9-2.35 5.25-5.25 5.25H9A6.25 6.25 0 0 1 2.75 31z"
    />
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M33.48 2H11.52A4.52 4.52 0 0 0 7 6.52v21.96A4.52 4.52 0 0 0 11.52 33h21.96A4.52 4.52 0 0 0 38 28.48V6.52A4.52 4.52 0 0 0 33.48 2Z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M13.152 9.96h18.88"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M13.028 16.303h18.88M13.028 22.647h10.58"
    />
  </svg>
);
export default SvgNoCodeColorContextualDialog;
