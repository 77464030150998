import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeFileUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 16.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21m0-18c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 5.28 21 6.12 21 7.8m-18 0c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.28 3 6.12 3 7.8 3M3 16.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21m1.993-6.793L12 12m0 0 2.207 2.207M12 12v4.965m4.414-2.349a3.034 3.034 0 0 0-1.931-5.375.34.34 0 0 1-.295-.167 4.138 4.138 0 1 0-6.499 5.018"
    />
  </svg>
);
export default SvgNoCodeFileUpload;
