import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <mask id="a" fill="#fff">
      <path d="M6 12h28v16.079H6z" />
    </mask>
    <path fill="#B7B4FE" d="M34 27.066H6v2.026h28z" mask="url(#a)" />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.921}
      d="m10.748 22.385-.521.52A1.842 1.842 0 0 1 7.62 20.3l.521-.52m4.69.52.52-.52a1.842 1.842 0 1 0-2.604-2.606l-.521.521m-1.03 3.634 2.58-2.579"
    />
    <path
      fill="#705AF8"
      d="M17.985 23.04v-5.82h1.054v4.936H21.6v.883zm4.485 0v-4.365H23.5v4.364zm.518-4.984a.6.6 0 0 1-.421-.162.52.52 0 0 1-.176-.395q0-.233.176-.395a.6.6 0 0 1 .42-.164q.247 0 .42.164.177.162.177.395a.52.52 0 0 1-.176.395.6.6 0 0 1-.42.162m2.597 2.426v2.557h-1.029v-4.364h.983v.742h.051q.15-.367.48-.583.333-.215.822-.215.45 0 .787.193.338.193.522.56.188.366.185.889v2.778h-1.028v-2.62q0-.436-.228-.684-.225-.247-.622-.247-.27 0-.48.12a.83.83 0 0 0-.327.337 1.14 1.14 0 0 0-.116.537m4.784 1.188-.003-1.242h.165l1.568-1.753h1.202l-1.93 2.148h-.212zm-.938 1.37v-5.82h1.029v5.82zm2.739 0-1.42-1.987.693-.724 1.957 2.71z"
    />
  </svg>
);
export default SvgNoCodeColorLink;
