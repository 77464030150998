import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.735 2.021h-25.5a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25v-25.5c0-2.899-2.35-5.25-5.25-5.25Z"
    />
    <path
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M38.245 11.241h-36M37.985 20.168h-36M38.235 29.095h-36M32.735 2.021h-25.5a5.25 5.25 0 0 0-5.25 5.25v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25v-25.5c0-2.899-2.35-5.25-5.25-5.25Z"
    />
    <path fill="#705AF8" d="M1.297 19.5h37.438v10.313H1.297z" />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M8.82 16.086h15.615"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M8.82 24.71h15.615"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M8.82 33.336h15.615"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.17}
      d="m29.874 24.297 1.09 1.09c.088.089.133.133.184.15a.22.22 0 0 0 .138 0c.051-.017.096-.061.185-.15l2.433-2.434"
    />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.07}
      d="m29.82 5.888 2.015 2.015 2.015-2.015"
    />
  </svg>
);
export default SvgNoCodeColorSelect;
