import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 39"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.875 4H6.369C3.47 4 1.12 6.22 1.12 8.958v24.084C1.12 35.78 3.47 38 6.37 38h25.5c2.9 0 5.25-2.22 5.25-4.958V8.958C37.12 6.22 34.77 4 31.87 4h-1.556M14.375 4h9.344M36.607 12.696h-35.5"
    />
    <rect width={6.345} height={6.345} x={6.677} y={17.087} fill="#705AF8" rx={1.6} />
    <rect width={6.345} height={6.345} x={6.677} y={26.433} fill="#CFD4DE" rx={1.6} />
    <rect width={6.345} height={6.345} x={16.023} y={17.087} fill="#CFD4DE" rx={1.6} />
    <rect width={6.345} height={6.345} x={16.023} y={26.433} fill="#CFD4DE" rx={1.6} />
    <rect width={6.345} height={6.345} x={25.368} y={17.087} fill="#CFD4DE" rx={1.6} />
    <rect width={6.345} height={6.345} x={25.368} y={26.433} fill="#CFD4DE" rx={1.6} />
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.5}
      d="M11.107 1.875v6.219M26.92 1.875v6.219"
    />
  </svg>
);
export default SvgNoCodeColorCalendar;
