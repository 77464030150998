import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEpubs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M5.825 4A3.375 3.375 0 0 1 9.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H9.2A3.375 3.375 0 0 1 5.825 20z"
    />
    <rect width={24.692} height={9.462} x={0.654} y={9.269} fill="#155EEF" rx={1.231} />
    <path
      fill="#fff"
      d="M2.889 16.5v-4.475h3.016v.78h-2.07v1.066H5.75v.78H3.835v1.069h2.078v.78zm3.768 0v-4.475h1.766q.51 0 .867.194.359.193.547.535.19.342.19.787 0 .446-.193.787a1.33 1.33 0 0 1-.557.53q-.363.192-.878.191H7.273v-.758h.973a.95.95 0 0 0 .45-.094.62.62 0 0 0 .267-.265.8.8 0 0 0 .09-.39.8.8 0 0 0-.09-.39.6.6 0 0 0-.267-.26 1 1 0 0 0-.455-.094h-.638V16.5zm6.749-4.475h.946v2.906q0 .49-.234.857-.23.366-.649.572a2.2 2.2 0 0 1-.972.203q-.558 0-.975-.203a1.56 1.56 0 0 1-.649-.572 1.57 1.57 0 0 1-.232-.857v-2.906h.947v2.825q0 .255.111.455a.8.8 0 0 0 .32.312 1 1 0 0 0 .478.114.97.97 0 0 0 .478-.114.8.8 0 0 0 .317-.312.9.9 0 0 0 .114-.455zM15.13 16.5v-4.475h1.791q.494 0 .824.146.33.147.497.406.165.258.166.595a.905.905 0 0 1-.393.785q-.183.123-.416.176v.044q.255.01.479.144.225.135.364.374.14.238.14.568 0 .357-.177.636-.174.278-.518.44-.342.16-.845.161zm.946-.774h.771q.396 0 .577-.15a.5.5 0 0 0 .181-.407.6.6 0 0 0-.09-.328.6.6 0 0 0-.255-.223.9.9 0 0 0-.39-.08h-.794zm0-1.829h.701q.195 0 .346-.067a.6.6 0 0 0 .24-.197.5.5 0 0 0 .09-.304.5.5 0 0 0-.173-.391q-.17-.15-.485-.149h-.72zm5.55-.585a.55.55 0 0 0-.225-.411q-.2-.147-.54-.147-.232 0-.391.066a.55.55 0 0 0-.245.177.43.43 0 0 0-.083.258q-.004.12.05.21.057.09.156.155.097.064.227.111.13.046.275.079l.402.096q.293.066.538.175.244.109.424.269.18.159.277.376.101.216.103.496a1.24 1.24 0 0 1-.21.712q-.206.3-.594.466-.387.163-.933.163-.542 0-.944-.166a1.4 1.4 0 0 1-.625-.491q-.224-.329-.234-.811h.916q.015.225.128.376.116.148.309.225.195.075.439.074.24 0 .417-.07a.64.64 0 0 0 .278-.194.45.45 0 0 0 .098-.287q0-.15-.09-.253a.7.7 0 0 0-.257-.175 2.6 2.6 0 0 0-.413-.131l-.488-.122q-.566-.139-.893-.43-.33-.294-.326-.79-.003-.406.216-.71.222-.304.605-.474.385-.17.875-.17.498 0 .87.17.373.17.58.474.209.303.215.704z"
    />
  </svg>
);
export default SvgEpubs;
