import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWebhook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.855 3.5a3.87 3.87 0 0 0-2.903-.367 3.864 3.864 0 0 0-.931 7.08L5.5 18.029m16.596 2.988a3.864 3.864 0 0 0-.968-7.06 3.87 3.87 0 0 0-2.94.388L13.67 6.53M2 19.175c.01.976.388 1.949 1.134 2.693a3.876 3.876 0 0 0 5.475 0 3.85 3.85 0 0 0 1.134-2.735h9.04"
    />
  </svg>
);
export default SvgWebhook;
