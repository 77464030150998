import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2.5H7.25A5.25 5.25 0 0 0 2 7.75v25.5c0 2.9 2.35 5.25 5.25 5.25h25.5c2.9 0 5.25-2.35 5.25-5.25V7.75c0-2.9-2.35-5.25-5.25-5.25Z"
    />
    <path stroke="#CFD4DE" strokeMiterlimit={10} strokeWidth={1.5} d="M38 12.88H2" />
    <path
      fill="#B7B4FE"
      d="M30.7 18.05H9.31a1.86 1.86 0 0 0-1.86 1.86v11.05a1.86 1.86 0 0 0 1.86 1.86H30.7a1.86 1.86 0 0 0 1.86-1.86V19.91a1.86 1.86 0 0 0-1.86-1.86"
    />
  </svg>
);
export default SvgNoCodeColorCard;
