import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPdf2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.825 4A3.375 3.375 0 0 1 8.2.625h8a.1.1 0 0 1 .07.03l6.476 6.475q.029.029.029.07V20a3.375 3.375 0 0 1-3.375 3.375H8.2A3.375 3.375 0 0 1 4.825 20z"
    />
    <rect width={16.764} height={9} y={10} fill="#E62E05" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2 12v5h1.057v-1.621h.889q.576 0 .981-.213.408-.212.623-.593t.215-.879-.213-.879a1.47 1.47 0 0 0-.61-.598q-.4-.217-.97-.217zm1.057 2.532h.718q.305 0 .503-.105a.7.7 0 0 0 .298-.296.9.9 0 0 0 .1-.437.9.9 0 0 0-.1-.434.67.67 0 0 0-.298-.29 1.1 1.1 0 0 0-.508-.106h-.713zM8.224 17H6.45v-5h1.787q.754 0 1.3.3.543.298.837.857.295.56.295 1.338 0 .782-.295 1.343a2.03 2.03 0 0 1-.843.862q-.546.3-1.308.3m-.716-.906h.672q.468 0 .788-.166.322-.168.484-.52.163-.354.163-.913 0-.554-.163-.906a1.06 1.06 0 0 0-.481-.517q-.32-.165-.789-.166h-.674zm3.945.906v-5h3.31v.872H12.51v1.191h2.034v.872H12.51V17z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPdf2;
