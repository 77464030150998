import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      stroke="#FDB022"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={6.5}
      d="M15.188 11.482v-.007"
    />
    <path
      fill="#FEDF89"
      d="M12.9 21.96c-.61-.78-1.78-.78-2.39 0l-7.76 9.96v.83c0 2.48 2.02 4.5 4.5 4.5h17.56z"
    />
    <path
      fill="#FDB022"
      d="M32.75 37.25c2.48 0 4.5-2.02 4.5-4.5v-6.14l-6.72-8.71c-.61-.79-1.8-.79-2.4 0L13.21 37.25z"
    />
  </svg>
);
export default SvgNoCodeColorImage;
