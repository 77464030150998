import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCursorInteractive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.505 11.22a4.696 4.696 0 1 1 4.715-4.676m-2.293-.136a2.53 2.53 0 1 0-2.539 2.518m4.946 2.765-1.058 1.963c-.172.321-.259.482-.364.524a.31.31 0 0 1-.279-.025c-.096-.06-.153-.232-.268-.578L7.67 8.454c-.1-.303-.15-.455-.115-.555a.31.31 0 0 1 .189-.189c.1-.036.252.014.555.115l5.12 1.695c.347.115.52.172.58.268.05.084.06.187.024.279-.042.105-.203.192-.524.364l-1.963 1.058a1 1 0 0 0-.095.056.3.3 0 0 0-.051.051 1 1 0 0 0-.056.095"
    />
  </svg>
);
export default SvgCursorInteractive;
