import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDecimal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.54 9.973c0-.553 0-.829.046-1.059a2.38 2.38 0 0 1 1.869-1.868C9.685 7 9.961 7 10.514 7s.828 0 1.058.046a2.38 2.38 0 0 1 1.869 1.868c.045.23.045.506.045 1.059v4.757c0 .552 0 .829-.045 1.058a2.38 2.38 0 0 1-1.869 1.869c-.23.046-.506.046-1.058.046-.553 0-.83 0-1.059-.046a2.38 2.38 0 0 1-1.869-1.869c-.045-.23-.045-.506-.045-1.058zM16.5 9.973c0-.553 0-.829.046-1.059a2.38 2.38 0 0 1 1.868-1.868C18.644 7 18.92 7 19.473 7s.829 0 1.059.046A2.38 2.38 0 0 1 22.4 8.914c.046.23.046.506.046 1.059v4.757c0 .552 0 .829-.046 1.058a2.38 2.38 0 0 1-1.868 1.869c-.23.046-.506.046-1.059.046s-.829 0-1.059-.046a2.38 2.38 0 0 1-1.868-1.869c-.046-.23-.046-.506-.046-1.058zM3.31 17.703a1.19 1.19 0 1 0 0-2.379 1.19 1.19 0 0 0 0 2.379"
    />
  </svg>
);
export default SvgDecimal;
