import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUnifyApi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.178 16.941 8.5 13.632m5.669-6.573L8.5 10.367m0 3.265c0 .11 0 .165-.004.21a1 1 0 0 1-.904.904c-.046.004-.1.004-.21.004H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 13.99 3 13.71 3 13.15v-2.3c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C3.76 9.25 4.04 9.25 4.6 9.25h2.783c.109 0 .163 0 .209.004a1 1 0 0 1 .904.904c.004.046.004.1.004.21v0m0 3.264v-3.265m8.608-1.978L19.1 7.24c.485-.28.727-.42.858-.621a1 1 0 0 0 .16-.597c-.012-.24-.152-.483-.433-.968l-1.15-1.991c-.28-.485-.42-.728-.621-.859a1 1 0 0 0-.597-.16c-.24.013-.482.153-.967.433l-1.992 1.15c-.485.28-.728.42-.858.621a1 1 0 0 0-.16.597c.012.24.152.483.432.968l1.15 1.991c.28.485.42.728.622.859a1 1 0 0 0 .597.16c.24-.013.482-.153.967-.433m-2.75 11.856 1.992 1.15c.485.28.727.42.967.433a1 1 0 0 0 .597-.16c.201-.13.341-.373.621-.858l1.15-1.992c.28-.485.42-.728.433-.968a1 1 0 0 0-.16-.597c-.13-.2-.373-.34-.858-.62l-1.992-1.15c-.485-.28-.728-.42-.967-.434a1 1 0 0 0-.597.16c-.202.131-.342.374-.622.859l-1.15 1.992c-.28.485-.42.727-.432.967a1 1 0 0 0 .16.597c.13.201.373.341.858.621"
    />
  </svg>
);
export default SvgUnifyApi;
