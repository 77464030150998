import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.75 2H7.25A5.25 5.25 0 0 0 2 7.25v25.5C2 35.65 4.35 38 7.25 38h25.5c2.9 0 5.25-2.35 5.25-5.25V7.25C38 4.35 35.65 2 32.75 2Z"
    />
    <path
      fill="#705AF8"
      d="M11.94 14.821q-.57 0-1.027-.203a1.7 1.7 0 0 1-.718-.61q-.262-.401-.262-.991 0-.508.187-.84a1.45 1.45 0 0 1 .512-.531 2.6 2.6 0 0 1 .73-.301q.41-.105.848-.153.528-.054.856-.097.327-.047.476-.14.153-.099.153-.302v-.023q0-.441-.262-.684-.262-.242-.754-.242-.52 0-.824.227-.3.226-.407.535l-1.32-.188q.156-.546.516-.914.36-.37.879-.554.52-.188 1.148-.188.434 0 .863.102.43.101.786.336.355.23.57.628.219.399.219.997V14.7h-1.36v-.824h-.047a1.7 1.7 0 0 1-.363.469q-.231.215-.582.347a2.4 2.4 0 0 1-.816.13m.368-1.039q.426 0 .738-.168.314-.171.48-.453.172-.281.172-.613v-.707a.7.7 0 0 1-.226.101 3 3 0 0 1-.352.082q-.195.036-.386.063l-.332.047q-.317.042-.567.14a.94.94 0 0 0-.394.274.67.67 0 0 0-.145.445q0 .39.285.59.286.2.727.2M25.348 31.444v-.898l3.094-3.879v-.05H25.45v-1.173h4.723v.965l-2.946 3.813v.05h3.047v1.172z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M17.98 13.668h11.407M10.98 26.356h11.407"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M10.605 20.012h18.783"
    />
  </svg>
);
export default SvgNoCodeColorText;
