import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataTypeBinary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.342 16.592H12.26m0 0h-2.083m2.083 0V7.2h-2.083m-5.423 9.6c-.438 0-.656 0-.838-.03-.747-.125-1.33-.614-1.479-1.24-.036-.153-.036-.336-.036-.703V9.173c0-.366 0-.55.036-.702.149-.626.732-1.116 1.479-1.24.182-.03.4-.03.838-.03s.655 0 .837.03c.747.124 1.33.614 1.479 1.24.036.152.036.336.036.702v5.654c0 .367 0 .55-.036.703-.149.626-.732 1.115-1.479 1.24-.182.03-.4.03-.837.03m14.494 0c-.437 0-.655 0-.837-.03-.747-.125-1.33-.614-1.479-1.24-.036-.153-.036-.336-.036-.703V9.173c0-.366 0-.55.036-.702.149-.626.732-1.116 1.479-1.24.182-.03.4-.03.837-.03.438 0 .656 0 .838.03.747.124 1.33.614 1.479 1.24.036.152.036.336.036.702v5.654c0 .367 0 .55-.036.703-.149.626-.732 1.115-1.479 1.24-.182.03-.4.03-.838.03"
    />
  </svg>
);
export default SvgDataTypeBinary;
