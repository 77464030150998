import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCatalog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8 18-7 4V6l7-4zm0 0 8 4m0 0V6m0 16 7-4V2l-7 4m0 0-5.44-2.64m.027 4.83 2.422.9m-2.46 2.747 2.422.902m-2.46 2.65 2.422.901"
    />
  </svg>
);
export default SvgCatalog;
